import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Chart, registerables } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SubSink } from 'subsink';
import * as d3 from "d3";
import { LNIService } from '../Service/lni.service';
import { CurrencyPipe } from '@angular/common';
import { count, elementAt, forkJoin } from 'rxjs';
import { ElementSchemaRegistry } from '@angular/compiler';
import { faWordpressSimple } from '@fortawesome/free-brands-svg-icons';
import { SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-news-paper',
  templateUrl: './news-paper.component.html',
  styleUrls: ['./news-paper.component.scss'],
  providers: [CurrencyPipe],
})
export class NewsPaperComponent implements OnInit {
  public chart3: any;
  public chart4: any;
  public chart5: any;
  public chart6: any;

  private subs = new SubSink();
  disableSelect = new FormControl('');

  AlreadyClicked = false

  // RadioOptionValue1: any = 'newsOutlet';
  RadioOptionValue: any = 'newsOutlet';
  RadioOptions: any[] = [
    {
      Name: 'News Outlets by County',
      value: 'newsOutlet',
    },
    {
      Name: 'Median Income by County',
      value: 'medianIncome',
    },
    {
      Name: 'Residents per Square Mile by County',
      value: 'densitySqm',
    },
    {
      Name: 'Presidential Election Results by County (2020)',
      value: 'perGap',
    },
  ];
  TabIndex: any = 0;

  PartyName = '';

  GovernerName = '';


  ParameterTabName = 'Local News Landscape';

  ConsolidationArr: any = [];
  PopulationRank: number = 0;
  EthnicCountOfOther: number = 0;
  EthnicCountOfBlack: number = 0;
  EthnicCountOfAsian: number = 0;
  EthnicTotal: number = 0;

  NetworkCountOfPatch: number = 0;
  NetworkCountOfOther: number = 0;
  NetworkTotal: number = 0;

  LocalClickedCounty: any;
  LocalNewsLandScapeTotalOutlet: any;
  MedianIncomeForTable: any;
  PopulationForTable: any;
  PopulationDensityForTable: any;
  DemocratsForTable: any;
  RepublicansForTable: any;

  DigitalTotalOutlet: any;
  EthnicMediaTotalOutlet: any;
  PublicBoardCastTotalOutlet: any;

  EthnicCountOfAmerican: number = 0;
  EthnicCountOfHispanic: number = 0;
  DigitalLocalProfit: number = 0;
  DigitalLocalNoneProfit: number = 0;
  digitalStateProfit: number = 0;
  digitalStateNonProfit: number = 0;
  PBLocalCount: number = 0;
  PBNonLocalCount: number = 0;
  PBStateLocalCount: number = 0;
  PBStateNonLocalCount: number = 0;
  CurrYrnewsPaperCount: number = 0;
  prevYrnewsPaperCount: number = 0;
  CurrYrdigitalCount: number = 0;
  prevYrdigitalCount: number = 0;
  CurrYrEthinicCount: number = 0;
  prevYrEthinicCount: number = 0;
  CurrYrPbCount: number = 0;
  prevYrPbCount: number = 0;
  CurrYrTotalOutlet: number = 0;
  prevYrTotalOutlet: number = 0;
  countOfDailyNewspaper2022: number = 0;
  countOfWeeklyNewspaper2022: number = 0;
  countOfDailyNewspaper2020: number = 0;
  countOfWeeklyNewspaper2020: number = 0;
  localEmployed2020: any = 0;
  localEmployed2022: any = 0;
  isnewsCountLoading = true;
  differentlocalId: number | any = 0;
  dailyCoundDifference: number = 0;
  weeklyCoundDifference: number = 0;
  totalCountDifference: number = 0;
  Audited2022Circulation: number = 0;
  Audited2020Circulation: number = 0;
  AuditedCoundDifference: number = 0;
  LocalNewsLandScapTable: any = [];
  NewsPaperTable: any = [];
  DigitalSitesTable: any = [];
  DigitalSitesOver: any = [];
  NetworkSitesTable: any = [];
  NetworkSitesOver: any = [];
  EthnicVoiceTable: any[] = [];
  PBStationTable: any[] = [];
  TotalPopulation = 0;
  AdultPopulation: any = 0;
  AdultPopulationCalc = 0;
  DemoGraphicsAge: any = 0;
  DemoGraphicsmedianIncome: any = 0;
  DemoGraphicsPovertyRate = 0;
  MinRangeValue: any = 0;
  MaxRangeValue: any = 0;

  width = 0;
  height = 0;
  Zoom = '';
  Top = '';

  Loading = false;
  SeletedState:
    | { StateCode: string; StateName: string; StateAbbr: string }
    | any;
  NationalMapBidenValue: number = 0;
  NationalMaptrumpValue: number = 0;

  constructor(
    public router: Router,
    private lniService: LNIService,
    private params: ActivatedRoute,
    public currencyPipe: CurrencyPipe,
    route: ActivatedRoute
  ) {
    route.queryParams.subscribe((p: any) => this.QuaryChange());
    Chart.register(...registerables);
  }

  StateNameList = [
    {
      StateCode: '01',
      StateName: 'Alabama',
      StateAbbr: 'AL',
    },
    {
      StateCode: '02',
      StateName: 'Alaska',
      StateAbbr: 'AK',
    },
    {
      StateCode: '04',
      StateName: 'Arizona',
      StateAbbr: 'AZ',
    },
    {
      StateCode: '05',
      StateName: 'Arkansas',
      StateAbbr: 'AR',
    },
    {
      StateCode: '06',
      StateName: 'California',
      StateAbbr: 'CA',
    },
    {
      StateCode: '08',
      StateName: 'Colorado',
      StateAbbr: 'CO',
    },
    {
      StateCode: '09',
      StateName: 'Connecticut',
      StateAbbr: 'CT',
    },
    {
      StateCode: '10',
      StateName: 'Delaware',
      StateAbbr: 'DE',
    },
    {
      StateCode: 11,
      StateName: 'District of Columbia',
      StateAbbr: 'DC',
    },
    {
      StateCode: 12,
      StateName: 'Florida',
      StateAbbr: 'FL',
    },
    {
      StateCode: 13,
      StateName: 'Georgia',
      StateAbbr: 'GA',
    },
    {
      StateCode: 15,
      StateName: 'Hawaii',
      StateAbbr: 'HI',
    },
    {
      StateCode: 16,
      StateName: 'Idaho',
      StateAbbr: 'ID',
    },
    {
      StateCode: 17,
      StateName: 'Illinois',
      StateAbbr: 'IL',
    },
    {
      StateCode: 18,
      StateName: 'Indiana',
      StateAbbr: 'IN',
    },
    {
      StateCode: 19,
      StateName: 'Iowa',
      StateAbbr: 'IA',
    },
    {
      StateCode: 20,
      StateName: 'Kansas',
      StateAbbr: 'KS',
    },
    {
      StateCode: 21,
      StateName: 'Kentucky',
      StateAbbr: 'KY',
    },
    {
      StateCode: 22,
      StateName: 'Louisiana',
      StateAbbr: 'LA',
    },
    {
      StateCode: 23,
      StateName: 'Maine',
      StateAbbr: 'ME',
    },
    {
      StateCode: 24,
      StateName: 'Maryland',
      StateAbbr: 'MD',
    },
    {
      StateCode: 25,
      StateName: 'Massachusetts',
      StateAbbr: 'MA',
    },
    {
      StateCode: 26,
      StateName: 'Michigan',
      StateAbbr: 'MI',
    },
    {
      StateCode: 27,
      StateName: 'Minnesota',
      StateAbbr: 'MN',
    },
    {
      StateCode: 28,
      StateName: 'Mississippi',
      StateAbbr: 'MS',
    },
    {
      StateCode: 29,
      StateName: 'Missouri',
      StateAbbr: 'MO',
    },
    {
      StateCode: 30,
      StateName: 'Montana',
      StateAbbr: 'MT',
    },
    {
      StateCode: 31,
      StateName: 'Nebraska',
      StateAbbr: 'NE',
    },
    {
      StateCode: 32,
      StateName: 'Nevada',
      StateAbbr: 'NV',
    },
    {
      StateCode: 33,
      StateName: 'New Hampshire',
      StateAbbr: 'NH',
    },
    {
      StateCode: 34,
      StateName: 'New Jersey',
      StateAbbr: 'NJ',
    },
    {
      StateCode: 35,
      StateName: 'New Mexico',
      StateAbbr: 'NM',
    },
    {
      StateCode: 36,
      StateName: 'New York',
      StateAbbr: 'NY',
    },
    {
      StateCode: 37,
      StateName: 'North Carolina',
      StateAbbr: 'NC',
    },
    {
      StateCode: 38,
      StateName: 'North Dakota',
      StateAbbr: 'ND',
    },
    {
      StateCode: 39,
      StateName: 'Ohio',
      StateAbbr: 'OH',
    },
    {
      StateCode: 40,
      StateName: 'Oklahoma',
      StateAbbr: 'OK',
    },
    {
      StateCode: 41,
      StateName: 'Oregon',
      StateAbbr: 'OR',
    },
    {
      StateCode: 42,
      StateName: 'Pennsylvania',
      StateAbbr: 'PA',
    },
    {
      StateCode: 44,
      StateName: 'Rhode Island',
      StateAbbr: 'RI',
    },
    {
      StateCode: 45,
      StateName: 'South Carolina',
      StateAbbr: 'SC',
    },
    {
      StateCode: 46,
      StateName: 'South Dakota',
      StateAbbr: 'SD',
    },
    {
      StateCode: 47,
      StateName: 'Tennessee',
      StateAbbr: 'TN',
    },
    {
      StateCode: 48,
      StateName: 'Texas',
      StateAbbr: 'TX',
    },
    {
      StateCode: 49,
      StateName: 'Utah',
      StateAbbr: 'UT',
    },
    {
      StateCode: 50,
      StateName: 'Vermont',
      StateAbbr: 'VT',
    },
    {
      StateCode: 51,
      StateName: 'Virginia',
      StateAbbr: 'VA',
    },
    {
      StateCode: 53,
      StateName: 'Washington',
      StateAbbr: 'WA',
    },
    {
      StateCode: 54,
      StateName: 'West Virginia',
      StateAbbr: 'WV',
    },
    {
      StateCode: 55,
      StateName: 'Wisconsin',
      StateAbbr: 'WI',
    },
    {
      StateCode: 56,
      StateName: 'Wyoming',
      StateAbbr: 'WY',
    },
  ];


  DeviceWidth = 0;

  routerValue = '';


  ngOnInit() {




    let quary: any = this.params.snapshot;


    this.routerValue = quary.routeConfig.path;

    if (this.routerValue === 'state-localnewslandscape') {
      this.TabIndex = 0

    } else if (this.routerValue === 'state-newspapers') {
      this.TabIndex = 1

    }
    else if (this.routerValue === 'state-standalonedigitalsites') {
      this.TabIndex = 2

    } else if (this.routerValue === 'state-networkdigitalsites') {
      this.TabIndex = 3
    }
    else if (this.routerValue === 'state-ethnicoutlets') {
      this.TabIndex = 4

    } else {
      this.TabIndex = 5

    }



    let w = window.screen.width;
    this.DeviceWidth = w
    document.getElementById('home')?.classList.remove('active');
    document.getElementById('brightspot')?.classList.remove('active');
    document.getElementById('StateofLocal')?.classList.remove('active');
    document.getElementById('newspaper')?.classList.add('active');

    setTimeout(() => {
      let MapLengends: any = document.getElementById(this.routerValue);
      if (w < 600) {
        this.width = MapLengends?.offsetWidth;
        this.height = 450;
        this.Zoom = 'scale(1)';
        this.Top = '10px';
      } else if (w > 600 && w < 900) {
        this.width = MapLengends?.offsetWidth;
        this.height = 550;
        this.Zoom = 'scale(1)';
        this.Top = '20px';
      } else {
        this.width = MapLengends?.offsetWidth;
        this.height = 700;
        this.Zoom = 'scale(1)';
        this.Top = '10px';
      }
    }, 50);
  }


  stateDescription: string = '';
  mapDescriptionTop: string = '';
  mapDescriptionBottom: string = '';
  getStateInfo(stateShortForm: any) {
    this.lniService.getStateInformation(stateShortForm).subscribe({
      next: (response: any) => {
        this.stateDescription = response.text

        // this.stateDescription = 'Sample Text here' + stateShortForm
      },
      error: (error: any) => {
        // console.log(error);
      },
      complete: () => { },
    })
  }

  CallMapDescription(TabName: any, stateShortForm: any) {
    this.lniService.getMapDescription(TabName, stateShortForm).subscribe({
      next: (response: any) => {
        this.mapDescriptionTop = response.topText;
        this.mapDescriptionBottom = response.bottomText;

        // this.mapDescriptionTop = 'Sample Text here '+TabName;
        // this.mapDescriptionBottom = 'Sample Text here' + TabName;


      },
      error: (error: any) => {
        // console.log(error);
      },
      complete: () => { },
    })
  }

  StateShortForm = '';

  QuaryChange() {
    this.Loading = true;
    let quary: any = this.params.snapshot.queryParamMap;
    let StateName = quary.params.state;
    this.StateShortForm = StateName;
    this.getStateInfo(this.StateShortForm);
    // let StateName = quary.params.state


    this.CallMapDescription(this.ParameterTabName, this.StateShortForm)
    this.valueReset();
    this.getRadioOptionValues(StateName);
    this.ApiForCardsBelowStateMap(StateName);

    this.LandScapTableApi(StateName);

    this.disableSelect.setValue(StateName);

    this.SeletedState = this.StateNameList.find(
      (element) => element.StateAbbr == StateName
    );

    this.disableSelect.setValue(StateName);

    d3.select('#tooltip').remove();
    d3.select('#dotstooltip').remove();
    d3.select('#map-container').remove();
  }

  valueReset() {
    this.ConsolidationArr = [];
    this.PopulationRank = 0;
    this.EthnicCountOfOther = 0;
    this.EthnicCountOfBlack = 0;
    this.EthnicCountOfAsian = 0;
    this.EthnicCountOfAmerican = 0;
    this.EthnicTotal = 0;
    this.EthnicCountOfHispanic = 0;
    this.DigitalLocalProfit = 0;
    this.DigitalLocalNoneProfit = 0;
    this.digitalStateProfit = 0;
    this.digitalStateNonProfit = 0;
    this.PBLocalCount = 0;
    this.PBNonLocalCount = 0;
    this.PBStateLocalCount = 0;
    this.PBStateNonLocalCount = 0;
    this.CurrYrnewsPaperCount = 0;
    this.prevYrnewsPaperCount = 0;
    this.CurrYrdigitalCount = 0;
    this.prevYrdigitalCount = 0;
    this.CurrYrEthinicCount = 0;
    this.prevYrEthinicCount = 0;
    this.CurrYrPbCount = 0;
    this.prevYrPbCount = 0;
    this.CurrYrTotalOutlet = 0;
    this.prevYrTotalOutlet = 0;
    this.countOfDailyNewspaper2022 = 0;
    this.countOfWeeklyNewspaper2022 = 0;
    this.countOfDailyNewspaper2020 = 0;
    this.countOfWeeklyNewspaper2020 = 0;
    this.localEmployed2020 = 0;
    this.localEmployed2022 = 0;
    this.isnewsCountLoading = true;
    this.differentlocalId = 0;
    this.dailyCoundDifference = 0;
    this.weeklyCoundDifference = 0;
    this.totalCountDifference = 0;
    this.Audited2022Circulation = 0;
    this.Audited2020Circulation = 0;
    this.AuditedCoundDifference = 0;
    this.NewsPaperTable = [];
    this.DigitalSitesTable = [];
    this.DigitalSitesOver = [];
    this.EthnicVoiceTable = [];
    this.PBStationTable = [];
    this.TotalPopulation = 0;
    this.AdultPopulation = 0;
    this.AdultPopulationCalc = 0;
    this.DemoGraphicsAge = 0;
    this.DemoGraphicsmedianIncome = 0;
    this.DemoGraphicsPovertyRate = 0;
    this.MinRangeValue = 0;
    this.MaxRangeValue = 0;
  }

  stateChange(event: any, state: any) {
    if (event.source.selected) {
      d3.select('#tooltip').remove();
      d3.select('#map-container').remove();
      this.AlreadyClicked = false
      this.FilteredLocalNewsLandScapTable = [];
      this.FilterdNewsPaperTable = [];
      this.FilteredDigitalSitesTable = [];
      this.FilteredEthnicVoiceTable = [];
      this.FilteredPBStationTable = [];
      setTimeout(() => {
        let stateCode = state.StateCode;
        let navigationExtras: NavigationExtras = {
          queryParams: { state: state.StateAbbr, stateCode },
        };

        this.router.navigate([this.routerValue], navigationExtras);
      }, 50);
    }
  }

  tabChange(Tab: any) {
    let quary: any = this.params.snapshot.queryParamMap;

    let navigationExtras: NavigationExtras = {
      queryParams: { 'state': quary.params.state, stateCode: quary.params.stateCode },
    };
    if (Tab.index === 0) {
      this.router.navigate(['state-localnewslandscape'], navigationExtras);

    } else if (Tab.index === 1) {
      this.router.navigate(['state-newspapers'], navigationExtras);
    } else if (Tab.index === 2) {
      this.router.navigate(['state-standalonedigitalsites'], navigationExtras);
    } else if (Tab.index === 3) {
      this.router.navigate(['state-networkdigitalsites'], navigationExtras);
    } else if (Tab.index === 4) {
      this.router.navigate(['state-ethnicoutlets'], navigationExtras);
    } else if (Tab.index === 5) {
      this.router.navigate(['state-publicbroadcasting'], navigationExtras);
    }

    // this.Loading = true;
    // this.AlreadyClicked = false
    // d3.select('#tooltip').remove();
    // d3.select('#map-container').remove();
    // d3.select("#legend1").remove()
    // this.TabIndex = Tab.index;
    // this.AlreadyClicked = false
    // this.FilteredLocalNewsLandScapTable = [];
    // this.FilterdNewsPaperTable = [];
    // this.FilteredDigitalSitesTable = [];
    // this.FilteredEthnicVoiceTable = [];
    // this.FilteredPBStationTable = [];
    // setTimeout(() => {
    //   this.TabChangeApis(
    //     this.TabIndex,
    //     quary.params.state,
    //     quary.params.stateCode
    //   );
    // }, 100);
  }

  radioChange(radioValue: any) {
    this.Loading = true;
    d3.select('#tooltip').remove();
    d3.select('#map-container').remove();
    d3.select("#legend1").remove()
    let quary: any = this.params.snapshot.queryParamMap;
    this.FilteredLocalNewsLandScapTable = [];
    this.FilterdNewsPaperTable = [];
    this.FilteredDigitalSitesTable = [];
    this.FilteredEthnicVoiceTable = [];
    this.FilteredPBStationTable = [];
    this.AlreadyClicked = false
    this.TabChangeApis(
      this.TabIndex,
      quary.params.state,
      quary.params.stateCode
    );
  }

  TabNameForIndex: any;

  TabChangeApis(tabIndex: any, StateName: any, StateCode: any) {
    this.TabNameForIndex = this.RadioOptionValue;
    if (tabIndex === 0) {
      this.showNewsOutletsByCountyTab = true;
      this.showMedianIncomeTab = false;
      this.showPercapTab = false;
      this.showPopulationDensityTab = false;
      this.FilteredLocalNewsLandScapTable = []
      // this.LocalNewsLandScapTable = [];
      this.ParameterTabName = 'Local News Landscape';
      this.CallMapDescription(this.ParameterTabName, this.StateShortForm)
      this.DotsApiCallForLocalNewsLandScape(StateName, StateCode);
      // }

    } else if (tabIndex === 1) {
      this.FilterdNewsPaperTable = [];
      this.ParameterTabName = 'Newspapers';
      this.CallMapDescription(this.ParameterTabName, this.StateShortForm)
      this.DotsApiCallForNewsPaper(StateName, StateCode);
    } else if (tabIndex === 2) {
      this.FilteredDigitalSitesTable = []
      this.ParameterTabName = 'Digital Sites';
      this.CallMapDescription(this.ParameterTabName, this.StateShortForm)
      this.DotsApiCallForDigitalSites(StateName, StateCode);
    } else if (tabIndex === 3) {
      this.ParameterTabName = 'Network Sites';
      this.CallMapDescription(this.ParameterTabName, this.StateShortForm)
      this.DotsApiCallForNetworkSites(StateName, StateCode);
    } else if (tabIndex === 4) {
      this.FilteredEthnicVoiceTable = []
      this.ParameterTabName = 'Ethnic Voices';
      this.CallMapDescription(this.ParameterTabName, this.StateShortForm)
      this.DotsApiCallForEthnicVoice(StateName, StateCode);
    } else if (tabIndex === 5) {
      this.FilteredPBStationTable = []
      this.ParameterTabName = 'Public Broadcasting';
      this.CallMapDescription(this.ParameterTabName, this.StateShortForm)
      this.DotsApiCallForPublicPrdCast(StateName, StateCode);
    }
  }

  get2020newsPapaerCount(stateName: any, currDaily: any, currWeekly: any) {
    this.lniService.consolidation2022Api(stateName).subscribe({
      next: (response: any) => {
        this.countOfDailyNewspaper2020 = 0;
        this.countOfWeeklyNewspaper2020 = 0;

        response.consolidations.forEach((element: any) => {
          this.countOfDailyNewspaper2020 =
            this.countOfDailyNewspaper2020 + element.countOfDailyNewspaper;
          this.countOfWeeklyNewspaper2020 =
            this.countOfWeeklyNewspaper2020 + element.countOfWeeklyNewspaper;
        });

        let currentDaily = Number(currDaily);
        let prevDaily = Number(this.countOfDailyNewspaper2020);
        let currentWeekly = Number(currWeekly);
        let prevWeekly = Number(this.countOfWeeklyNewspaper2020);
        this.dailyCoundDifference = this.getDailyCountDifference(
          prevDaily,
          currentDaily
        );
        this.weeklyCoundDifference = this.getDailyCountDifference(
          prevWeekly,
          currentWeekly
        );

        let p1 =
          Number(this.countOfDailyNewspaper2020) +
          Number(this.countOfWeeklyNewspaper2020);
        let c1 =
          Number(this.countOfDailyNewspaper2022) +
          Number(this.countOfWeeklyNewspaper2022);

        this.totalCountDifference = this.getDailyCountDifference(p1, c1);
      },
      error: (error: any) => {
        // console.log(error);
      },
      complete: () => { },
    });
  }

  getRadioOptionValues(stateName: any) {
    let total = 0;
    this.lniService.NetworkSiteApiCallForDOts(stateName).subscribe({
      next: (response: any) => {
        response.forEach((element: any, index: any) => {
          if (element.network == 'Patch') {
            this.NetworkCountOfPatch++;
          } else {
            this.NetworkCountOfOther++;
          }
        })
        this.NetworkTotal = this.NetworkCountOfPatch + this.NetworkCountOfOther;

      }

    })

    this.lniService.getHeadMapDataForStateMAp(stateName).subscribe({
      next: (response: any) => {
        this.ConsolidationArr = response;
        let consolidate = response.consolidations[0];
        let total = 0;
        let total1 = 0;
        let rateIndex = 0;

        let medianIndex = 0;

        let DemoGraphicsAgeCount = 0

        response.consolidations.forEach((element: any, index: any) => {
          // if (element.medianIncome) {
          //   medianIndex = medianIndex + 1;
          //   total = total + Number(element.medianIncome);
          // }
          // if (element.percentageBelowPoverty) {
          //   rateIndex = rateIndex + 1;
          //   total1 = total1 + Number(element.percentageBelowPoverty);
          // }

          // if (element.medianAge) {

          //   DemoGraphicsAgeCount = DemoGraphicsAgeCount + 1
          //   this.DemoGraphicsAge = this.DemoGraphicsAge + (element.medianAge ? Number(element.medianAge) : 0)
          // }

          // Data for Ethinic voice card Table
          this.EthnicCountOfOther =
            this.EthnicCountOfOther + element.countOfOther + element.countOfNativeAmerican;
          this.EthnicCountOfBlack =
            this.EthnicCountOfBlack + element.countOfAfricanAmerican;
          this.EthnicCountOfAsian =
            this.EthnicCountOfAsian + element.countOfAsianAmerican;

          this.EthnicCountOfAmerican =
            this.EthnicCountOfAmerican + element.countOfNativeAmerican;
          this.EthnicCountOfHispanic =
            this.EthnicCountOfHispanic + element.countOfLatino;

          this.EthnicTotal = this.EthnicTotal + element.countOfOther + element.countOfAfricanAmerican + element.countOfAsianAmerican
            + element.countOfNativeAmerican + element.countOfLatino;

          // Data for Digital Sites card Table
          this.DigitalLocalProfit =
            this.DigitalLocalProfit +
            element.countOfTypeOfLocationOutletLocalProfit;
          this.DigitalLocalNoneProfit =
            this.DigitalLocalNoneProfit +
            element.countOfTypeOfLocationOutletLocalNonProfit;
          this.digitalStateProfit =
            this.digitalStateProfit +
            element.countOfTypeOfLocationOutletStateProfit;
          this.digitalStateNonProfit =
            this.digitalStateNonProfit +
            element.countOfTypeOfLocationOutletStateNonProfit;

          // Data for Public Broadcasting card Table
          this.PBLocalCount = this.PBLocalCount + element.nPRLocalCountyCount;
          this.PBNonLocalCount =
            this.PBNonLocalCount + element.nPRNonLocalCountyCount;
          this.PBStateLocalCount =
            this.PBStateLocalCount + element.pBSLocalCountyCount;
          this.PBStateNonLocalCount =
            this.PBStateNonLocalCount + element.pBSNonLocalCountyCount;

          this.countOfDailyNewspaper2022 =
            this.countOfDailyNewspaper2022 + element.countOfDailyNewspaper;
          this.countOfWeeklyNewspaper2022 =
            this.countOfWeeklyNewspaper2022 + element.countOfWeeklyNewspaper;


        });

        // Data for POPULATION RANK in Population card
        // this.PopulationRank = Number(consolidate.populationRankPerState);

        // Data for Demographics cards



        // this.DemoGraphicsAge = (this.DemoGraphicsAge / DemoGraphicsAgeCount).toFixed(2);


        // const splitedVal = this.DemoGraphicsAge.split('.')
        // const secondaryVal = splitedVal[1].split('');
        // let joinedVal: any = 0;
        // if (secondaryVal[0] == 0) {

        //   joinedVal = String(splitedVal[0]);
        // } else {
        //   joinedVal = String(splitedVal[0]) + '.' + String(secondaryVal[0]);

        // }

        // this.DemoGraphicsAge = joinedVal;

        // let avg = total / medianIndex;
        // let avg1 = total1 / rateIndex;
        // this.DemoGraphicsmedianIncome = avg.toFixed();

        // this.DemoGraphicsPovertyRate = avg1;

        this.get2020newsPapaerCount(
          stateName,
          this.countOfDailyNewspaper2022,
          this.countOfWeeklyNewspaper2022
        );

        let quary: any = this.params.snapshot.queryParamMap;
        this.TabChangeApis(
          this.TabIndex,
          quary.params.state,
          quary.params.stateCode
        );
      },
      error: (error: any) => {
        // console.log(error);
      },
      complete: () => { },
    });
  }




  // Dots APICalling function for NewsPaper Section in Map section
  DotsApiCallForLocalNewsLandScape(stateName: any, stateCode: any) {
    const response = this.ConsolidationArr
    this.getPaperDetailes(stateName, stateCode, response.consolidations, "localnewsLandScape");
    this.LocalNewsLandScapTable = [];
    response.consolidations.forEach((element: any) => {
      let existingCounty = this.LocalNewsLandScapTable.find(
        (ele: any) => ele.fips === element.fips
      );
    });
  }


  // Dots APICalling function for NewsPaper Section in Map section
  DotsApiCallForNewsPaper(stateName: any, stateCode: any) {
    this.subs.add(
      this.lniService.newsPaperApiCallForDots(stateName, 2024).subscribe({
        next: (response: any) => {
          this.NewsPaperTable = [];
          this.Audited2022Circulation = 0;
          let number = 0;
          response.activePapers.forEach((element: any, index: any) => {
            if (isNaN(Number(element.totalCirculation))) {
              number = 0;
            } else {
              number = Number(element.totalCirculation);
            }
            this.Audited2022Circulation = number + this.Audited2022Circulation;
            let existingCounty = this.NewsPaperTable.find(
              (ele: any) => ele.fips == element.fips
            );
            const TableObj = {
              fips: element.fips,
              county: element.county,
              newspaperName: element.newspaperName,
              ownerName: element.ownerName,
              Frequency: element.frequency,
            };
            this.NewsPaperTable.push(TableObj);
          });
          const million = 1000000;
          this.Audited2022Circulation = this.Audited2022Circulation / million;
          this.Audited2022Circulation = Number(
            this.Audited2022Circulation.toFixed(1)
          );
          this.getAutitedCircularionFor2020(stateName);
          this.getPaperDetailes(stateName, stateCode, response.activePapers, "newspaper");
        },
        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    );
  }

  // Dots APICalling function for Digital Sites in Map section
  DotsApiCallForDigitalSites(stateName: any, stateCode: any) {
    this.subs.add(
      this.lniService.DigitalSiteApiCallForDots(stateName, 2024).subscribe({
        next: (response: any) => {
          this.getPaperDetailes(stateName, stateCode, response.digitalSites, "digitalSites");
          this.DigitalSitesTable = [];
          this.DigitalSitesOver = [];
          response.digitalSites.forEach((element: any) => {
            let existingCounty = this.DigitalSitesTable.find(
              (ele: any) => ele.fips == element.fips
            );
            const TableObj = {
              county:
                existingCounty && this.DigitalSitesTable.length > 0
                  ? element.county
                  : element.county,
              organization: element.organization,
              website: element.website,
              profitType: element.profitType,
              typeOfOutlet: element.typeOfOutlet,
              fips: element.fips,
            };
            this.DigitalSitesTable.push(TableObj);
          });
        },
        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    );
  }

  DotsApiCallForNetworkSites(stateName: any, stateCode: any) {
    this.subs.add(
      this.lniService.NetworkSiteApiCallForDOts(stateName).subscribe({
        next: (response: any) => {
          this.getPaperDetailes(stateName, stateCode, response, "networkSites");
          this.NetworkSitesTable = [];
          this.NetworkSitesOver = [];
          response.forEach((element: any) => {
            let existingCounty = this.NetworkSitesTable.find(
              (ele: any) => ele.fips == element.fips
            );
            const TableObj = {
              county:
                existingCounty && this.NetworkSitesTable.length > 0
                  ? element.county
                  : element.county,
              network: element.sites,
              link: element.link,
              fips: element.fips,
            };
            this.NetworkSitesTable.push(TableObj);
          });
        },
        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    );
  }

  // Dots APICalling function for Ethinic Voice in Map section
  DotsApiCallForEthnicVoice(stateName: any, stateCode: any) {
    this.subs.add(
      this.lniService.EthinicVoiceApiCallForDots(stateName, 2024).subscribe({
        next: (response: any) => {
          this.getPaperDetailes(stateName, stateCode, response.ethnicMedia, "ethnic");

          this.EthnicVoiceTable = [];
          response.ethnicMedia.forEach((element: any) => {
            let existingCounty = this.EthnicVoiceTable.find(
              (ele: any) => ele.fips == element.fips
            );
            const TableObj = {
              county:
                existingCounty && this.EthnicVoiceTable.length > 0
                  ? element.county
                  : element.county,
              outletName: element.outletName,
              format: element.format,
              ethnicGroup: element.ethnicGroup,
              fips: element.fips,
            };
            this.EthnicVoiceTable.push(TableObj);
          });
        },
        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    );
  }

  getAutitedCircularionFor2020(stateName: any) {
    this.subs.add(
      this.lniService.newsPaperApiCallForDots(stateName, 2022).subscribe({
        next: (response: any) => {
          this.Audited2020Circulation = 0;
          let number = 0;
          response.activePapers.forEach((element: any) => {
            if (isNaN(Number(element.totalCirculation))) {
              number = 0;
            } else {
              number = Number(element.totalCirculation);
            }
            this.Audited2020Circulation = number + this.Audited2020Circulation;
          });

          const million = 1000000;
          this.Audited2020Circulation = this.Audited2020Circulation / million;
          this.Audited2020Circulation = Number(
            this.Audited2020Circulation.toFixed(1)
          );

          this.AuditedCoundDifference = this.getDailyCountDifference(
            this.Audited2020Circulation,
            this.Audited2022Circulation
          );
        },
        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    );
  }
  FilterdNewsPaperTable: any = [];
  FilteredLocalNewsLandScapTable: any = []
  key: any = [];

  showMedianIncomeTab: boolean = true;
  showPopulationDensityTab: boolean = false;
  showPercapTab: boolean = false;
  showNewsOutletsByCountyTab: boolean = false;

  CountOfNewsPaper: number = 0;
  CountOfDigitalSites: number = 0;
  CountOfNetworkSites: number = 0;
  CountOfEthnicOutlets: number = 0;
  CountOfPublicBoardCasting: number = 0;

  LocalClickedFips = ''

  FilterLNLSTable(stateSHort: any) {
    this.LocalClickedFips = stateSHort.fips;
    this.LocalClickedCounty = stateSHort?.county;
    this.FilteredLocalNewsLandScapTable = [];
    this.LocalNewsLandScapTable.forEach((element: any) => {
      if (element.fips === stateSHort?.fips) {
        this.FilteredLocalNewsLandScapTable.push(element);
      }
    });
    this.FilteredLocalNewsLandScapTable = [
      {
        medianName: this.LocalClickedCounty
      }
    ]
    let country;
    const stateData = this.ConsolidationArr.consolidations;
    if (this.RadioOptionValue == 'newsOutlet') {
      this.showMedianIncomeTab = false;
      this.showPopulationDensityTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = true;
    }
    else if (this.RadioOptionValue == 'medianIncome') {
      country = stateData.find((ele: any) => (ele.fips == this.LocalClickedFips));
      this.MedianIncomeForTable = this.getFormattedCurrency(country.medianIncome);
      this.showMedianIncomeTab = true;
      this.showPopulationDensityTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = false;
    } else if (this.RadioOptionValue == 'densitySqm') {
      country = stateData.find((ele: any) => (ele.fips == this.LocalClickedFips));
      this.PopulationForTable = this.getFormattedPopulation(country.population);
      this.PopulationDensityForTable = this.getFormattedDensity(country.densitySqm);
      this.showPopulationDensityTab = true;
      this.showMedianIncomeTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = false;
    } else if (this.RadioOptionValue == 'perGap') {
      country = stateData.find((ele: any) => (ele.fips == this.LocalClickedFips));
      this.DemocratsForTable = country.perDem;
      this.RepublicansForTable = country.perGap;
      this.showPercapTab = true;
      this.showMedianIncomeTab = false;
      this.showPopulationDensityTab = false;
      this.showNewsOutletsByCountyTab = false;
    }

    this.lniService.localNewsLandScapeApiCallForTable(stateSHort.state, this.LocalClickedFips, 2024).subscribe({
      next: (response: any) => {
        this.FilteredLocalNewsLandScapTable = response;
        this.CountOfDigitalSites = 0;
        this.CountOfEthnicOutlets = 0;
        this.CountOfNewsPaper = 0;
        this.CountOfPublicBoardCasting = 0;
        this.CountOfNetworkSites = 0;
        for (let i = 0; i < this.FilteredLocalNewsLandScapTable.length; i++) {
          if (this.FilteredLocalNewsLandScapTable[i].mediaType == "Newspapers") {
            this.CountOfNewsPaper = this.CountOfNewsPaper + 1;
          } else if (this.FilteredLocalNewsLandScapTable[i].mediaType == "Ethnic Outlets") {
            this.CountOfEthnicOutlets = this.CountOfEthnicOutlets + 1;
          } else if (this.FilteredLocalNewsLandScapTable[i].mediaType == "Digital sites") {
            this.CountOfDigitalSites = this.CountOfDigitalSites + 1;
          } else if (this.FilteredLocalNewsLandScapTable[i].mediaType == "Public Broadcasting") {
            this.CountOfPublicBoardCasting = this.CountOfPublicBoardCasting + 1;
          } else if (this.FilteredLocalNewsLandScapTable[i].mediaType == "Network Sites") {
            this.CountOfNetworkSites = this.CountOfNetworkSites + 1;
          }
        }
        if (this.FilteredLocalNewsLandScapTable.length == 0) {
          this.LocalNewsLandScapeTotalOutlet = 0;
        } else {
          this.LocalNewsLandScapeTotalOutlet = this.FilteredLocalNewsLandScapTable.length;
        }
      }
    })
  }

  getFormattedCurrency(value: any): string {
    const formattedValue = this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
    return formattedValue ? formattedValue : '';
  }

  getFormattedPopulation(value: any): string {
    const formattedValue = this.currencyPipe.transform(value, 'USD', '', '1.0-0', 'en');
    return formattedValue !== null ? formattedValue : '';
  }

  getFormattedDensity(value: any): string {
    const formattedValue = this.currencyPipe.transform(value, 'USD', '', '1.0-1', 'en');
    return formattedValue !== null ? formattedValue : '';
  }

  NewsClickedCounty = ''
  NewsClickedFips = ''
  NewsPapersWeekly: number = 0;
  NewsPapersDaily: number = 0;


  FilterTable(stateSHort: any) {
    let daily = 0;
    let weekly = 0;
    let stateAbbr: any = null;
    this.NewsClickedFips = stateSHort.fips
    this.NewsClickedCounty = stateSHort?.county
    this.FilterdNewsPaperTable = [];
    this.CountOfNewsPaper = 0;

    this.NewsPaperTable.forEach((element: any) => {
      if (element.fips == stateSHort?.fips) {
        element.fips = stateSHort?.fips;
        this.FilterdNewsPaperTable.push(element);
        if (element.Frequency == 'D') {
          daily = daily + 1;
        } else if (element.Frequency == 'W') {
          weekly = weekly + 1;
        }
        this.CountOfNewsPaper = daily + weekly;
      }
    });
    let country;
    const stateData = this.ConsolidationArr.consolidations;
    if (this.RadioOptionValue == 'newsOutlet') {
      country = stateData.find((ele: any) => (ele.fips == this.NewsClickedFips));
      this.showMedianIncomeTab = false;
      this.showPopulationDensityTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = true;
    }
    else if (this.RadioOptionValue == 'medianIncome') {
      country = stateData.find((ele: any) => (ele.fips == this.NewsClickedFips));
      this.MedianIncomeForTable = this.getFormattedCurrency(country.medianIncome);
      this.showMedianIncomeTab = true;
      this.showPopulationDensityTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = false;
    } else if (this.RadioOptionValue == 'densitySqm') {
      country = stateData.find((ele: any) => (ele.fips == this.NewsClickedFips));
      this.PopulationForTable = this.getFormattedPopulation(country.population);
      this.PopulationDensityForTable = this.getFormattedDensity(country.densitySqm);
      this.showPopulationDensityTab = true;
      this.showMedianIncomeTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = false;
    } else if (this.RadioOptionValue == 'perGap') {
      country = stateData.find((ele: any) => (ele.fips === this.NewsClickedFips));
      this.DemocratsForTable = country.perDem;
      this.RepublicansForTable = country.perGap;
      this.showPercapTab = true;
      this.showMedianIncomeTab = false;
      this.showPopulationDensityTab = false;
      this.showNewsOutletsByCountyTab = false;
    }
    this.NewsPapersDaily = daily;
    this.NewsPapersWeekly = weekly;
  }

  FilteredDigitalSitesTable: any = [];

  DigitalClickedCounty = ''
  DigitalClickedFips = ''
  DigitalSiteLocalProfit: number = 0;
  DigitalSiteLocalNonProfit: number = 0;
  DigitalSiteStateProfit: number = 0;
  DigitalSiteStateNonProfit: number = 0;


  FilterDigitalSitesTable(stateSHort: any) {
    let localProfit = 0;
    let localNonProfit = 0;
    let stateProfit = 0;
    let stateNonProfit = 0;
    this.FilteredDigitalSitesTable = [];
    this.DigitalClickedFips = stateSHort.fips
    this.DigitalClickedCounty = stateSHort?.county;
    this.CountOfDigitalSites = 0;
    this.DigitalSitesTable.forEach((element: any) => {
      if (element.fips == stateSHort?.fips) {
        this.FilteredDigitalSitesTable.push(element);
        if (element.typeOfOutlet == 'Local') {
          if (element.profitType == 'For-Profit') {
            localProfit = localProfit + 1;
          } else if (element.profitType == 'Non-Profit') {
            localNonProfit = localNonProfit + 1;
          }
        } else if (element.typeOfOutlet == 'State') {
          if (element.profitType == 'For-Profit') {
            stateProfit = stateProfit + 1;
          } else if (element.profitType == 'Non-Profit') {
            stateNonProfit = stateNonProfit + 1;
          }
        }
        this.CountOfDigitalSites = localProfit + localNonProfit + stateProfit + stateNonProfit;
      }
    });
    let country;
    const stateData = this.ConsolidationArr.consolidations;
    if (this.RadioOptionValue == 'newsOutlet') {
      this.showMedianIncomeTab = false;
      this.showPopulationDensityTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = true;
    }
    else
      if (this.RadioOptionValue == 'medianIncome') {
        country = stateData.find((ele: any) => (ele.fips == this.DigitalClickedFips));
        this.MedianIncomeForTable = this.getFormattedCurrency(country.medianIncome);
        this.showMedianIncomeTab = true;
        this.showPopulationDensityTab = false;
        this.showPercapTab = false;
        this.showNewsOutletsByCountyTab = false;
      } else if (this.RadioOptionValue == 'densitySqm') {
        country = stateData.find((ele: any) => (ele.fips == this.DigitalClickedFips));
        this.PopulationForTable = this.getFormattedPopulation(country.population);
        this.PopulationDensityForTable = this.getFormattedDensity(country.densitySqm);
        this.showPopulationDensityTab = true;
        this.showMedianIncomeTab = false;
        this.showPercapTab = false;
        this.showNewsOutletsByCountyTab = false;
      } else if (this.RadioOptionValue == 'perGap') {
        country = stateData.find((ele: any) => (ele.fips == this.DigitalClickedFips));
        this.DemocratsForTable = country.perDem;
        this.RepublicansForTable = country.perGap;
        this.showPercapTab = true;
        this.showMedianIncomeTab = false;
        this.showPopulationDensityTab = false;
        this.showNewsOutletsByCountyTab = false;
      }
    this.DigitalSiteLocalProfit = localProfit;
    this.DigitalSiteLocalNonProfit = localNonProfit;
    this.DigitalSiteStateProfit = stateProfit;
    this.DigitalSiteStateNonProfit = stateNonProfit;
  }

  FilteredNetworkSitesTable: any = [];
  NetworkClickedCounty = '';
  NetworkClickedFips = '';

  FilterNetworkSitesTable(stateSHort: any) {
    this.FilteredNetworkSitesTable = [];
    this.NetworkClickedFips = stateSHort?.fips;
    this.NetworkClickedCounty = stateSHort?.county;
    this.NetworkSitesTable.forEach((element: any) => {
      if (element.fips == stateSHort?.fips) {
        this.FilteredNetworkSitesTable.push(element);
      }
    });
    this.CountOfNetworkSites = this.FilteredNetworkSitesTable.length;
    let country;
    const stateData = this.ConsolidationArr.consolidations;
    if (this.RadioOptionValue == 'newsOutlet') {
      this.showMedianIncomeTab = false;
      this.showPopulationDensityTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = true;
    }
    else if (this.RadioOptionValue == 'medianIncome') {
      country = stateData.find((ele: any) => (ele.fips == this.NetworkClickedFips));
      this.MedianIncomeForTable = this.getFormattedCurrency(country.medianIncome);
      this.showMedianIncomeTab = true;
      this.showPopulationDensityTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = false;
    } else if (this.RadioOptionValue == 'densitySqm') {
      country = stateData.find((ele: any) => (ele.fips == this.NetworkClickedFips));
      this.PopulationForTable = this.getFormattedPopulation(country.population);
      this.PopulationDensityForTable = this.getFormattedDensity(country.densitySqm);
      this.showPopulationDensityTab = true;
      this.showMedianIncomeTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = false;
    } else if (this.RadioOptionValue == 'perGap') {
      country = stateData.find((ele: any) => (ele.fips == this.NetworkClickedFips));
      this.DemocratsForTable = country.perDem;
      this.RepublicansForTable = country.perGap;
      this.showPercapTab = true;
      this.showMedianIncomeTab = false;
      this.showPopulationDensityTab = false;
      this.showNewsOutletsByCountyTab = false;
    }
  }

  FilteredEthnicVoiceTable: any = [];
  EthinicClickedCounty = ''
  EthnicClickedFips = ''
  EthnicLatinoCount: number = 0;
  EthnicAsianAmericanCount: number = 0;
  EthnicAfricanAmericanCount: number = 0;
  EthnicNativeAmericanCount: number = 0;
  EthnicOthersCount: number = 0;

  FilterEthnicVoiceTable(stateSHort: any) {
    let LatinoCount = 0;
    let AsianAmericanCount = 0;
    let AfricanAmericanCount = 0;
    let NativeAmericalCount = 0;
    let OtherCount = 0;

    this.FilteredEthnicVoiceTable = [];
    this.CountOfEthnicOutlets = 0;
    this.EthnicClickedFips = stateSHort.fips
    this.EthinicClickedCounty = stateSHort?.county
    this.EthnicVoiceTable.forEach((element: any) => {
      if (element.fips == stateSHort?.fips) {
        this.FilteredEthnicVoiceTable.push(element);
        if (element.ethnicGroup == 'Latino') {
          LatinoCount = LatinoCount + 1;
        } else if (element.ethnicGroup == 'Asian American') {
          AsianAmericanCount = AsianAmericanCount + 1;
        } else if (element.ethnicGroup == 'African American') {
          AfricanAmericanCount = AfricanAmericanCount + 1;
        } else if (element.ethnicGroup == 'Native American') {
          NativeAmericalCount = NativeAmericalCount + 1;
        } else if (element.ethnicGroup == 'Other') {
          OtherCount = OtherCount + 1;
        }
        this.CountOfEthnicOutlets = LatinoCount + AsianAmericanCount + AfricanAmericanCount + NativeAmericalCount + OtherCount;
      }
    });
    let country;
    const stateData = this.ConsolidationArr.consolidations;
    if (this.RadioOptionValue == 'newsOutlet') {
      this.showMedianIncomeTab = false;
      this.showPopulationDensityTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = true;
    }
    else
      if (this.RadioOptionValue == 'medianIncome') {
        country = stateData.find((ele: any) => (ele.fips == this.EthnicClickedFips));
        this.MedianIncomeForTable = this.getFormattedCurrency(country.medianIncome);
        this.showMedianIncomeTab = true;
        this.showPopulationDensityTab = false;
        this.showPercapTab = false;
        this.showNewsOutletsByCountyTab = false;
      } else if (this.RadioOptionValue == 'densitySqm') {
        country = stateData.find((ele: any) => (ele.fips == this.EthnicClickedFips));
        this.PopulationForTable = this.getFormattedPopulation(country.population);
        this.PopulationDensityForTable = this.getFormattedDensity(country.densitySqm);
        this.showPopulationDensityTab = true;
        this.showMedianIncomeTab = false;
        this.showPercapTab = false;
        this.showNewsOutletsByCountyTab = false;
      } else if (this.RadioOptionValue == 'perGap') {
        country = stateData.find((ele: any) => (ele.fips == this.EthnicClickedFips));
        this.DemocratsForTable = country.perDem;
        this.RepublicansForTable = country.perGap;
        this.showPercapTab = true;
        this.showMedianIncomeTab = false;
        this.showPopulationDensityTab = false;
        this.showNewsOutletsByCountyTab = false;
      }
    this.EthnicLatinoCount = LatinoCount;
    this.EthnicAsianAmericanCount = AsianAmericanCount;
    this.EthnicAfricanAmericanCount = AfricanAmericanCount;
    this.EthnicNativeAmericanCount = NativeAmericalCount;
    this.EthnicOthersCount = OtherCount;
  }



  PBClickedCounty = ''
  PBClickedFips = ''
  PBCPacificaCount: number = 0;
  PBCStationCount: number = 0;
  PBCPBSCount: number = 0;
  PBCAPMCount: number = 0;
  PBCNPRCount: number = 0;

  FilteredPBStationTable: any = [];
  FilterPBStationTable(stateSHort: any) {
    let pacificaCount = 0;
    let stationsCount = 0;
    let PBSCount = 0;
    let APMCount = 0;
    let NPRCount = 0;

    this.FilteredPBStationTable = [];
    this.CountOfPublicBoardCasting = 0;
    this.PBClickedFips = stateSHort.fips;
    this.PBClickedCounty = stateSHort?.county
    this.PBStationTable.forEach((element: any) => {
      if (element.fips == stateSHort?.fips) {
        this.FilteredPBStationTable.push(element);
        if (element.publicBroadcastingType == 'Pacifica') {
          pacificaCount = pacificaCount + 1;
        } else if (element.publicBroadcastingType == 'Stations') {
          stationsCount = stationsCount + 1;
        } else if (element.publicBroadcastingType == 'PBS') {
          PBSCount = PBSCount + 1;
        } else if (element.publicBroadcastingType == 'APM') {
          APMCount = APMCount + 1;
        } else if (element.publicBroadcastingType == 'NPR') {
          NPRCount = NPRCount + 1;
        }

        this.CountOfPublicBoardCasting = pacificaCount + stationsCount + PBSCount + APMCount + NPRCount;
        // this.CountOfPublicBoardCasting = this.FilterPBStationTable.length;
      }
    });
    let country;
    const stateData = this.ConsolidationArr.consolidations;
    if (this.RadioOptionValue == 'newsOutlet') {
      this.showMedianIncomeTab = false;
      this.showPopulationDensityTab = false;
      this.showPercapTab = false;
      this.showNewsOutletsByCountyTab = true;
    }
    else
      if (this.RadioOptionValue == 'medianIncome') {
        country = stateData.find((ele: any) => (ele.fips == this.PBClickedFips));
        this.MedianIncomeForTable = this.getFormattedCurrency(country.medianIncome);
        this.showMedianIncomeTab = true;
        this.showPopulationDensityTab = false;
        this.showPercapTab = false;
        this.showNewsOutletsByCountyTab = false;
      } else if (this.RadioOptionValue == 'densitySqm') {
        country = stateData.find((ele: any) => (ele.fips == this.PBClickedFips));
        this.PopulationForTable = this.getFormattedPopulation(country.population);
        this.PopulationDensityForTable = this.getFormattedDensity(country.densitySqm);
        this.showPopulationDensityTab = true;
        this.showMedianIncomeTab = false;
        this.showPercapTab = false;
        this.showNewsOutletsByCountyTab = false;
      } else if (this.RadioOptionValue == 'perGap') {
        country = stateData.find((ele: any) => (ele.fips == this.PBClickedFips));
        this.DemocratsForTable = country.perDem;
        this.RepublicansForTable = country.perGap;
        this.showPercapTab = true;
        this.showMedianIncomeTab = false;
        this.showPopulationDensityTab = false;
        this.showNewsOutletsByCountyTab = false;
      }
    this.PBCPacificaCount = pacificaCount;
    this.PBCStationCount = stationsCount;
    this.PBCPBSCount = PBSCount;
    this.PBCAPMCount = APMCount;
    this.PBCNPRCount = NPRCount;
  }

  // Dots APICalling function for Public Prodacasting in Map section
  DotsApiCallForPublicPrdCast(stateName: any, stateCode: any) {
    this.subs.add(
      this.lniService.publicProdcastApiCallForDots(stateName, 2024).subscribe({
        next: (response: any) => {
          this.getPaperDetailes(
            stateName,
            stateCode,
            response.publicBroadcastings,
            "publicbraodcasting"
          );

          this.PBStationTable = [];
          response.publicBroadcastings.forEach((element: any) => {
            let existingCounty = this.PBStationTable.find(
              (ele: any) => ele.fips == element.fips
            );
            const TableObj = {
              county:
                existingCounty && this.PBStationTable.length > 0
                  ? element.county
                  : element.county,
              station: element.station,
              publicBroadcastingType: element.publicBroadcastingType,
              fips: element.fips,
            };
            this.PBStationTable.push(TableObj);
          });
        },
        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    );
  }

  // Getting head Map data from API for All Section
  getPaperDetailes(stateName: any, stateCode: any, DotValue: any, buttonName: any) {
    if (buttonName === 'localnewsLandScape') {
      if (this.RadioOptionValue === 'medianIncome') {
        this.ConsolidationArr.consolidations.forEach((element: any) => {
          element.totalCirculation = Number(element.medianIncome ? element.medianIncome : 0);
          element.fips = Number(element.fips);
          Object.assign(element, { value: Number(element.medianIncome ? element.medianIncome : 0) });
          Object.assign(element, { value1: Number(element.medianIncome ? element.medianIncome : 0) });
        });
      } else if (this.RadioOptionValue === 'densitySqm') {
        this.ConsolidationArr.consolidations.forEach((element: any) => {
          const numberValue = parseFloat(element?.densitySqm ? element?.densitySqm?.replace(',', '') : 0);
          const numberValue1 = parseFloat(element?.population ? element?.population?.replace(',', '') : 0);
          element.totalCirculation = Number(numberValue);
          element.fips = Number(element.fips);
          Object.assign(element, { value: Number(numberValue) ? Number(numberValue) : 0 });
          Object.assign(element, { value1: Number(numberValue1) ? Number(numberValue1) : 0 });
        });
      } else if (this.RadioOptionValue === 'perGap') {
        this.ConsolidationArr.consolidations.forEach((element: any) => {
          const numberValue = parseFloat(element?.perGap ? element?.perGap?.replace('%', '') : 0);
          const numberValue1 = parseFloat(element?.perDem ? element?.perDem?.replace('%', '') : 0);
          element.totalCirculation = Number(numberValue);
          element.fips = Number(element.fips);
          Object.assign(element, { value: Number(numberValue) ? Number(numberValue) : 0 });
          Object.assign(element, { value1: Number(numberValue1) ? Number(numberValue1) : 0 });
        });
      }
    } else {
      if (this.RadioOptionValue === 'medianIncome') {
        this.ConsolidationArr.consolidations.forEach((element: any) => {
          element.totalCirculation = Number(element.medianIncome ? element.medianIncome : 0);
          element.fips = Number(element.fips);
          Object.assign(element, { value: Number(element.medianIncome ? element.medianIncome : 0) });
          Object.assign(element, { value1: Number(element.medianIncome ? element.medianIncome : 0) });
        });
      } else if (this.RadioOptionValue === 'densitySqm') {
        this.ConsolidationArr.consolidations.forEach((element: any) => {
          const numberValue = parseFloat(element?.densitySqm ? element?.densitySqm?.replace(',', '') : 0);
          const numberValue1 = parseFloat(element?.population ? element?.population?.replace(',', '') : 0);
          element.totalCirculation = Number(numberValue);
          element.fips = Number(element.fips);
          Object.assign(element, { value: Number(numberValue) ? Number(numberValue) : 0 });
          Object.assign(element, { value1: Number(numberValue1) ? Number(numberValue1) : 0 });
        });
      } else if (this.RadioOptionValue === 'perGap') {
        this.ConsolidationArr.consolidations.forEach((element: any) => {
          const numberValue = parseFloat(element?.perGap ? element?.perGap?.replace('%', '') : 0);
          const numberValue1 = parseFloat(element?.perDem ? element?.perDem?.replace('%', '') : 0);
          element.totalCirculation = Number(numberValue);
          element.fips = Number(element.fips);
          Object.assign(element, { value: Number(numberValue) ? Number(numberValue) : 0 });
          Object.assign(element, { value1: Number(numberValue1) ? Number(numberValue1) : 0 });
        });
      }
    }
    var minValue = this.ConsolidationArr.consolidations.reduce(function (
      min: any,
      obj: any
    ) {

      return obj.value < min && obj.value > 0 ? obj.value : min;
    },
      Infinity);

    let objectValueArr: any[] = [];
    // var maxValue = this.ConsolidationArr.consolidations.reduce(function (
    //   max: any,
    //   obj: any
    // ) {
    //   return obj.value > max && obj.value > 0 ? obj.value : max;
    // },
    //   -Infinity);
    var maxValue = this.ConsolidationArr.consolidations.reduce((max: any, obj: any) => {
      if (buttonName === "localnewsLandScape") {
        if (this.RadioOptionValue === "densitySqm") {
          objectValueArr.push(obj.value);
          if (obj.value > max && obj.value > 0) {
            max = obj.value;
          }
        } else {
          if (obj.value > max && obj.value > 0) {
            max = obj.value;
          }
        }
      } else {
        if (this.RadioOptionValue === "densitySqm") {
          objectValueArr.push(obj.value);
          if (obj.value > max && obj.value > 0) {
            max = obj.value;
          }
        } else {
          if (obj.value > max && obj.value > 0) {
            max = obj.value;
          }
        }
      }

      return max;
    }, -Infinity);

    objectValueArr.sort((a, b) => a - b);
    if (buttonName === "localnewsLandScape") {
      if (this.RadioOptionValue === "densitySqm") {
        if (objectValueArr.length > 4) {
          maxValue = objectValueArr[objectValueArr.length - 4];
        }
      }
    } else {
      if (this.RadioOptionValue === "densitySqm") {
        if (objectValueArr.length > 4) {
          maxValue = objectValueArr[objectValueArr.length - 4];
        }
      }
    }

    this.StateMapFormationWithD3Js(
      this.ConsolidationArr.consolidations,
      stateName,
      minValue,
      maxValue,
      DotValue,

    );
  }



  // Create State Map with heatmap and dots data
  StateMapFormationWithD3Js(
    HeadMapDatta: any,
    stateName: any,
    minValue: any,
    maxValue: any,
    DotValue: any
  ) {
    d3.select('#tooltip').remove();
    d3.select('#map-container').remove();

    if (minValue > 1000) {
      const suffixes = ['', 'k', 'M', 'B', 'T'];
      const suffixIndex = Math.floor(Math.log10(minValue) / 3);
      const shortValue = (minValue / Math.pow(1000, suffixIndex)).toFixed(0);
      this.MinRangeValue = shortValue + suffixes[suffixIndex];
    } else {
      this.MinRangeValue = minValue;
    }
    if (maxValue > 1000) {
      const suffixes = ['', 'k', 'M', 'B', 'T'];
      const suffixIndex = Math.floor(Math.log10(maxValue) / 3);
      const shortValue = (maxValue / Math.pow(1000, suffixIndex)).toFixed(0);
      this.MaxRangeValue = shortValue + suffixes[suffixIndex];
    } else {
      this.MaxRangeValue = maxValue;
    }
    this.getEthnicCount();

    Promise.all([d3.json('/projects/state-of-local-news/explore/assets/gz_2010_us_050_00_5m.json')]).then(
      // Promise.all([d3.json('/projects/state-of-local-news/explore/assets/gz_2010_us_050_00_5m_1.json')]).then(
      (Jsondata) => {
        const geojson1: any = Jsondata[0];
        const stateData: any = HeadMapDatta;
        let geojson: any = {
          type: 'FeatureCollection',
          features: [],
        };


        geojson1.features.forEach((element: any, index: any) => {
          if (element.properties.STUSPS === stateName) {
            geojson.features.push(element);
          }
        });


        let RadioOptionValue = this.RadioOptionValue;
        let circleData: any = [];
        let idx = 0;

        DotValue.forEach((element: any, index: any) => {
          let dotExists = undefined
          dotExists = geojson.features.find((dot: any) => (dot.properties.GEOID == element.fips) || (element.fips === dot.properties.GEOID));

          if (dotExists) {
            if (this.TabIndex === 0) {
              Object.assign(dotExists, { frequency: element });
            } else if (this.TabIndex === 1) {
              Object.assign(dotExists, { frequency: element.frequency });
            } else if (this.TabIndex === 2) {
              Object.assign(dotExists, { frequency: element.profitType });
            } else if (this.TabIndex === 3) {
              Object.assign(dotExists, { frequency: element.profitType });
            } else if (this.TabIndex === 4) {
              Object.assign(dotExists, { frequency: element.ethnicGroup });
            } else if (this.TabIndex === 5) {
              Object.assign(dotExists, {
                frequency: element.publicBroadcastingType,
              });
            }
            Object.assign(dotExists, { county: element.county });
            Object.assign(dotExists, { fips: element.fips });
            Object.assign(dotExists, { index: index });

            idx = idx + 1;
            let dotExists1 = { ...dotExists };
            circleData.push(dotExists1);
          }
        });


        const stateDataMap = new Map();
        const stateDataMap1 = new Map();
        stateData.forEach((d: any) => {
          stateDataMap.set(d.county, +d.value);
        });
        stateData.forEach((d: any) => {
          stateDataMap1.set(d.county, +d.value1);
        });


        var minColor: any = '#e4e0ee'
        var maxColor: any = '#4e2a84'
        const colorScale = d3
          .scaleLinear()
          .domain([minValue, maxValue])
          .range([minColor, maxColor]);

        const svg = d3
          .select('main')
          .append('div')
          .attr('id', 'map-container')
          .append('svg')
          .attr('width', this.width)
          .attr('height', this.height)
          .style('margin-top', this.Top)
          .style('transform', this.Zoom)
          .attr("viewBox", [0, 0, this.width, this.height])
        this.SVG = svg

        const tooltip: any = d3
          .select('main')
          .append('div')
          .attr('class', 'dataTip')
          .attr('style', 'position: absolute')
          .attr('id', 'tooltip');
        // .attr("viewBox", [0, 0, this.width, this.height]);

        const projection = d3
          .geoAlbersUsa()
          .fitSize([this.width, this.height], geojson);
        const path: any = d3.geoPath().projection(projection);
        let index = 0;
        const g: any = svg.append("g");
        let lastHoveredElement: any = null;

        this.MapG = g
        if (this.TabIndex === 0) {
          if (RadioOptionValue === 'densitySqm') {
            var self = this;

            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {

                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                const state = d.target.__data__.properties.NAME;
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');
                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true

                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterLNLSTable(country);
              });
          } else if (RadioOptionValue === 'perGap') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterLNLSTable(country);
              });
          } else if (RadioOptionValue === 'newsOutlet') {
            var self = this;
            // console.log('geojson.features',geojson.features);

            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))

                const total = value?.countOfNewspapers + value?.countOfDigitalSites + value?.countOfEthnicVoice + value?.countOfPBStation + value?.countOfNetwork;
                if (total >= 2) {
                  return '#4e2a84'
                } else if (total == 1) {
                  return '#b6acd1'
                } else {
                  return '#FFE59C'
                }
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target)
                  .style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })
              .on('mouseout', function (d: any) {
                d3.select(d.target)
                  .style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('.tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px');
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }
                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterLNLSTable(country);
              });
          } else {
            let i = 1;
            var self = this;

            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))

                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');
                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance

                lastHoveredElement = d.target;

              })
              .on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }
                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))

                self.FilterLNLSTable(country);
              });
          }
        }
        else if (this.TabIndex === 1) {
          let lastHoveredElement: any = null;

          if (RadioOptionValue === 'densitySqm') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }
                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');
                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');
                lastHoveredElement = d.target;
              })
              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px');
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterTable(country);
              });
          } else if (RadioOptionValue === 'perGap') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');
                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterTable(country);
              });
          } else if (RadioOptionValue === 'newsOutlet') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))
                const total = value?.countOfNewspapers;
                if (total >= 2) {
                  return '#4e2a84'
                } else if (total == 1) {
                  return '#b6acd1'
                }
                else {
                  return '#fff'
                }
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');
                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterTable(country);
              });
          }
          else {
            let i = 1;
            var self = this;

            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID
                let value
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              })
              .on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true

                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterTable(country);
              });
          }
        }
        else if (this.TabIndex === 2) {
          let lastHoveredElement: any = null;

          if (RadioOptionValue === 'densitySqm') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))

                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }


                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterDigitalSitesTable(country);
              });
          } else if (RadioOptionValue === 'perGap') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterDigitalSitesTable(country);
              });
          } else if (RadioOptionValue === 'newsOutlet') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))

                const total = value?.countOfDigitalSites;
                if (total >= 2) {
                  return '#4e2a84'
                } else if (total == 1) {
                  return '#b6acd1'
                } else {
                  return '#FFF'
                }
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterDigitalSitesTable(country);
              });
          }
          else {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }
                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterDigitalSitesTable(country);
              });
          }
        }
        else if (this.TabIndex === 3) {
          let lastHoveredElement: any = null;
          if (RadioOptionValue === 'densitySqm') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))

                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterNetworkSitesTable(country);
              });
          } else if (RadioOptionValue === 'perGap') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');
                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');
                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }
                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterNetworkSitesTable(country);
              });
          } else if (RadioOptionValue === 'newsOutlet') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))
                const total = value?.countOfNetwork;

                if (total >= 2) {
                  return '#4e2a84'
                } else if (total == 1) {
                  return '#b6acd1'
                } else {
                  return '#FFF'
                }
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');
                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterNetworkSitesTable(country);
              });
          }
          else {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))

                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterNetworkSitesTable(country);
              });
          }
        } else if (this.TabIndex === 4) {
          let lastHoveredElement: any = null;
          if (RadioOptionValue === 'densitySqm') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px');
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterEthnicVoiceTable(country);
              });
          } else if (RadioOptionValue === 'perGap') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')

              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px');
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterEthnicVoiceTable(country);
              });
          } else if (RadioOptionValue === 'newsOutlet') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))
                const total = value?.countOfEthnicVoice;
                if (total >= 2) {
                  return '#4e2a84'
                } else if (total == 1) {
                  return '#b6acd1'
                } else {
                  return '#FFF'
                }
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;

              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterEthnicVoiceTable(country);
              });
          } else {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })

              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px');
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterEthnicVoiceTable(country);
              });
          }
        } else if (this.TabIndex === 5) {
          let lastHoveredElement: any = null;
          if (RadioOptionValue === 'densitySqm') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {

                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px');
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterPBStationTable(country);
              });
          } else if (RadioOptionValue === 'perGap') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');
                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px');
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterPBStationTable(country);
              });
          } else if (RadioOptionValue === 'newsOutlet') {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')
              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value
                value = stateData.find((ele: any) => (ele.fips == state))
                const total = value?.countOfPBStation;
                if (total >= 2) {
                  return '#4e2a84'
                } else if (total == 1) {
                  return '#b6acd1'
                } else {
                  return '#FFF'
                }
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');

                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }
                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterPBStationTable(country);
              });
          } else {
            var self = this;
            const states = g.append("g")
              .attr("cursor", "pointer")
              .selectAll("path")
              .data(geojson.features)
              .enter()
              .append('path')
              .attr('d', path)
              .style('stroke', '#bec4db')
              .style('stroke-width', '0.5px')

              .attr('fill', (d: any) => {
                const state = d.properties.GEOID;
                let value;
                value = stateData.find((ele: any) => (ele.fips == state))
                if (!value) {
                  return '#fff'
                }
                return this.colorCountry(value.value, minValue, maxValue, value.value1);
              })
              .on('mouseover', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                d3.select(d.target).
                  style('stroke', 'rgb(13 45 108)')
                  .style('stroke-width', '2px');
                d3.select('.tooltip')
                  .style('stroke', '#000')
                  .style('stroke-width', '2px');

                lastHoveredElement = d.target;
              })

              .on('mouseout', function (d: any) {
                d3.select(d.target).
                  style('stroke', 'rgb(125, 145, 166)')
                  .style('stroke-width', '0.25px');

                // Remove the highlighting
                d3.select('tooltip')
                  .style('stroke', '#bec4db')
                  .style('stroke-width', '1px'); // Remove the stroke to revert to the original appearance
              }).on('click', function (d: any) {
                if (lastHoveredElement) {
                  d3.select(lastHoveredElement)
                    .style('stroke', '#bec4db')
                    .style('stroke-width', '0.5px');
                }

                self.AlreadyClicked = true
                const state = d.target.__data__.properties.GEOID
                let country;
                country = stateData.find((ele: any) => (ele.fips == state))
                self.FilterPBStationTable(country);
              });
          }
        }

        let CurrCxValue = 0;
        let CurrCYValue = 0;
        g
          .selectAll('circle')
          .data(circleData)
          .enter()
          .append('circle')
          .attr('cx', function (d: any) {
            if (CurrCxValue !== path.centroid(d)[0]) {
              CurrCxValue = path.centroid(d)[0];
              return path.centroid(d)[0];
            }
          })
          .attr('cy', function (d: any) {
            if (CurrCYValue !== path.centroid(d)[1]) {
              CurrCYValue = path.centroid(d)[1];
              return path.centroid(d)[1];
            }
          })

          .attr('name', function (d: any) {
            return d.county
          })
          .attr('r', function (d: any) {

            if (CurrCxValue !== path.centroid(d)[1]) {
              CurrCxValue = path.centroid(d)[1];

              const state = d.properties?.GEOID;
              let country;
              country = stateData.find((ele: any) => (ele.fips == state))

              if (self.TabIndex === 0) {
                if (self.RadioOptionValue === 'newsOutlet') {
                  return 0;
                } else {
                  const total = country?.countOfNewspapers + country?.countOfDigitalSites + country?.countOfEthnicVoice + country?.countOfPBStation
                  return (total && total > 0) ? (5 + total) : 0;
                }
              }
              else if (self.TabIndex === 1) {
                if (self.RadioOptionValue === 'newsOutlet') {
                  return 0;
                } else {
                  const total = country?.countOfDailyNewspaper + country?.countOfWeeklyNewspaper
                  if (total && total > 0) {
                    return 5 + total
                  } else {
                    return 0
                  }
                }


              } else if (self.TabIndex === 2) {
                let total =
                  country?.countOfTypeOfLocationOutletLocalProfit +
                  country?.countOfTypeOfLocationOutletLocalNonProfit +
                  country?.countOfTypeOfLocationOutletStateProfit +
                  country?.countOfTypeOfLocationOutletStateNonProfit;
                if (self.RadioOptionValue === 'newsOutlet') {
                  return 0;
                } else {
                  if (total && total > 0) {
                    return 5 + total
                  } else {
                    return 0
                  }
                }


              } else if (self.TabIndex === 3) {
                let total =
                  country?.countOfLatino +
                  country?.countOfAsianAmerican +
                  country?.countOfAfricanAmerican +
                  country?.countOfNativeAmerican +
                  country?.countOfOther;
                if (self.RadioOptionValue === 'newsOutlet') {
                  return 0;
                } else {
                  if (total && total > 0) {
                    return 5 + total
                  } else {
                    return 0
                  }
                }
                // return (5 + total ?? 0);
              } else {
                let total =
                  country?.countOfPacifica +
                  country?.countOfStations +
                  country?.countOfPBS +
                  country?.countOfAPM +
                  country?.countOfNPR;
                if (self.RadioOptionValue === 'newsOutlet') {
                  return 0;
                } else {
                  if (total && total > 0) {
                    return 5 + total
                  } else {
                    return 0
                  }
                }
                // return (5 + total ?? 0);
              }
            }
          })
          .attr('OX', function (d: any) {

            if (CurrCxValue !== path.centroid(d)[1]) {
              CurrCxValue = path.centroid(d)[1];

              const state = d.properties?.GEOID;
              let country;
              
              country = stateData.find((ele: any) => (ele.fips == state))
              
              if (self.TabIndex === 0) {
                if (self.RadioOptionValue == 'newsOutlet') {
                  return 0;
                } else {
                  const total = country?.countOfNewspapers + country?.countOfDigitalSites + country?.countOfEthnicVoice + country?.countOfPBStation
                  return (total && total > 0) ? (5 + total) : 0;
                }

              }
              else if (self.TabIndex === 1) {
                const total = country?.countOfDailyNewspaper + country?.countOfWeeklyNewspaper
                if (self.RadioOptionValue === 'newsOutlet') {
                  return 0;
                } else {
                  if (total && total > 0) {
                    return 5 + total
                  } else {
                    return 0
                  }
                }


              } else if (self.TabIndex === 2) {
                let total =
                  country?.countOfTypeOfLocationOutletLocalProfit +
                  country?.countOfTypeOfLocationOutletLocalNonProfit +
                  country?.countOfTypeOfLocationOutletStateProfit +
                  country?.countOfTypeOfLocationOutletStateNonProfit;
                if (self.RadioOptionValue === 'newsOutlet') {
                  return 0;
                } else {
                  if (total && total > 0) {
                    return 5 + total
                  } else {
                    return 0
                  }
                }

              } else if (self.TabIndex === 3) {
                let total =
                  country?.countOfLatino +
                  country?.countOfAsianAmerican +
                  country?.countOfAfricanAmerican +
                  country?.countOfNativeAmerican +
                  country?.countOfOther;
                if (self.RadioOptionValue === 'newsOutlet') {
                  return 0;
                } else {
                  if (total && total > 0) {
                    return 5 + total
                  } else {
                    return 0
                  }
                }
                // return (5 + total ?? 0);
              } else {
                let total =
                  country?.countOfPacifica +
                  country?.countOfStations +
                  country?.countOfPBS +
                  country?.countOfAPM +
                  country?.countOfNPR;
                if (self.RadioOptionValue === 'newsOutlet') {
                  return 0;
                } else {
                  if (total && total > 0) {
                    return 5 + total
                  } else {
                    return 0
                  }
                }
                // return (5 + total ?? 0);
              }
            }
          })
          .style('fill', function (d: any) {
            return '#1D0235';

          })
          .attr("fill-opacity", .3)
          .style('outline', function (d: any) {
            return '';
          })
          .attr('class', 'circle')
          .attr('id', 'legendCircle')
          .style('box-shadow', '0 4px 30px rgba(0, 0, 0, 0.1)')
          .style('backdrop-filter', 'blur(10.4px)')
          .style('-webkit-backdrop-filter', 'blur(10.4px)')
          .style('border-radius', '50%')


        const zoom: any = d3.zoom()
          .scaleExtent([1, 8])
          .on("zoom", zoomed.bind(this))
          .on("end", zoomEnd.bind(this));;
        this.ZOOM = zoom
        svg.call(zoom);

        function zoomEnd(event: any) {
          self.currentTransform = event.transform;
        }

        let timeoutId: any;
        function zoomed(event: any) {
          // // console.log(event?.sourceEvent?.type);

          if (event?.sourceEvent?.type === 'wheel') {
            // event?.sourceEvent?.preventDefault();
            return

          }

          // if (event?.sourceEvent?.type === 'dblclick') {

          //   clearTimeout(timeoutId);

          //   // Set a new timeout to execute the zoomIN function after 100ms
          //   timeoutId = setTimeout(() => {
          //     // console.log('a');
          //       self.zoomIN();
          //       event?.sourceEvent?.preventDefault();
          //   }, 100);

          //   return;

          // }
          // // console.log('q');


          setTimeout(() => {

            const { transform } = event;
            g.attr("transform", transform);
            g.attr("stroke-width", 1);
            self.TransFormedValue = transform

            let cirlces = document.querySelectorAll('circle')
            cirlces.forEach(element => {
              const originalRadius: any = element.getAttribute('OX')
              if (originalRadius != 0) {
                const zoomScale = transform.k;
                const radius: any = originalRadius / zoomScale;
                if (radius < 3) {
                  element.setAttribute("r", '3')
                } else {
                  element.setAttribute("r", radius)
                }
              }
            });
          }, 100);

        }


        let minValue1 = minValue
        const maxValue1 = maxValue

        const customInterpolator = (t: any) => {
          return d3.interpolateRgb('#fff', '#4e2a84')(t);
        };
        if (this.StateShortForm === 'DC') {
          minValue1 = 0
        }


        const colorScale1 = d3.scaleSequential(customInterpolator).domain([minValue1, maxValue1]);

        const div: any = document.getElementsByClassName('radioContainer'); // Get the div element by its ID
        const w = div[0].offsetWidth;
        d3.select("#legend1").remove();
        const ColorRangeLegend: any = d3
          .select('#legend')
          .append('div')

          .attr('id', 'legend1');

        const legendWidth = w - 15;
        const legendHeight = 50;
        const numSwatches = 5;
        const legendSvg = d3.select("#legend1")
          .append("svg")
          .attr("id", "svg1")
          .attr("width", legendWidth)
          .attr("height", legendHeight);

        const legendScale = d3.scaleLinear()
          .domain([0, numSwatches])
          .range([0, legendWidth]);
        const domainValues = d3.range(minValue1, maxValue1, (maxValue1 - minValue1) / numSwatches);

        // const domainValues1 = d3.range(minValue1, maxValue1, (maxValue1 - minValue1) / numSwatches+1));

        legendSvg.selectAll("rect")
          .data(domainValues)
          .enter()
          .append("rect")
          .attr("x", (d, index) => {
            const x = legendScale((d - minValue1) / (maxValue1 - minValue1) * (numSwatches))
            return x

          })
          .attr("y", 0)
          .attr("width", (legendWidth / numSwatches - 2) + 1)
          .attr("height", 20)
          .style("fill", (d) => colorScale1(d));


        legendSvg.selectAll("line.tick")
          .data(domainValues)
          .enter()
          .append("line")
          .attr("class", "tick")
          .attr("x1", (d) => {
            return legendScale((d - minValue1) / (maxValue1 - minValue1) * (numSwatches))
          })
          .attr("x2", (d) => {
            return legendScale((d - minValue1) / (maxValue1 - minValue1) * (numSwatches))

          })
          .attr("y1", 20)
          .attr("y2", 0)
          .style("stroke", "#4e2a84");

        let xValue = 0
        let fontSixe = '10px'

        if (this.DeviceWidth < 600) {
          fontSixe = '8px'
          xValue = 30
        } else {
          xValue = 15
          fontSixe = '10px'

        }

        legendSvg.selectAll("text.legend-label")
          .data(domainValues)
          .enter()
          .append("text")
          .attr("class", "legend-label")
          .attr("x", (d) => {
            return 10 + (legendScale((d - minValue1) / (maxValue1 - minValue1) * (numSwatches)));
          })
          .attr("y", 30)
          .text((d: any) => {
            if (d > 1000) {
              const suffixes = ['', 'k', 'M', 'B', 'T'];
              const suffixIndex = Math.floor(Math.log10(d) / 3);
              const shortValue = (d / Math.pow(1000, suffixIndex)).toFixed(0);
              const MinRangeValue = shortValue + suffixes[suffixIndex];
              return MinRangeValue
            } else {
              // const MinRangeValue = minValue1;
              return Math.round(d)
            }

          })
          .style("text-anchor", "middle")
          .style("font-size", fontSixe)
          .style("fill", "black");


        let MaxRangeValue: any = 0

        if (maxValue1 > 1000) {
          const suffixes = ['', 'k', 'M', 'B', 'T'];
          const suffixIndex = Math.floor(Math.log10(maxValue1) / 3);
          const shortValue = (maxValue1 / Math.pow(1000, suffixIndex)).toFixed(0);
          const MinRangeValue = shortValue + suffixes[suffixIndex];
          MaxRangeValue = MinRangeValue
        } else {
          // const MinRangeValue = minValue1;
          MaxRangeValue = Math.round(maxValue1)
        }

        legendSvg
          .append("text")
          .attr("class", "legend-label")
          .attr("x", legendWidth - xValue) // Position it at the end of the legend
          .attr("y", 30)
          .text(MaxRangeValue) // Display the maximum value
          .style("text-anchor", "middle")
          .style("font-size", fontSixe)
          .style("fill", "black");

        // End MuruganCK
        this.Loading = false;

      }

    );


  }

  getEthnicCount() {

    this.subs.add(
      this.lniService.getEthinicCount(this.StateShortForm).subscribe({
        next: (response: any) => {
          this.CurrYrEthinicCount = response.activePapersCompareResponseMap[this.StateShortForm].currentYearCountOfEthnicity
          this.CurrYrTotalOutlet = this.CurrYrnewsPaperCount + this.CurrYrdigitalCount + this.CurrYrEthinicCount + this.CurrYrPbCount + this.NetworkTotal


        },

        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    )
    // 
  }

  idx = 0;

  TransFormedValue = 0

  currentTransform = 0


  lerp(a: any, b: any, t: any) {
    return a + (b - a) * t;
  }

  colorCountry(value: any, minValue: any, maxValue: any, value1: any) {
    minValue = Math.round(minValue);
    maxValue = Math.round(maxValue);
    value = Math.round(value);
    if (this.StateShortForm == 'DC') {
      minValue = 0
    }

    if (this.ParameterTabName === 'Local News Landscape') {
      if (this.RadioOptionValue === 'medianIncome' || this.RadioOptionValue === 'densitySqm') {
        if (!value) {
          return '#E7EAF0';
        }

        const minColor = [228, 224, 238]; // White
        const maxColor = [78, 42, 132]; // #367CA0 in RGB

        const percentage = (value - minValue) / (maxValue - minValue);

        function lerp(a: number, b: number, t: number) {
          return a + (b - a) * t;
        }

        const color = minColor.map((channel, i) =>
          Math.round(lerp(channel, maxColor[i], percentage))
        );

        if (this.RadioOptionValue === 'densitySqm') {
          if (value > maxValue) {
            return "#4E2A84";
          }
        }

        return `#${color.map(componentToHex).join('')}`;
      } else {
        if (value > value1) {
          return '#df6c6e';
        } else {
          return '#84b4d2';
        }

      }
    } else {
      if (this.RadioOptionValue === 'medianIncome' || this.RadioOptionValue === 'densitySqm') {
        if (!value) {
          return '#E7EAF0';
        }

        const minColor = [228, 224, 238]; // White
        const maxColor = [78, 42, 132]; // #367CA0 in RGB

        const percentage = (value - minValue) / (maxValue - minValue);

        function lerp(a: number, b: number, t: number) {
          return a + (b - a) * t;
        }

        const color = minColor.map((channel, i) =>
          Math.round(lerp(channel, maxColor[i], percentage))
        );

        if (this.RadioOptionValue === 'densitySqm') {
          if (value > maxValue) {
            return "#4E2A84";
          }
        }

        return `#${color.map(componentToHex).join('')}`;
      } else {
        if (value > value1) {
          return '#df6c6e';
        } else {
          return '#84b4d2';
        }
      }
    }


    function componentToHex(c: number) {
      const hex = Math.round(c).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }
  }


  getLocalTotal() {
    let total: any = this.DigitalLocalProfit + this.DigitalLocalNoneProfit;
    return total;
  }

  getStateTotal() {
    let total: any = this.digitalStateProfit + this.digitalStateNonProfit;
    return total;
  }

  getProfitTotal() {
    let total: any = this.DigitalLocalProfit + this.digitalStateProfit;
    return total;
  }
  getNonProfitTotal() {
    let total: any = this.DigitalLocalNoneProfit + this.digitalStateNonProfit;
    return total;
  }

  getAllTotal() {
    let total: any = this.getLocalTotal() + this.getStateTotal();
    return total;
  }

  PBLocalCountTotal() {
    let total = this.PBLocalCount + this.PBNonLocalCount;
    return total;
  }

  PBStateCountTotal() {
    let total = this.PBStateLocalCount + this.PBStateNonLocalCount;
    return total;
  }

  getPbLocalTotal() {
    let total = this.PBLocalCount + this.PBStateLocalCount;
    return total;
  }
  getPBStateTotal() {
    let total = this.PBNonLocalCount + this.PBStateNonLocalCount;
    return total;
  }
  getAllPBTotal() {
    let total = this.PBLocalCountTotal() + this.PBStateCountTotal();
    return total;
  }

  ApiForCardsBelowStateMap2020(stateName: String, currentValue: any) {
    this.subs.add(
      this.lniService.CardsTableApi(2024, stateName).subscribe({
        next: (response: any) => {
          response.politics[0]
            ? (this.localEmployed2020 = response.politics[0]?.localEmployed)
            : (this.localEmployed2020 = 0);
          let previous = Number(this.localEmployed2020);
          let current = Number(currentValue);
          this.differentlocalId = this.getDailyCountDifference(
            previous,
            current
          );
        },
        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    );
  }

  ApiForCardsBelowStateMap(stateName: String) {
    this.subs.add(
      this.lniService.CensusTableApi(2024, stateName).subscribe({
        next: (response: any) => {
          let populationObj = response[0].population;
          let adultPopulationObj = response[0].adultPopulation;
          let populationRankObj = response[0].popRank;
          let medianIncomeObj = response[0].medianHouseholdIncome;
          let medianAgeObj = response[0].medianAge;
          let povertyRateObj = response[0].percentageInPoverty;

          this.TotalPopulation = Number(
            (Number(populationObj) / 1000000).toFixed(2)
          );
          this.AdultPopulation = Number(
            (Number(adultPopulationObj) / 1000000).toFixed(2)
          );

          this.PopulationRank = Number(populationRankObj);
          this.DemoGraphicsmedianIncome = Number(medianIncomeObj);
          this.DemoGraphicsAge = this.DemoGraphicsAge + (medianAgeObj ? Number(medianAgeObj) : 0);
          this.DemoGraphicsPovertyRate = Number(povertyRateObj);

        }
      }),

      this.lniService.CardsTableApi(2025, stateName).subscribe({
        next: (response: any) => {
          let politicsObj = response.politics[0];
          let BidenValue: number = parseFloat(
            politicsObj?.dem?.replace('%', '')
          );
          let trumpValue: number = parseFloat(
            politicsObj?.gop?.replace('%', '')
          );
          BidenValue = Number(BidenValue.toFixed(2));
          trumpValue = Number(trumpValue.toFixed(2));

          this.localEmployed2022 = response.politics[0]?.localEmployed;

          this.ApiForCardsBelowStateMap2020(stateName, this.localEmployed2022);
          let balance: number = 0;
          if (BidenValue > trumpValue) {
            balance = BidenValue - trumpValue;
          } else {
            balance = trumpValue - BidenValue;
          }
          this.NationalMapBidenValue = BidenValue;
          this.NationalMaptrumpValue = trumpValue;

          // this.DonurtChart3Creation(BidenValue, trumpValue, balance)

          let voterTurnout: any = parseFloat(
            politicsObj?.voterTurnout?.replace('%', '')
          );
          voterTurnout = Number(voterTurnout.toFixed(2));
          let remaining: any = 100 - voterTurnout;
          // this.TotalPopulation = Number(
          //   (Number(politicsObj?.totalResidentPopulation) / 1000000).toFixed(2)
          // );

          // this.AdultPopulationCalc = Number(
          //   (Number(politicsObj?.adultPopulation) / 1000000).toFixed(2)
          // );

          // this.AdultPopulation = Number(this.TotalPopulation - this.AdultPopulationCalc).toFixed(2);

          this.PoliticsSenRepData = politicsObj;
          this.GovernerName = politicsObj?.governor_name;
          this.PartyName = politicsObj?.party_name;

        },
        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    );
  }

  LandScapTableApi(stateName: String) {
    this.subs.add(
      this.lniService.LanscapTableApi(stateName).subscribe({
        next: (response: any) => {
          this.CurrYrnewsPaperCount = 0;
          this.prevYrnewsPaperCount = 0;
          this.CurrYrdigitalCount = 0;
          this.prevYrdigitalCount = 0;
          this.CurrYrEthinicCount = 0;
          this.prevYrEthinicCount = 0;
          this.CurrYrPbCount = 0;
          this.prevYrPbCount = 0;
          this.CurrYrTotalOutlet = 0;
          this.prevYrTotalOutlet = 0;

          const jsonData: any = Object.entries(
            response.activePapersCompareResponseMap
          );
          jsonData.forEach((element: any, index: any) => {

            this.CurrYrnewsPaperCount =
              this.CurrYrnewsPaperCount +
              element[1].currentYearCountOfNewsPapers;
            this.prevYrnewsPaperCount =
              this.prevYrnewsPaperCount +
              element[1].compareYearCountOfNewsPapers;
            this.CurrYrdigitalCount =
              this.CurrYrdigitalCount +
              element[1].currentYearCountOfDigitalSites;
            this.prevYrdigitalCount =
              this.prevYrdigitalCount +
              element[1].compareYearCountOfDigitalSites;
            // this.CurrYrEthinicCount =
            //   this.CurrYrEthinicCount + element[1].currentYearCountOfEthnicity;
            this.prevYrEthinicCount =
              this.prevYrEthinicCount + element[1].compareYearCountOfEthnicity;
            this.CurrYrPbCount =
              this.CurrYrPbCount +
              element[1].currentYearCountOfPublicBroadCasting;
            this.prevYrPbCount =
              this.prevYrPbCount +
              element[1].compareYearCountOfPublicBroadCasting;

            this.prevYrTotalOutlet =
              this.prevYrTotalOutlet +
              element[1]
                .sumOfCompareNewsPaperDigitalSitesEthnicityPublicBroadcasting;
          });
          this.CurrYrTotalOutlet = this.CurrYrnewsPaperCount + this.CurrYrdigitalCount + this.CurrYrEthinicCount + this.CurrYrPbCount
        },
        error: (error: any) => {
          // console.log(error);
        },
        complete: () => { },
      })
    );
  }

  getDailyCountDifference(previousValue: any, currentValue: any) {
    if (Number(previousValue) === 0) {
      return 100;
    }
    if (Number(currentValue) === 0) {
      return -100;
    }
    let percentage =
      ((Number(currentValue) - Number(previousValue)) / Number(previousValue)) *
      100;
    percentage = Math.round(percentage);
    return percentage;
  }


  // DonurtChart3Creation(BidenValue: number, trumpValue: number, balance: number) {
  //   const canvas = document.getElementById('MyChart3') as HTMLCanvasElement;
  //   Chart.register(ChartDataLabels);
  //   if (typeof this.chart3 !== 'undefined') {
  //     this.chart3.destroy();
  //   }

  //   this.chart3 = new Chart(canvas, {
  //     type: 'doughnut',
  //     data: {
  //       labels: [
  //         'Biden',
  //         'Trump',
  //       ],
  //       datasets: [{
  //         label: '2022 Election Results',
  //         data: [63.5, 34.3, (63.5 - 34.3)],
  //         backgroundColor: [
  //           '#7fd6f9',
  //           '#ee5f65',
  //           '#E4E4E4',

  //         ],
  //       }]
  //     },
  //     options: {
  //       responsive: false,
  //       animation: {
  //         duration: 1000,
  //         easing: 'linear',
  //       },

  //       elements: {
  //         point: {
  //           radius: 0,
  //         },
  //       },
  //       plugins: {
  //         legend: {
  //           position: 'right',
  //           align: 'center',
  //           fullSize: true,
  //           display: true,
  //           labels: {
  //             usePointStyle: true,
  //             pointStyle: 'circle',
  //             boxWidth: 12,
  //             boxHeight: 12,
  //             padding: 32,
  //             color: '#222222',
  //             font: {
  //               size: 13,
  //               family: 'akkuratregular',
  //               weight: '600',
  //               lineHeight: '15px',
  //             },
  //           },
  //         },
  //         datalabels: {
  //           formatter: function (value: any, context: any) {
  //             return context.dataIndex == 2 ? '' : value + '%';
  //           },
  //           color: '#222222',
  //           font: {
  //             size: 13,
  //             family: 'akkuratregular',
  //             weight: 600,
  //             lineHeight: '15px',
  //           },
  //         }
  //       },
  //       events: []// Set this to false to disable tooltips

  //     },
  //   });

  //   this.chart3.update();
  // }
  StateVoterValue: number = 0;
  isLoading = true;

  DonurtChart4Creation(
    BidenValue: number,
    trumpValue: number,
    balance: number
  ) {
    // this.StateVoterValue = voterTurnout
    const canvas = document.getElementById('MyChart4') as HTMLCanvasElement;

    if (typeof this.chart4 !== 'undefined') {
      this.chart4.destroy();
    }

    this.chart4 = new Chart(canvas, {
      type: 'doughnut',
      data: {
        labels: [
          'Republicans',
          'Democrats',
          // `Biden ${BidenValue}%`,
          // `Trump ${trumpValue}%` ,
        ],
        datasets: [
          {
            label: '2022 Election Results',
            data: [BidenValue, trumpValue, balance],
            backgroundColor: ['#ee5f65', '#7fd6f9', '#E4E4E4'],
          },
        ],
      },
      options: {
        responsive: false,
        animation: {
          duration: 1000,
          easing: 'linear',
        },
        // layout: {
        //   padding: 1,
        // },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            position: 'right',
            align: 'center',
            fullSize: true,
            display: true,
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              boxWidth: 12,
              boxHeight: 12,
              padding: 32,
              color: '#222222',
              font: {
                size: 13,
                family: 'akkuratregular',
                weight: '600',
                lineHeight: '15px',
              },
            },
          },
          datalabels: {
            formatter: function (value: any, context: any) {
              return context.dataIndex == 2 ? '' : value + '%';
            },
            color: '#222222',
            font: {
              size: 13,
              family: 'akkuratregular',
              weight: 600,
              lineHeight: '15px',
            },
          },
        },
        events: [],
      },
    });

    this.chart4.update();
    setTimeout(() => {
      this.isLoading = false;
    }, 50);
  }

  //   DonurtChart4Creation(voterTurnout: number, remaining: number) {

  //   this.StateVoterValue = voterTurnout
  //   const canvas = document.getElementById('MyChart4') as HTMLCanvasElement;

  //   if (typeof this.chart4 !== 'undefined') {
  //     this.chart4.destroy();
  //   }

  //   this.chart4 = new Chart(canvas, {
  //     type: 'doughnut',
  //     data: {

  //       datasets: [{
  //         label: 'voterTurnout',
  //         data: [voterTurnout, remaining],
  //         backgroundColor: [
  //           '#F6C74B',
  //           '#E4E4E4'
  //         ],
  //       }]
  //     },
  //     options: {

  //       responsive: false,
  //       animation: {
  //         duration: 1000,
  //         easing: 'linear',
  //       },

  //       elements: {
  //         point: {
  //           radius: 0,
  //         },
  //       },
  //       plugins: {
  //         legend: {
  //           position: 'right',
  //           align: 'center',
  //           fullSize: true,
  //           display: true,
  //           labels: {
  //             usePointStyle: true,
  //             pointStyle: 'circle',
  //             boxWidth: 12,
  //             boxHeight: 12,
  //             padding: 32,
  //             color: '#222222',
  //             font: {
  //               size: 13,
  //               family: 'akkuratregular',
  //               weight: '600',
  //               lineHeight: '15px',
  //             },
  //           },
  //         },
  //         datalabels: {
  //           color: '#222222',
  //           formatter: function(value:any, context:any) {
  //             return '';
  //           }
  //         }
  //       },
  //     },
  //   });

  //   this.chart4.update();
  //   setTimeout(() => {

  //     this.isLoading = false
  //   }, 50);
  // }

  PoliticsSenRepData: any = {};

  // stackedChart1(data: any) {

  //   if (this.chart5) {
  //     this.chart5.destroy(); // Destroy the existing chart if it exists
  //   }
  //   const ctx = document.getElementById('MyStackChart1') as HTMLCanvasElement;
  //   this.chart5 = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: ['Senators', 'Representatives'],
  //       datasets: [
  //         {
  //           label: 'Democrats',
  //           data: [data.usSenateDemocrates, data.usRepresentativesDemocrates],
  //           backgroundColor: '#7fd6f9',
  //           borderColor: '#fff',
  //           borderWidth: 1,
  //           borderRadius: 10,
  //           barThickness: 58,
  //         },
  //         {
  //           label: 'Republicans',
  //           data: [data.usSenateRepublic, data.usRepresentativesRepublic],
  //           backgroundColor: '#ee5f65',
  //           borderColor: '#fff',
  //           borderWidth: 1,
  //           borderRadius: 10,
  //           barThickness: 58,
  //         }
  //       ]
  //     },
  //     options: {
  //       responsive: true,
  //       indexAxis: 'y',
  //       scales: {
  //         x: {
  //           stacked: true,
  //           grid: {
  //             display: false,

  //           },
  //           ticks: {
  //             display: false,
  //             color: '#222222',

  //             font: {
  //               size: 13,
  //               family: 'akkuratregular',
  //               weight: '600',
  //               lineHeight: '15px',
  //             }

  //           }
  //         },
  //         y: {
  //           stacked: true,
  //           grid: {
  //             display: false
  //           },
  //           ticks: {
  //             color: '#222222',

  //             font: {
  //               size: 13,
  //               family: 'akkuratregular',
  //               weight: '600',
  //               lineHeight: '15px',
  //             }
  //           }
  //         }
  //       },
  //       plugins: {
  //         legend: {
  //           position: 'bottom',
  //           align: 'center',
  //           fullSize: true,
  //           display: true,
  //           labels: {
  //             usePointStyle: true,
  //             pointStyle: 'circle',
  //             boxWidth: 12,
  //             boxHeight: 12,
  //             padding: 32,
  //             color: '#222222',
  //             font: {
  //               size: 13,
  //               family: 'akkuratregular',
  //               weight: '600',
  //               lineHeight: '15px',
  //             },
  //           },
  //         },
  //         datalabels: {
  //           color: '#222222',
  //           formatter: function (value: any, context: any) {
  //             return value > 0 ? value : '';
  //           },
  //           font: {
  //             size: 13,
  //             family: 'akkuratregular',
  //             weight: 600,
  //             lineHeight: '15px',
  //           },
  //         },

  //       },
  //       events: []

  //     },
  //   });

  //   this.chart5.update();
  // }

  // stackedChart2(data: any) {

  //   if (this.chart6) {
  //     this.chart6.destroy(); // Destroy the existing chart if it exists
  //   }
  //   const ctx = document.getElementById('MyStackChart2') as HTMLCanvasElement;
  //   this.chart6 = new Chart(ctx, {
  //     type: 'bar',
  //     data: {
  //       labels: ['Senators', 'Representatives'],
  //       datasets: [
  //         {
  //           label: 'Democrats',
  //           data: [data.stateSenatesDemocrates, data.stateRepresentativesDemocrates],
  //           backgroundColor: '#7fd6f9',
  //           borderColor: '#fff',
  //           borderWidth: 1,
  //           borderRadius: 10,
  //           barThickness: 58,

  //         },
  //         {
  //           label: 'Republicans',
  //           data: [data.stateSenatesRepublic, data.stateRepresentativesRepublic],
  //           backgroundColor: '#ee5f65',
  //           borderColor: '#fff',
  //           borderWidth: 1,
  //           borderRadius: 10,
  //           barThickness: 58,

  //         }
  //       ]
  //     },
  //     options: {
  //       responsive: true,
  //       indexAxis: 'y',
  //       scales: {
  //         x: {
  //           stacked: true,
  //           grid: {
  //             display: false
  //           },
  //           ticks: {
  //             display: false,
  //             color: '#222222',
  //             font: {
  //               size: 13,
  //               family: 'akkuratregular',
  //               weight: '600',
  //               lineHeight: '15px',
  //             }
  //           },
  //         },
  //         y: {
  //           stacked: true,
  //           grid: {
  //             display: false
  //           },
  //           ticks: {
  //             color: '#222222',

  //             font: {
  //               size: 13,
  //               family: 'akkuratregular',
  //               weight: '600',
  //               lineHeight: '15px',
  //             }
  //           }
  //         }
  //       },
  //       plugins: {
  //         legend: {
  //           position: 'bottom',
  //           align: 'center',
  //           fullSize: true,
  //           display: true,
  //           labels: {
  //             usePointStyle: true,
  //             pointStyle: 'circle',
  //             boxWidth: 12,
  //             boxHeight: 12,
  //             padding: 32,
  //             color: '#222222',
  //             font: {
  //               size: 13,
  //               family: 'akkuratregular',
  //               weight: '600',
  //               lineHeight: '15px',
  //             },
  //           },
  //         },

  //         datalabels: {
  //           color: '#222222',
  //           formatter: function (value: any, context: any) {
  //             return value > 0 ? value : '';
  //           },
  //           font: {
  //             size: 13,
  //             family: 'akkuratregular',
  //             weight: 600,
  //             lineHeight: '15px',
  //           },
  //         }

  //       },

  //       events: []
  //     },
  //   });

  //   this.chart6.update();
  // }

  ngOnDestroy() {
    this.subs.unsubscribe();
    d3.select('#tooltip').remove();
    d3.select('#dotstooltip').remove();
    d3.select('#map-container').remove();
  }

  InitialValue = 1

  private zoomLevel: number = 1





  zoomIN() {
    // You can adjust the zoom level based on your requirement
    const newZoomLevel = this.ZOOM.scaleBy(this.SVG.transition().duration(500), 1.2);
  }

  zoomOut() {
    // You can adjust the zoom level based on your requirement
    const newZoomLevel = this.ZOOM.scaleBy(this.SVG.transition().duration(500), 0.8);
  }

  ReStore() {
    // Reset the zoom to the initial state
    this.SVG.transition().duration(500).call(this.ZOOM.transform, d3.zoomIdentity);
  }
  // zoomIN() {
  //   // console.log('comming');

  //   this.zoomLevel *= 1.2;
  //   this.updateZoom();
  // }

  MapG: any = '';

  restoreTransform: any = d3.zoomIdentity;

  SVG: any;
  ZOOM: any;

  transformKValue = 1

  // updateZoom() {
  //   const newTransform = d3.zoomIdentity.scale(this.zoomLevel);
  //   this.transformKValue = newTransform.k;
  //   this.MapG.attr('transform', newTransform);
  //   this.restoreTransform = newTransform;
  //   this.updateCircleRadiusAndPosition(); // Update both radius and position
  // }


  // updateCircleRadiusAndPosition() {
  //   const circles = document.querySelectorAll('circle');
  //   circles.forEach((element: any) => {
  //     const originalRadius: any = parseFloat(element.getAttribute('OX'));
  //     // // console.log(originalRadius);

  //     if (originalRadius) {
  //       let radius = originalRadius / (this.zoomLevel + 0.8);
  //       if (radius < 5) {
  //         radius = 5

  //       } else {
  //         radius = radius
  //         // radius = radius -10

  //       }
  //       element.setAttribute('r', radius.toString());

  //     }
  //   });
  // }


  // zoomOut() {
  //   this.zoomLevel /= 1.2;
  //   this.updateZoom();
  // }


  // originalTransform: any

  // ReStore() {
  //   const newTransform1 = d3.zoomIdentity;
  //   this.SVG.transition().duration(1).call(this.ZOOM.transform, newTransform1);
  //   this.zoomLevel = 1;
  //   const newTransform = this.originalTransform;
  //   this.MapG.attr('transform', newTransform);
  //   this.TransFormedValue = newTransform;
  //   this.currentTransform = newTransform;
  //   this.zoomLevel = 1;

  //   this.updateCircleRadiusAndPosition();
  // }
}

