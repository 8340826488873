import { Component, HostListener } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {


  constructor(
    public router: Router,
  ){}




  myFunction() {
    var x: any = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  isMegaMenuVisible: boolean = false;

  // Function to show the mega menu
  showMegaMenu(): void {
 
    this.isMegaMenuVisible = true;
  }

  // Function to hide the mega menu
  hideMegaMenu(): void {
    setTimeout(() => {
      
      this.isMegaMenuVisible = false;
    }, 200);
  }


GotoHome(){
    window.open('https://localnewsinitiative.northwestern.edu/', '_blank');
    this.isMegaMenuVisible = false;
  }
  
  GotoLocalNewsProject() {
    this.isMegaMenuVisible = false;
    window.open('https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/2024/', '_blank');
}

  GotoHome1(){
    this.isMegaMenuVisible = false;
   this.myFunction()
    this.router.navigate(['/'])
  }
    Gotospot(){
    this.isMegaMenuVisible = false;
   this.myFunction()
    this.router.navigate(['/brightSpots'])
  }
  routingState(state:any){
    this.isMegaMenuVisible = false;
    setTimeout(() => {
      let stateCode = state.StateCode
      let navigationExtras: NavigationExtras = {
        queryParams: { 'state': state.StateAbbr, stateCode },
      };
      this.router.navigate(['state-localnewslandscape'], navigationExtras);
      var x: any = document.getElementById("myTopnav");
        x.className = "topnav";
    }, 50);
  
       setTimeout(() => {
        
        this.isMegaMenuVisible = false;
      }, 200);
  }


  StateNameList = [
    {
      "StateCode": '01',
      "StateName": "Alabama",
      "StateAbbr": "AL"
    },
    {
      "StateCode": '02',
      "StateName": "Alaska",
      "StateAbbr": "AK"
    },
    {
      "StateCode": '04',
      "StateName": "Arizona",
      "StateAbbr": "AZ"
    },
    {
      "StateCode": '05',
      "StateName": "Arkansas",
      "StateAbbr": "AR"
    },
    {
      "StateCode": '06',
      "StateName": "California",
      "StateAbbr": "CA"
    },
    {
      "StateCode": '08',
      "StateName": "Colorado",
      "StateAbbr": "CO"
    },
    {
      "StateCode": '09',
      "StateName": "Connecticut",
      "StateAbbr": "CT"
    },
    {
      "StateCode": '10',
      "StateName": "Delaware",
      "StateAbbr": "DE"
    },
    {
      "StateCode": 11,
      "StateName": "District of Columbia",
      "StateAbbr": "DC"
    },
    {
      "StateCode": 12,
      "StateName": "Florida",
      "StateAbbr": "FL"
    },
    {
      "StateCode": 13,
      "StateName": "Georgia",
      "StateAbbr": "GA"
    },
    {
      "StateCode": 15,
      "StateName": "Hawaii",
      "StateAbbr": "HI"
    },
    {
      "StateCode": 16,
      "StateName": "Idaho",
      "StateAbbr": "ID"
    },
    {
      "StateCode": 17,
      "StateName": "Illinois",
      "StateAbbr": "IL"
    },
    {
      "StateCode": 18,
      "StateName": "Indiana",
      "StateAbbr": "IN"
    },
    {
      "StateCode": 19,
      "StateName": "Iowa",
      "StateAbbr": "IA"
    },
    {
      "StateCode": 20,
      "StateName": "Kansas",
      "StateAbbr": "KS"
    },
    {
      "StateCode": 21,
      "StateName": "Kentucky",
      "StateAbbr": "KY"
    },
    {
      "StateCode": 22,
      "StateName": "Louisiana",
      "StateAbbr": "LA"
    },
    {
      "StateCode": 23,
      "StateName": "Maine",
      "StateAbbr": "ME"
    },
    {
      "StateCode": 24,
      "StateName": "Maryland",
      "StateAbbr": "MD"
    },
    {
      "StateCode": 25,
      "StateName": "Massachusetts",
      "StateAbbr": "MA"
    },
    {
      "StateCode": 26,
      "StateName": "Michigan",
      "StateAbbr": "MI"
    },
    {
      "StateCode": 27,
      "StateName": "Minnesota",
      "StateAbbr": "MN"
    },
    {
      "StateCode": 28,
      "StateName": "Mississippi",
      "StateAbbr": "MS"
    },
    {
      "StateCode": 29,
      "StateName": "Missouri",
      "StateAbbr": "MO"
    },
    {
      "StateCode": 30,
      "StateName": "Montana",
      "StateAbbr": "MT"
    },
    {
      "StateCode": 31,
      "StateName": "Nebraska",
      "StateAbbr": "NE"
    },
    {
      "StateCode": 32,
      "StateName": "Nevada",
      "StateAbbr": "NV"
    },
    {
      "StateCode": 33,
      "StateName": "New Hampshire",
      "StateAbbr": "NH"
    },
    {
      "StateCode": 34,
      "StateName": "New Jersey",
      "StateAbbr": "NJ"
    },
    {
      "StateCode": 35,
      "StateName": "New Mexico",
      "StateAbbr": "NM"
    },
    {
      "StateCode": 36,
      "StateName": "New York",
      "StateAbbr": "NY"
    },
    {
      "StateCode": 37,
      "StateName": "North Carolina",
      "StateAbbr": "NC"
    },
    {
      "StateCode": 38,
      "StateName": "North Dakota",
      "StateAbbr": "ND"
    },
    {
      "StateCode": 39,
      "StateName": "Ohio",
      "StateAbbr": "OH"
    },
    {
      "StateCode": 40,
      "StateName": "Oklahoma",
      "StateAbbr": "OK"
    },
    {
      "StateCode": 41,
      "StateName": "Oregon",
      "StateAbbr": "OR"
    },
    {
      "StateCode": 42,
      "StateName": "Pennsylvania",
      "StateAbbr": "PA"
    },
    {
      "StateCode": 44,
      "StateName": "Rhode Island",
      "StateAbbr": "RI"
    },
    {
      "StateCode": 45,
      "StateName": "South Carolina",
      "StateAbbr": "SC"
    },
    {
      "StateCode": 46,
      "StateName": "South Dakota",
      "StateAbbr": "SD"
    },
    {
      "StateCode": 47,
      "StateName": "Tennessee",
      "StateAbbr": "TN"
    },
    {
      "StateCode": 48,
      "StateName": "Texas",
      "StateAbbr": "TX"
    },
    {
      "StateCode": 49,
      "StateName": "Utah",
      "StateAbbr": "UT"
    },
    {
      "StateCode": 50,
      "StateName": "Vermont",
      "StateAbbr": "VT"
    },
    {
      "StateCode": 51,
      "StateName": "Virginia",
      "StateAbbr": "VA"
    },
    {
      "StateCode": 53,
      "StateName": "Washington",
      "StateAbbr": "WA"
    },
    {
      "StateCode": 54,
      "StateName": "West Virginia",
      "StateAbbr": "WV"
    },
    {
      "StateCode": 55,
      "StateName": "Wisconsin",
      "StateAbbr": "WI"
    },
    {
      "StateCode": 56,
      "StateName": "Wyoming",
      "StateAbbr": "WY"
    }
  ]

}
