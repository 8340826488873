<div class="loader-div" *ngIf="Loading">
    <div class="loader">
        <div class="one"></div>
        <div class="two"></div>
    </div>
</div>
<!-- *ngIf="Loading" -->
<section class="mainContent gNewContent stateouttab">
    <div class="locationInfo gContainerMain">
        <div class="stateName">{{SeletedState?.StateName}}</div>

        <div class="stateDescription">
            {{stateDescription}}
            <a
                href="https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/2024/report/#executive-summary"
                target="_blank">Click here to read the 2024 report.</a>
        </div>
    </div>
    <div class="InnerContent1 gContainerMain">

        <div class="TabSection">
            <mat-tab-group [selectedIndex]="TabIndex" (selectedTabChange)="tabChange($event)">
                <mat-tab label="Local News Landscape">
                    <div class="MapContainer">
                        <div class="row">
                            <div class="col-xl-8 col-sm-12 col-md-8">
                                <div class="newmapDescription">
                                    <div class="mapheader">Local News Landscape</div>
                                    <div class="mapDesc">
                                        <!-- <div *ngIf="RadioOptionValue !=='newsOutlet'"> -->
                                        {{mapDescriptionTop}}
                                        <!-- </div> -->
                                        <!-- {{localMapDescriptionTop}} -->
                                    </div>

                                </div>


                                <div *ngIf="RadioOptionValue !=='newsOutlet'">
                                    <div class="bubble-text">* Bubble size based on number of news outlets.</div>
                                    <div class="bubble-text">* Click on a county in the map for more information - A
                                        Table
                                        displays right-side of the map.</div>
                                </div>
                                <div *ngIf="RadioOptionValue =='perGap'">
                                    <div class="bubble-text">{{PerGapForMap}}</div>
                                </div>
                                <div class="HeatMapRange" *ngIf="RadioOptionValue === 'newsOutlet'">
                                    <span class="noNewsPaper">No news outlets</span>
                                    <span class="oneNewsPaper">1 news outlet</span>
                                    <span class="multipleNewsPaper">2 or more news outlets</span>
                                </div>
                                <div class="col-xl-12 col-sm-12 col-md-12 MapOverFlow" id="state-localnewslandscape">
                                    <!-- <main class="choropleth "> -->
                                    <!-- <div id="choropleth" class="d-flex justify-content-center"></div>
                                        <div id="description"></div>
                                    </main> -->

                                    <main class="choropleth ">
                                        <div id="choropleth" class="d-flex justify-content-center"></div>
                                        <div id="description"></div>
                                    </main>
                                    <div class="MapLengends">
                                        <span class="Maplegend1"></span>
                                        <span class="Maplegendlable1">Daily</span>

                                        <span class="Maplegend2"></span>
                                        <span class="Maplegendlable1">Weekly</span>
                                    </div>
                                </div>
                                <div class="d-flex state-end">
                                    <div class="zoom-func">
                                        <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-4 col-sm-12 col-md-4">
                                <div class="radioContainer">
                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                        class="example-radio-group" [(ngModel)]="RadioOptionValue"
                                        (change)="radioChange($event)">
                                        <ng-container *ngFor="let Option of RadioOptions">
                                            <mat-radio-button class="RadioButton" [value]="Option.value">
                                                {{ Option.Name }}
                                            </mat-radio-button>
                                        </ng-container>
                                    </mat-radio-group>
                                </div>

                                <div id="legend" class="colorRangeLegend"
                                    *ngIf="RadioOptionValue !== 'perGap' && RadioOptionValue !== 'newsOutlet'"></div>

                                <div class="HeatMapRange d-flex" *ngIf="RadioOptionValue ==='perGap'">
                                    <span class="DemocratsColor"></span>
                                    <span class="Maplegendlable1">Democrats</span>
                                    <span class="RepublicansColor"></span>
                                    <span class="Maplegendlable1">Republicans</span>
                                </div>


                                <div class="NewTable"
                                    *ngIf="FilteredLocalNewsLandScapTable?.length > 0 || AlreadyClicked">
                                    <div class="gInnerTable">
                                        <span>{{LocalClickedCounty}}, {{ StateShortForm}}</span>
                                        <!-- <mat-icon>remove_circle_outline</mat-icon> -->
                                    </div>
                                    <div class="totalTable">
                                        <div *ngIf="showNewsOutletsByCountyTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Newspapers</span>
                                                    <span class="count">{{ CountOfNewsPaper }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Standalone Digital Sites</span>
                                                    <span class="count">{{ CountOfDigitalSites }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Network Sites</span>
                                                    <span class="count">{{ CountOfNetworkSites }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Ethnic Outlets</span>
                                                    <span class="count">{{ CountOfEthnicOutlets }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Public Broadcasting</span>
                                                    <span class="count">{{ CountOfPublicBoardCasting }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showMedianIncomeTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Median Income</span>
                                                    <span class="count">{{ MedianIncomeForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPopulationDensityTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Population</span>
                                                    <span class="count">{{ PopulationForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Population Density (Per Sq. Mile)</span>
                                                    <span class="count">{{ PopulationDensityForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPercapTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Democrats</span>
                                                    <span class="count">{{ DemocratsForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Republicans</span>
                                                    <span class="count">{{ RepublicansForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="newspaper-container">
                                            <div class="item">
                                                <span class="label">Total Outlet Count</span>
                                                <span class="count">{{ LocalNewsLandScapeTotalOutlet }}</span>
                                            </div>
                                        </div>
                                        <!-- <span>Total Outlet Count: {{LocalNewsLandScapeTotalOutlet}}</span> -->
                                        <!-- <mat-icon>remove_circle_outline</mat-icon> -->
                                    </div>
                                    <table class="table mapTable">
                                        <thead class="gTableHeader">
                                            <tr>
                                                <th>Media Name</th>
                                                <th>Media Type</th>
                                                <!-- <th></th> -->
                                            </tr>
                                        </thead>
                                        <tbody class="gTableBody">
                                            <tr *ngFor="let data of FilteredLocalNewsLandScapTable">

                                                <td>{{data.mediaName}}</td>
                                                <td>{{data.mediaType}}</td>
                                                <!-- <td>{{data.Frequency === 'D' ? 'Daily' :'Weekly'}}</td> -->
                                            </tr>
                                            <tr *ngIf="FilteredLocalNewsLandScapTable.length === 0">
                                                <td [colSpan]="3" class="text-center">No data found.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <!-- <div class="HeatMapRange d-flex" *ngIf="RadioOptionValue !=='perGap'">
                                    <div class="d-flex RangeSlider" *ngIf="RadioOptionValue !== 'perGap'">
                                        <span class="range1">{{MinRangeValue}}</span>
                                       <div class="" id="colorRange"
                                        [ngClass]="{'colorRange': RadioOptionValue !== 'perGap', 'colorRange1': RadioOptionValue == 'perGap'}">
                                    </div>
                                        <span class="range2">{{MaxRangeValue}}</span>
                                    </div>
                                   
                                    <div class="" *ngIf="RadioOptionValue === 'perGap'">
                                        <span>{{MinRangeValue}}</span>
                                        <div class="" id="colorRange"
                                            [ngClass]="{'colorRange': RadioOptionValue !== 'perGap', 'colorRange1': RadioOptionValue == 'perGap'}">
                                        </div>
                                        <span>{{MaxRangeValue}}</span>
                                    </div>
    
                                </div> -->

                            </div>

                            <div class="col-12">
                                <div class="mapDescBottom">
                                    {{mapDescriptionBottom}}
                                </div>
                                <div *ngIf="RadioOptionValue ==='perGap'">
                                    <div class="dgcontentSourceName">Source: CNN (2024)</div>
                                </div>
                                <!-- <div class="dgcontentSourceName">Source: CNN (2024)</div> -->
                            </div>

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Newspapers">

                    <div class="MapContainer">
                        <div class="row ">
                            <div class="col-xl-8 col-sm-12 col-md-8">
                                <div class="newmapDescription">
                                    <div class="mapheader">Newspapers</div>
                                    <div class="mapDesc">
                                        {{mapDescriptionTop}}
                                    </div>
                                </div>
                                <div *ngIf="RadioOptionValue !=='newsOutlet'">
                                    <div class="bubble-text">* Bubble size based on number of news outlets.</div>
                                    <div class="bubble-text">* Click on a county in the map for more information - A
                                        Table
                                        displays right-side of the map.</div>
                                </div>
                                <div *ngIf="RadioOptionValue =='perGap'">
                                    <div class="bubble-text">{{PerGapForMap}}</div>
                                </div>
                                <div class="HeatMapRange" *ngIf="RadioOptionValue === 'newsOutlet'">
                                    <!-- <span class="noNewsPaper">0 Newspapers</span> -->
                                    <span class="oneNewsPaper">1 Newspaper</span>
                                    <span class="multipleNewsPaper">2 or more Newspapers</span>
                                </div>

                                <div class="col-xl-12 col-sm-12 col-md-12 newsPaperMap MapOverFlow"
                                    id="state-newspapers">
                                    <main class="choropleth ">
                                        <div id="choropleth" class="d-flex justify-content-center"></div>
                                        <div id="description"></div>
                                    </main>
                                    <div class="MapLengends">
                                        <span class="Maplegend1"></span>
                                        <span class="Maplegendlable1">Daily</span>



                                        <span class="Maplegend2"></span>
                                        <span class="Maplegendlable1">Weekly</span>


                                    </div>
                                </div>
                                <div class="d-flex state-end">
                                    <div class="zoom-func">
                                        <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-sm-12 col-md-4 ">


                                <div class="radioContainer">
                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                        class="example-radio-group" [(ngModel)]="RadioOptionValue"
                                        (change)="radioChange($event)">
                                        <ng-container *ngFor="let Option of RadioOptions">
                                            <!-- <mat-radio-button class="RadioButton" [value]="Option.value"
                                                *ngIf="Option.value !== 'newsOutlet'"> -->
                                            <mat-radio-button class="RadioButton" [value]="Option.value">
                                                {{ Option.Name }}
                                            </mat-radio-button>
                                        </ng-container>
                                    </mat-radio-group>
                                </div>



                                <!-- <div class="HeatMapRange d-flex" *ngIf="RadioOptionValue !=='perGap'">
                                    <div class="d-flex RangeSlider" *ngIf="RadioOptionValue !== 'perGap'">
                                        <span class="range1">{{MinRangeValue}}</span>
                                       <div class="" id="colorRange"
                                        [ngClass]="{'colorRange': RadioOptionValue !== 'perGap', 'colorRange1': RadioOptionValue == 'perGap'}">
                                    </div>
                                        <span class="range2">{{MaxRangeValue}}</span>
                                    </div>
                                    
                                    <div class="" *ngIf="RadioOptionValue === 'perGap'">
                                        <span>{{MinRangeValue}}</span>
                                        <div class="" id="colorRange"
                                            [ngClass]="{'colorRange': RadioOptionValue !== 'perGap', 'colorRange1': RadioOptionValue == 'perGap'}">
                                        </div>
                                        <span>{{MaxRangeValue}}</span>
                                    </div>
    
                                </div> -->

                                <div id="legend" class="colorRangeLegend"
                                    *ngIf="RadioOptionValue !== 'perGap' && RadioOptionValue !== 'newsOutlet'"></div>

                                <div class="HeatMapRange d-flex"
                                    *ngIf="RadioOptionValue ==='perGap' && RadioOptionValue !== 'newsOutlet'">
                                    <span class="DemocratsColor"></span>
                                    <span class="Maplegendlable1">Democrats</span>
                                    <span class="RepublicansColor"></span>
                                    <span class="Maplegendlable1">Republicans</span>

                                </div>


                                <div class="NewTable" *ngIf="FilterdNewsPaperTable?.length > 0 || AlreadyClicked">
                                    <div class="gInnerTable">
                                        <span>{{NewsClickedCounty}}, {{
                                            StateShortForm}}</span>
                                    </div>
                                    <div class="totalTable">
                                        <div *ngIf="showNewsOutletsByCountyTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Total Newspapers</span>
                                                    <span class="count">{{ CountOfNewsPaper }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showMedianIncomeTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Median Income</span>
                                                    <span class="count">{{ MedianIncomeForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPopulationDensityTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Population</span>
                                                    <span class="count">{{ PopulationForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Population Density (Per Sq. Mile)</span>
                                                    <span class="count">{{ PopulationDensityForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPercapTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Democrats</span>
                                                    <span class="count">{{ DemocratsForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Republicans</span>
                                                    <span class="count">{{ RepublicansForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="newspaper-container">
                                            <div class="item">
                                                <span class="label">Daily Newspaper Count</span>
                                                <span class="count">{{ NewsPapersDaily }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">Weekly Newspaper Count</span>
                                                <span class="count">{{ NewsPapersWeekly }}</span>
                                            </div>
                                        </div>
                                        <!-- <mat-icon>remove_circle_outline</mat-icon> -->
                                    </div>
                                    <table class="table mapTable">
                                        <thead class="gTableHeader">
                                            <tr>
                                                <!-- <th>County</th> -->
                                                <th>Newspapers</th>
                                                <th>Owner Name</th>
                                                <th>Frequency</th>


                                            </tr>
                                        </thead>
                                        <tbody class="gTableBody">
                                            <tr *ngFor="let data of FilterdNewsPaperTable">
                                                <!-- <td>{{data.county}}</td> -->
                                                <td>{{data.newspaperName}}</td>
                                                <td>{{data.ownerName}}</td>
                                                <td>{{data.Frequency === 'D' ? 'Daily' :'Weekly'}}</td>
                                                <!-- <td *ngIf="data.Frequency ==='D'">Daily</td>
                                                    <td *ngIf="data.Frequency ==='W'">Weekly</td>
                                                    <td *ngIf="data.Frequency ===''"></td> -->
                                            </tr>
                                            <tr *ngIf="FilterdNewsPaperTable.length === 0">
                                                <td [colSpan]="3" class="text-center">No data found.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="col-xl-12 col-sm-12 col-md-12">
                                <div class="mapDescBottom">
                                    {{mapDescriptionBottom}}
                                </div>
                            </div>
                            <div *ngIf="RadioOptionValue ==='perGap'">
                                <div class="dgcontentSourceName">Source: CNN (2024)</div>
                            </div>
                            <!-- <div class="dgcontentSourceName">Source: CNN (2024)</div> -->
                        </div>

                    </div>
                </mat-tab>
                <mat-tab label="Standalone Digital Sites">
                    <div class="MapContainer">
                        <div class="row ">
                            <div class="col-xl-8 col-sm-12 col-md-8">
                                <div class="newmapDescription">
                                    <div class="mapheader">Standalone Digital Sites</div>
                                    <div class="mapDesc">
                                        {{mapDescriptionTop}}
                                    </div>
                                </div>
                                <div *ngIf="RadioOptionValue !=='newsOutlet'">
                                    <div class="bubble-text">* Bubble size based on number of news outlets.</div>
                                    <div class="bubble-text">* Click on a county in the map for more information - A
                                        Table
                                        displays right-side of the map.</div>
                                </div>
                                <div *ngIf="RadioOptionValue =='perGap'">
                                    <div class="bubble-text">{{PerGapForMap}}</div>
                                </div>
                                <div class="HeatMapRange" *ngIf="RadioOptionValue === 'newsOutlet'">
                                    <!-- <span class="noNewsPaper">No news outlets</span> -->
                                    <span class="oneNewsPaper">1 Digital Site</span>
                                    <span class="multipleNewsPaper">2 or more Digital Sites</span>
                                </div>
                                <div class="col-xl-12 col-sm-12 col-md-12 MapOverFlow"
                                    id="state-standalonedigitalsites">
                                    <main class="choropleth ">
                                        <div id="choropleth" class="d-flex justify-content-center"></div>
                                        <div id="description"></div>
                                    </main>
                                    <div class="MapLengends">
                                        <span class="Maplegend1"></span>
                                        <span class="Maplegendlable1">For Profit</span>
                                        <span class="Maplegend2"></span>
                                        <span class="Maplegendlable1">Non Profit</span>
                                    </div>
                                </div>
                                <div class="d-flex state-end">
                                    <div class="zoom-func">
                                        <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-sm-12 col-md-4">
                                <div class="radioContainer">
                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                        class="example-radio-group" [(ngModel)]="RadioOptionValue"
                                        (change)="radioChange($event)">
                                        <ng-container *ngFor="let Option of RadioOptions">
                                            <mat-radio-button class="RadioButton" [value]="Option.value">
                                                <!-- *ngIf="Option.value !== 'newsOutlet'"> -->
                                                {{ Option.Name }}
                                            </mat-radio-button>
                                        </ng-container>
                                    </mat-radio-group>
                                </div>
                                <div id="legend" class="colorRangeLegend"
                                    *ngIf="RadioOptionValue !=='perGap' && RadioOptionValue !== 'newsOutlet'"></div>
                                <div class="HeatMapRange d-flex"
                                    *ngIf="RadioOptionValue ==='perGap' && RadioOptionValue !== 'newsOutlet'">
                                    <span class="DemocratsColor"></span>
                                    <span class="Maplegendlable1">Democrats</span>
                                    <span class="RepublicansColor"></span>
                                    <span class="Maplegendlable1">Republicans</span>
                                </div>
                                <div class="NewTable" *ngIf="FilteredDigitalSitesTable?.length > 0 || AlreadyClicked">
                                    <div class="gInnerTable">
                                        <span>{{DigitalClickedCounty}}, {{
                                            StateShortForm}}</span>
                                    </div>
                                    <div class="totalTable">
                                        <div *ngIf="showNewsOutletsByCountyTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Total Digital Sites</span>
                                                    <span class="count">{{ CountOfDigitalSites }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showMedianIncomeTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Median Income</span>
                                                    <span class="count">{{ MedianIncomeForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPopulationDensityTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Population</span>
                                                    <span class="count">{{ PopulationForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Population Density (Per Sq. Mile)</span>
                                                    <span class="count">{{ PopulationDensityForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPercapTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Democrats</span>
                                                    <span class="count">{{ DemocratsForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Republicans</span>
                                                    <span class="count">{{ RepublicansForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="newspaper-container">
                                            <div class="item">
                                                <span class="label">Local Profit</span>
                                                <span class="count">{{ DigitalSiteLocalProfit }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">Local Non-Profit</span>
                                                <span class="count">{{ DigitalSiteLocalNonProfit }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">State Profit</span>
                                                <span class="count">{{ DigitalSiteStateProfit }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">State Non-Profit</span>
                                                <span class="count">{{ DigitalSiteStateNonProfit }}</span>
                                            </div>
                                        </div>
                                        <!-- <mat-icon>remove_circle_outline</mat-icon> -->
                                    </div>
                                    <table class="table mapTable">
                                        <thead class="gTableHeader">
                                            <tr>
                                                <!-- <th>County</th> -->
                                                <th>Organization</th>
                                                <th>Website</th>

                                            </tr>
                                        </thead>
                                        <tbody class="gTableBody">
                                            <tr *ngFor="let data of FilteredDigitalSitesTable">
                                                <!-- <td>{{data.county}}</td> -->
                                                <td>{{data.organization}}</td>
                                                <td>
                                                    <a href="{{data.website}}">{{data.website}}</a>
                                                </td>
                                            </tr>
                                            <tr *ngIf="FilteredDigitalSitesTable.length === 0">
                                                <td [colSpan]="3" class="text-center">No data found.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xl-12 col-sm-12 col-md-12">
                                <div class="mapDescBottom">
                                    {{mapDescriptionBottom}}
                                </div>
                            </div>
                            <!-- <div class="dgcontentSourceName">Source: CNN (2024)</div> -->
                            <div *ngIf="RadioOptionValue ==='perGap'">
                                <div class="dgcontentSourceName">Source: CNN (2024)</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="Network Sites">
                    <div class="MapContainer">
                        <div class="row ">
                            <div class="col-xl-8 col-sm-12 col-md-8">
                                <div class="newmapDescription">
                                    <div class="mapheader">Network Sites</div>
                                    <div class="mapDesc">
                                        {{mapDescriptionTop}}
                                    </div>
                                </div>
                                <div *ngIf="RadioOptionValue !=='newsOutlet'">
                                    <div class="bubble-text">* Bubble size based on number of news outlets.</div>
                                    <div class="bubble-text">* Click on a county in the map for more information - A
                                        Table
                                        displays right-side of the map.</div>
                                </div>
                                <div *ngIf="RadioOptionValue =='perGap'">
                                    <div class="bubble-text">{{PerGapForMap}}</div>
                                </div>
                                <div class="HeatMapRange" *ngIf="RadioOptionValue === 'newsOutlet'">
                                    <!-- <span class="noNewsPaper">No news outlets</span>  -->
                                    <span class="oneNewsPaper">1 Digital Site</span>
                                    <span class="multipleNewsPaper">2 or more Digital Sites</span>
                                </div>
                                <div class="col-xl-12 col-sm-12 col-md-12 MapOverFlow" id="state-networkdigitalsites">
                                    <main class="choropleth ">
                                        <div id="choropleth" class="d-flex justify-content-center"></div>
                                        <div id="description"></div>
                                    </main>
                                    <!-- <div class="MapLengends">
                                        <span class="Maplegend1"></span>
                                        <span class="Maplegendlable1">For Profit</span>
                                        <span class="Maplegend2"></span>
                                        <span class="Maplegendlable1">Non Profit</span>
                                    </div> -->
                                </div>
                                <div class="d-flex state-end">
                                    <div class="zoom-func">
                                        <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-sm-12 col-md-4">
                                <div class="radioContainer">
                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                        class="example-radio-group" [(ngModel)]="RadioOptionValue"
                                        (change)="radioChange($event)">
                                        <ng-container *ngFor="let Option of RadioOptions">
                                            <mat-radio-button class="RadioButton" [value]="Option.value">
                                                <!-- *ngIf="Option.value !== 'newsOutlet'"> -->
                                                {{ Option.Name }}
                                            </mat-radio-button>
                                        </ng-container>
                                    </mat-radio-group>
                                </div>
                                <div id="legend" class="colorRangeLegend"
                                    *ngIf="RadioOptionValue !=='perGap' && RadioOptionValue !== 'newsOutlet'"></div>
                                <div class="HeatMapRange d-flex"
                                    *ngIf="RadioOptionValue ==='perGap' && RadioOptionValue !== 'newsOutlet'">
                                    <span class="DemocratsColor"></span>
                                    <span class="Maplegendlable1">Democrats</span>
                                    <span class="RepublicansColor"></span>
                                    <span class="Maplegendlable1">Republicans</span>
                                </div>
                                <div class="NewTable" *ngIf="FilteredNetworkSitesTable?.length > 0 || AlreadyClicked">
                                    <div class="gInnerTable">
                                        <span>{{NetworkClickedCounty}}, {{
                                            StateShortForm}}</span>
                                    </div>
                                    <div class="totalTable">
                                        <div *ngIf="showNewsOutletsByCountyTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Total Network Sites</span>
                                                    <span class="count">{{ CountOfNetworkSites }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div *ngIf="showMedianIncomeTab">Median Income Table</div> -->
                                        <!-- <div *ngIf="showPopulationDensityTab">Residents per Square Table</div> -->
                                        <!-- <div *ngIf="showPercapTab"> President Election Tabl</div> -->
                                        <!-- <mat-icon>remove_circle_outline</mat-icon> -->
                                    </div>
                                    <table class="table mapTable">
                                        <thead class="gTableHeader">
                                            <tr>
                                                <th>Network</th>
                                                <th>Link</th>
                                            </tr>
                                        </thead>
                                        <tbody class="gTableBody">
                                            <tr *ngFor="let data of FilteredNetworkSitesTable">
                                                <td>{{data.network}}</td>
                                                <td>
                                                    <a href="{{data.link}}">{{data.link}}</a>
                                                </td>
                                            </tr>
                                            <tr *ngIf="FilteredNetworkSitesTable.length === 0">
                                                <td [colSpan]="3" class="text-center">No data found.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xl-12 col-sm-12 col-md-12">
                                <div class="mapDescBottom">
                                    {{mapDescriptionBottom}}
                                </div>
                            </div>
                            <!-- <div class="dgcontentSourceName">Source: CNN (2024)</div> -->
                            <div *ngIf="RadioOptionValue ==='perGap'">
                                <div class="dgcontentSourceName">Source: CNN (2024)</div>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="Ethnic Outlets">

                    <div class="MapContainer">

                        <div class="row ">


                            <div class="col-xl-8 col-sm-12 col-md-8">
                                <div class="newmapDescription">
                                    <div class="mapheader">Ethnic Outlets</div>
                                    <div class="mapDesc">
                                        {{mapDescriptionTop}}
                                    </div>
                                </div>




                                <div *ngIf="RadioOptionValue !=='newsOutlet'">
                                    <div class="bubble-text">* Bubble size based on number of news outlets.</div>
                                    <div class="bubble-text">* Click on a county in the map for more information - A
                                        Table
                                        displays right-side of the map.</div>
                                </div>
                                <div *ngIf="RadioOptionValue =='perGap'">
                                    <div class="bubble-text">{{PerGapForMap}}</div>
                                </div>
                                <div class="HeatMapRange" *ngIf="RadioOptionValue === 'newsOutlet'">
                                    <!-- <span class="noNewsPaper">No news outlets</span> -->
                                    <span class="oneNewsPaper">1 Ethnic Outlet</span>
                                    <span class="multipleNewsPaper">2 or more Ethnic Outlets</span>
                                </div>

                                <div class="col-xl-12 col-sm-12 col-md-12 MapOverFlow" id="state-ethnicoutlets">

                                    <main class="choropleth ">
                                        <div id="choropleth" class="d-flex justify-content-center"></div>
                                        <div id="description"></div>
                                    </main>

                                    <div class="MapLengends">
                                        <span class="Maplegend1"></span>
                                        <span class="Maplegendlable1">Latino</span>

                                        <span class="Maplegend6"></span>
                                        <span class="Maplegendlable1">Asian American</span>

                                        <span class="Maplegend3"></span>
                                        <span class="Maplegendlable1">African American</span>

                                        <span class="Maplegend4"></span>
                                        <span class="Maplegendlable1">Native American</span>
                                        <span class="Maplegend5"></span>
                                        <span class="Maplegendlable1">Other</span>


                                    </div>
                                </div>
                                <div class="d-flex state-end">
                                    <div class="zoom-func">
                                        <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                                    </div>
                                </div>


                            </div>
                            <div class="col-xl-4 col-sm-12 col-md-4">

                                <div class="radioContainer">
                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                        class="example-radio-group" [(ngModel)]="RadioOptionValue"
                                        (change)="radioChange($event)">
                                        <ng-container *ngFor="let Option of RadioOptions">
                                            <mat-radio-button class="RadioButton" [value]="Option.value">
                                                <!-- *ngIf="Option.value !== 'newsOutlet'"> -->
                                                {{ Option.Name }}
                                            </mat-radio-button>
                                        </ng-container>
                                    </mat-radio-group>
                                </div>



                                <div id="legend" class="colorRangeLegend"
                                    *ngIf="RadioOptionValue !=='perGap' && RadioOptionValue !== 'newsOutlet'"></div>

                                <div class="HeatMapRange d-flex"
                                    *ngIf="RadioOptionValue ==='perGap' && RadioOptionValue !== 'newsOutlet'">
                                    <span class="DemocratsColor"></span>
                                    <span class="Maplegendlable1">Democrats</span>
                                    <span class="RepublicansColor"></span>
                                    <span class="Maplegendlable1">Republicans</span>
                                </div>

                                <div class="NewTable" *ngIf="FilteredEthnicVoiceTable?.length > 0 || AlreadyClicked">
                                    <div class="gInnerTable">
                                        <span>{{EthinicClickedCounty}}, {{
                                            StateShortForm}}</span>
                                    </div>
                                    <div class="totalTable">
                                        <div *ngIf="showNewsOutletsByCountyTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Total Ethnic Outlets</span>
                                                    <span class="count">{{ CountOfEthnicOutlets }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showMedianIncomeTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Median Income</span>
                                                    <span class="count">{{ MedianIncomeForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPopulationDensityTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Population</span>
                                                    <span class="count">{{ PopulationForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Population Density (Per Sq. Mile)</span>
                                                    <span class="count">{{ PopulationDensityForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPercapTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Democrats</span>
                                                    <span class="count">{{ DemocratsForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Republicans</span>
                                                    <span class="count">{{ RepublicansForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="newspaper-container">
                                            <div class="item">
                                                <span class="label">Latino Count</span>
                                                <span class="count">{{ EthnicLatinoCount }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">Asian American Count</span>
                                                <span class="count">{{ EthnicAsianAmericanCount }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">African American Count</span>
                                                <span class="count">{{ EthnicAfricanAmericanCount }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">Native American Count</span>
                                                <span class="count">{{ EthnicNativeAmericanCount }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">Other Count</span>
                                                <span class="count">{{ EthnicOthersCount }}</span>
                                            </div>
                                        </div>
                                        <!-- <mat-icon>remove_circle_outline</mat-icon> -->
                                    </div>
                                    <table class="table mapTable">
                                        <thead class="gTableHeader">
                                            <tr>
                                                <!-- <th>County</th> -->
                                                <th>OutletName</th>
                                                <th>Format</th>

                                            </tr>
                                        </thead>
                                        <tbody class="gTableBody">
                                            <tr *ngFor="let data of FilteredEthnicVoiceTable">
                                                <!-- <td>{{data.county}}</td> -->
                                                <td>{{data.outletName}}</td>
                                                <td>{{data.format}}</td>
                                            </tr>
                                            <tr *ngIf="FilteredEthnicVoiceTable.length === 0">
                                                <td [colSpan]="3" class="text-center">No data found.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="col-xl-12 col-sm-12 col-md-12">
                                <div class="mapDescBottom">
                                    {{mapDescriptionBottom}}
                                </div>
                            </div>
                            <!-- <div class="dgcontentSourceName">Source: CNN (2024)</div> -->
                            <div *ngIf="RadioOptionValue ==='perGap'">
                                <div class="dgcontentSourceName">Source: CNN (2024)</div>
                            </div>

                        </div>

                    </div>

                </mat-tab>
                <mat-tab label="Public Broadcasting">

                    <div class="MapContainer ">

                        <div class="row ">
                            <div class="col-xl-8 col-sm-12 col-md-8">
                                <div class="newmapDescription">
                                    <div class="mapheader"> Public Broadcasting</div>
                                    <div class="mapDesc">
                                        {{mapDescriptionTop}}
                                    </div>
                                </div>


                                <div *ngIf="RadioOptionValue !=='newsOutlet'">
                                    <div class="bubble-text">* Bubble size based on number of news outlets.</div>
                                    <div class="bubble-text">* Click on a county in the map for more information - A
                                        Table
                                        displays right-side of the map.</div>
                                </div>
                                <div *ngIf="RadioOptionValue =='perGap'">
                                    <div class="bubble-text">{{PerGapForMap}}</div>
                                </div>
                                <div class="HeatMapRange" *ngIf="RadioOptionValue === 'newsOutlet'">
                                    <!-- <span class="noNewsPaper">No news outlets</span> -->
                                    <span class="oneNewsPaper">1 Public Broadcasting Station</span>
                                    <span class="multipleNewsPaper">2 or more Public Broadcasting Stations</span>
                                </div>

                                <div class="col-xl-12 col-sm-12 col-md-12 MapOverFlow" id="state-publicbroadcasting">
                                    <main class="choropleth text-center">

                                        <div id="choropleth" class="d-flex justify-content-center"></div>
                                        <div id="description"></div>
                                    </main>




                                    <div class="MapLengends">
                                        <span class="Maplegend1"></span>
                                        <span class="Maplegendlable1">Pacifica</span>

                                        <span class="Maplegend6"></span>
                                        <span class="Maplegendlable1">Stations</span>

                                        <span class="Maplegend3"></span>
                                        <span class="Maplegendlable1">PBS</span>

                                        <span class="Maplegend4"></span>
                                        <span class="Maplegendlable1">APM</span>
                                        <span class="Maplegend5"></span>
                                        <span class="Maplegendlable1">NPR</span>


                                    </div>
                                </div>
                                <div class="d-flex state-end">
                                    <div class="zoom-func">
                                        <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                                        <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                                src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                                    </div>
                                </div>

                            </div>
                            <div class="col-xl-4 col-sm-12 col-md-4">
                                <div class="radioContainer">
                                    <mat-radio-group aria-labelledby="example-radio-group-label"
                                        class="example-radio-group" [(ngModel)]="RadioOptionValue"
                                        (change)="radioChange($event)">
                                        <ng-container *ngFor="let Option of RadioOptions">
                                            <mat-radio-button class="RadioButton" [value]="Option.value">
                                                <!-- *ngIf="Option.value !== 'newsOutlet'"> -->
                                                {{ Option.Name }}
                                            </mat-radio-button>
                                        </ng-container>
                                    </mat-radio-group>
                                </div>



                                <div id="legend" class="colorRangeLegend"
                                    *ngIf="RadioOptionValue !=='perGap' && RadioOptionValue !== 'newsOutlet'"></div>

                                <div class="HeatMapRange d-flex"
                                    *ngIf="RadioOptionValue ==='perGap' && RadioOptionValue !== 'newsOutlet'">
                                    <span class="DemocratsColor"></span>
                                    <span class="Maplegendlable1">Democrats</span>
                                    <span class="RepublicansColor"></span>
                                    <span class="Maplegendlable1">Republicans</span>
                                </div>

                                <div class="NewTable" *ngIf="FilteredPBStationTable?.length > 0 || AlreadyClicked">
                                    <div class="gInnerTable">
                                        <span>{{PBClickedCounty}}, {{
                                            StateShortForm}}</span>
                                    </div>
                                    <div class="totalTable">
                                        <div *ngIf="showNewsOutletsByCountyTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Total Public Broadcasting</span>
                                                    <span class="count">{{ CountOfPublicBoardCasting }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showMedianIncomeTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Median Income</span>
                                                    <span class="count">{{ MedianIncomeForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPopulationDensityTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Population</span>
                                                    <span class="count">{{ PopulationForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Population Density (Per Sq. Mile)</span>
                                                    <span class="count">{{ PopulationDensityForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showPercapTab">
                                            <div class="newspaper-container">
                                                <div class="item">
                                                    <span class="label">Democrats</span>
                                                    <span class="count">{{ DemocratsForTable }}</span>
                                                </div>
                                                <div class="item">
                                                    <span class="label">Republicans</span>
                                                    <span class="count">{{ RepublicansForTable }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="newspaper-container">
                                            <div class="item">
                                                <span class="label">Pacifica Count</span>
                                                <span class="count">{{ PBCPacificaCount }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">Stations Count</span>
                                                <span class="count">{{ PBCStationCount }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">PBS Count</span>
                                                <span class="count">{{ PBCPBSCount }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">APM Count</span>
                                                <span class="count">{{ PBCAPMCount }}</span>
                                            </div>
                                            <div class="item">
                                                <span class="label">NPR Count</span>
                                                <span class="count">{{ PBCNPRCount }}</span>
                                            </div>
                                        </div>
                                        <!-- <mat-icon>remove_circle_outline</mat-icon> -->
                                    </div>
                                    <table class="table mapTable">
                                        <thead class="gTableHeader">
                                            <tr>
                                                <!-- <th>County</th> -->
                                                <th>Station</th>
                                                <th>PBType</th>

                                            </tr>
                                        </thead>
                                        <tbody class="gTableBody">
                                            <tr *ngFor="let data of FilteredPBStationTable">
                                                <!-- <td>{{data.county}}</td> -->
                                                <td>{{data.station}}</td>
                                                <td>{{data.publicBroadcastingType}}</td>
                                            </tr>
                                            <tr *ngIf="FilteredPBStationTable.length === 0">
                                                <td [colSpan]="3" class="text-center">No data found.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="col-xl-12 col-sm-12 col-md-12">
                                <div class="mapDescBottom">
                                    {{mapDescriptionBottom}}
                                </div>
                            </div>
                            <div *ngIf="RadioOptionValue ==='perGap'">
                                <div class="dgcontentSourceName">Source: CNN (2024)</div>
                            </div>
                            <!-- <div class="dgcontentSourceName">Source: CNN (2024)</div> -->

                        </div>

                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

        <!-- <div class="RiskParameterSection">
                <div class="stateName">{{ParameterTabName}} Parameters</div>
    
                <div class="stateDescription">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur
                    adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit elitLorem ipsum dolor sit amet,
                    consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor
                    sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit elitLorem
                    ipsum dolor sit amet, consectetur adipiscing elit
                </div>
    
            </div> -->
    </div>

    <div class="stateChoose">
        <span class="StateSelectText">Explore any state:</span>
        <mat-select [formControl]="disableSelect" class="StateSelector">
            <mat-option value="option2" disabled>-- Select State --</mat-option>
            <mat-option [value]="state.StateAbbr" *ngFor="let state of StateNameList"
                (onSelectionChange)="stateChange($event,state)">{{state.StateName}}</mat-option>
        </mat-select>
    </div>

    <div class="InnerContent gContainerMain gTop">
        <div class="g-inner-container">
            <div class="tableTitle">Population </div>
            <div class="row mt-5">
                <div class="col-12 col-md-6 col-xl-4 ">
                    <div class="TinyCards">
                        <div class="d-flex align-items-center justify-content-start gap-2 tinyCAlignment">
                            <div class="userImgContainer">
                                <img src="/projects/state-of-local-news/explore/assets/Images/Users.svg" alt="">
                            </div>
                            <div class="tinyTitle">Total Population</div>
                        </div>
                        <div class="tinyCount tinyCntAlignment">{{TotalPopulation}} Million</div>
                        <div class="tinyTDescription">

                        </div>
                        <!-- <span class="SourceName">Source: Medill LNI database</span> -->

                    </div>
                </div>
                <div class="col-12 col-md-6 col-xl-4 ">
                    <div class="TinyCards">
                        <div class="d-flex align-items-center justify-content-start gap-2 tinyCAlignment">
                            <div class="userImgContainer">
                                <img src="/projects/state-of-local-news/explore/assets/Images/Users.svg" alt="">
                            </div>
                            <div class="tinyTitle">Population Rank</div>
                        </div>
                        <div class="tinyCount tinyCntAlignment">{{PopulationRank}} Out of 51</div>
                        <!-- <div class="tinyTitle">POPULATION RANK</div>
                        <div class="tinyCount">{{PopulationRank}}st Out of 50 </div> -->
                        <div class="tinyTDescription">
                            <!-- Out of 50 -->
                        </div>
                        <!-- <span class="SourceName">Source: Medill LNI database</span> -->
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-4">
                    <div class="TinyCards">
                        <div class="d-flex align-items-center justify-content-start gap-2 tinyCAlignment">
                            <div class="userImgContainer">
                                <img src="/projects/state-of-local-news/explore/assets/Images/Users.svg" alt="">
                            </div>
                            <div class="tinyTitle">Adult Population</div>
                        </div>
                        <div class="tinyCount tinyCntAlignment">{{AdultPopulation}} Million</div>
                        <!-- <div class="tinyTitle">ADULT POPULATION</div>
                        <div class="tinyCount">{{AdultPopulation}} </div> -->
                        <div class="tinyTDescription">
                            <!-- Million -->
                        </div>
                        <span class="SourceName">Source: Census Bureau, September 2024</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="InnerContent gTop2">
        <div class="tableSection position-relative dgContainer">
            <div class="dgInnerContainer">
                <div class="tableTitle">Demographics</div>
                <div class="row gy-3 mt-4">
                    <div class="col-12 col-md-6 col-xl-4">
                        <div class="dgCards">
                            <div class="d-flex align-items-center justify-content-start gap-2"
                                style="padding-top: 21px; padding-left: 20px;">
                                <div class="dgImgContainer">
                                    <img src="/projects/state-of-local-news/explore/assets/Images/dgUsers.svg" alt="">
                                </div>
                                <div class="tinyTitle demo-title">Median Age</div>
                            </div>
                            <div class="tinyCount dgCountPosition">{{DemoGraphicsAge}}</div>
                            <div class="dgTDescription" style="margin-top: 26px; margin-left: 74px;">

                            </div>
                        </div>

                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                        <div class="dgCards">
                            <div class="d-flex align-items-center justify-content-start gap-2"
                                style="padding-top: 21px; padding-left: 20px;">
                                <div class="dgImgContainer">
                                    <img src="/projects/state-of-local-news/explore/assets/Images/MedianIncome.svg" alt="">
                                </div>
                                <div class="tinyTitle demo-title">Median Household Income</div>
                            </div>
                            <div class="tinyCount dgCountPosition">{{DemoGraphicsmedianIncome | currency:'USD':'symbol'
                                : '1.0-0' }}</div>
                            <div class="dgTDescription" style="margin-top: 8px; margin-left: 74px;">
                                (compared with $74,632 for U.S.)
                            </div>
                        </div>

                    </div>
                    <div class="col-12 col-md-6 col-xl-4">
                        <div class="dgCards">
                            <div class="d-flex align-items-center justify-content-start gap-2"
                                style="padding-top: 21px; padding-left: 20px;">
                                <div class="dgImgContainer">
                                    <img src="/projects/state-of-local-news/explore/assets/Images/povertyRate.svg" alt="">
                                </div>
                                <div class="tinyTitle demo-title">Percentage Below Poverty Line</div>
                            </div>
                            <div class="tinyCount dgCountPosition">{{DemoGraphicsPovertyRate.toFixed(1)}}%</div>
                            <div class="dgTDescription" style="margin-top: 8px; margin-left: 74px;">
                                (compared with 11.8% in U.S.)
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <span class="dgSourceName">Source: Census Bureau and USDA, September 2024</span>
        </div>
    </div>




    <div class="InnerContent gContainerMain gTop2">
        <div class="ChartSection">
            <div class="chartList d-flex align-items-center flex-wrap g-3 position-relative">
                <div class="col-12 col-sm-6 col-md-12 col-xl-6 d-flex align-items-center justify-content-start">
                    <span class="chartCaption">Local News Landscape</span>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-xl-6 d-flex align-items-center justify-content-start p-3">
                    <div class="col-12 d-flex align-items-center justify-content-center gap-3 news-landscape">
                        <div class="TinyCards col-12 col-sm-12 col-md-6 col-xl-6">
                            <div class="d-flex align-items-center justify-content-start gap-2 tinyCAlignment">
                                
                                <div class="userImgContainer">
                                    <img src="/projects/state-of-local-news/explore/assets/Images/EnvelopeOpen.svg" alt="">
                                </div>
                                <div class="tinyTitle">Total News Outlets</div>
                            </div>
                            <div class="tinyCount tinyCntAlignment">{{CurrYrTotalOutlet}}</div>

                                
                            <div class="tinyTDescription">

                            </div>
                            <!-- <span class="SourceName">Source: Medill LNI database</span> -->
                        </div>
                        <div class="TinyCards col-12 col-sm-12 col-md-6 col-xl-6">
                            <div class="d-flex align-items-center justify-content-start gap-2 tinyCAlignment">
                                
                                <div class="userImgContainer">
                                    <img src="/projects/state-of-local-news/explore/assets/Images/newsPaper.svg" alt="">
                                </div>
                                <div class="tinyTitle">Newspapers</div>
                            </div>
                            <div class="tinyCount tinyCntAlignment">{{CurrYrnewsPaperCount}}</div>

                            <div class="tinyTDescription">

                            </div>
                            <!-- <span class="SourceName">Source: Medill LNI database</span> -->
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-xl-6 d-flex align-items-center justify-content-start p-3">
                    <div class="col-12 d-flex align-items-center justify-content-center gap-3 news-landscape">
                        <div class="TinyCards col-12 col-sm-12 col-md-6 col-xl-6">
                            <div class="d-flex align-items-center justify-content-start gap-2 tinyCAlignment">
                                
                                <div class="userImgContainer">
                                    <img src="/projects/state-of-local-news/explore/assets/Images/digitalSite.svg" alt="">
                                </div>
                                <div class="tinyTitle">Digital Sites</div>
                            </div>
                            <div class="tinyCount tinyCntAlignment">{{CurrYrdigitalCount}}</div>
                            <div class="tinyTDescription">

                            </div>
                            <!-- <span class="SourceName">Source: Medill LNI database</span> -->

                        </div>
                        <div class="TinyCards col-12 col-sm-12 col-md-6 col-xl-6">
                            <div class="d-flex align-items-center justify-content-start gap-2 tinyCAlignment">
                                <div class="userImgContainer">
                                    <img src="/projects/state-of-local-news/explore/assets/Images/network-sites.svg" alt="">
                                </div>
                                <div class="tinyTitle">Network Sites</div>
                            </div>
                            <div class="tinyCount tinyCntAlignment">{{NetworkTotal}}</div>
                            <div class="tinyTDescription">

                            </div>
                            <!-- <span class="SourceName">Source: Medill LNI database</span> -->

                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-xl-6 d-flex align-items-center justify-content-start p-3">
                    <div class="col-12 d-flex align-items-center justify-content-center gap-3 news-landscape">
                        <div class="TinyCards col-12 col-sm-12 col-md-6 col-xl-6">
                            <div class="d-flex align-items-center justify-content-start gap-2 tinyCAlignment">
                                <div class="userImgContainer">
                                    <img src="/projects/state-of-local-news/explore/assets/Images/ethinicVoice.svg" alt="">
                                </div>
                                <div class="tinyTitle">Ethnic Outlets</div>
                            </div>
                            <div class="tinyCount tinyCntAlignment">{{CurrYrEthinicCount}}</div>
                            <div class="tinyTDescription">

                            </div>
                            <!-- <span class="SourceName">Source: Medill LNI database</span> -->
                        </div>
                        <div class="TinyCards col-12 col-sm-12 col-md-6 col-xl-6">
                            <div class="d-flex align-items-center justify-content-start gap-2 tinyCAlignment">
                                <div class="userImgContainer">
                                    <img src="/projects/state-of-local-news/explore/assets/Images/publicBroadcasting.svg" alt="">
                                </div>
                                <div class="tinyTitle">Public Broadcasting</div>
                            </div>
                            <div class="tinyCount tinyCntAlignment">{{CurrYrPbCount}}</div>
                            <div class="tinyTDescription">

                            </div>
                        </div>
                    </div>
                </div>
                <span class="SourceName" style="position: absolute; right: 10px; bottom: -30px;">Source: Medill LNI
                    database, September 2024</span>
            </div>
        </div>

    </div>
    <div class="InnerContent gContainerDemograpics gTop2">
        <div class="tableSection4 row">
            <div class="col-12 col-md-12 col-xl-6">
                <div class="tableTitle gInnerBottom">Newspapers </div>
                <div class="newsPaperCard2">
                    <!--                    <div class="NewsCardHeader">
                    <span>REMIPSUM DOLOR SIT AMET</span>
                    <mat-icon>fullscreen_exit</mat-icon>
                </div>-->
                    <div class="overFlowTable">
                        <table class="table FirstTable">
                            <thead>
                                <tr>
                                    <th style="border-bottom: none;" class="tableColorText">Description</th>
                                    <th style="border-bottom: none;" class="tableColorText">2023</th>
                                    <th style="border-bottom: none;" class="tableColorText">2024</th>
                                    <th style="border-bottom: none;" class="tableColorText text-end">Change</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="tableColorText">Daily</td>
                                    <td class="tableNumbers">{{countOfDailyNewspaper2020}}</td>
                                    <td class="tableNumbers">{{countOfDailyNewspaper2022}}</td>
                                    <td class="tableLastCol text-end">

                                        <button class="FirstTableBtn increased"
                                            *ngIf="dailyCoundDifference >0">{{dailyCoundDifference}}%</button>
                                        <button class="FirstTableBtn decreased"
                                            *ngIf="dailyCoundDifference <0">{{dailyCoundDifference}}%</button>

                                        <button class="FirstTableBtn Nutral"
                                            *ngIf="dailyCoundDifference === 0">{{dailyCoundDifference}}%</button>


                                    </td>
                                </tr>
                                <tr>
                                    <td class="tableColorText">Weekly</td>
                                    <td class="tableNumbers">{{countOfWeeklyNewspaper2020}}</td>
                                    <td class="tableNumbers">{{countOfWeeklyNewspaper2022}}</td>
                                    <td class="tableLastCol text-end">
                                        <button class="FirstTableBtn increased"
                                            *ngIf="weeklyCoundDifference >0">{{weeklyCoundDifference}}%</button>
                                        <button class="FirstTableBtn decreased"
                                            *ngIf="weeklyCoundDifference <0">{{weeklyCoundDifference}}%</button>
                                        <button class="FirstTableBtn Nutral"
                                            *ngIf="weeklyCoundDifference === 0">{{weeklyCoundDifference}}%</button>
                                    </td>
                                </tr>
                                <tr class="firstTableFooter gTotalCaption">
                                    <td class="tableColorText">Total</td>
                                    <td class="tableNumbers">{{countOfDailyNewspaper2020 + countOfWeeklyNewspaper2020}}
                                    </td>
                                    <td class="tableNumbers">{{countOfDailyNewspaper2022 + countOfWeeklyNewspaper2022}}
                                    </td>
                                    <td class="tableLastCol text-end">

                                        <button class="FirstTableBtn increased"
                                            *ngIf="totalCountDifference >0">{{totalCountDifference}}%</button>
                                        <button class="FirstTableBtn decreased" *ngIf="totalCountDifference <0">

                                            {{totalCountDifference}}%</button>
                                        <button class="FirstTableBtn Nutral"
                                            *ngIf="totalCountDifference ==0">{{totalCountDifference}}%</button>


                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td class="tableColorText">Audited Circulation <br> <span
                                            class="gTblCaption">Daily</span></td>
                                    <td class="tableNumbers">{{Audited2020Circulation}}M</td>
                                    <td class="tableNumbers">{{Audited2022Circulation}}M</td>
                                    <td class="tableLastCol">
                                         <button  class="FirstTableBtn increased">{{30}}%</button> 
                                        <button class="FirstTableBtn decreased"
                                            *ngIf="AuditedCoundDifference <0">{{AuditedCoundDifference}}%</button>
                                        <button class="FirstTableBtn increased"
                                            *ngIf="AuditedCoundDifference >0">{{AuditedCoundDifference}}%</button>
                                        <button class="FirstTableBtn Nutral"
                                            *ngIf="AuditedCoundDifference ==0">{{AuditedCoundDifference}}%</button>
                                    </td>
                                </tr> -->
                                <!-- <tr class="firstTableFooter">
                                    <td class="tableColorText">Number of Employeed <br> <span class="gTblCaption">By
                                            Local Newspaper</span></td>
                                    <td class="tableNumbers">{{localEmployed2020}}</td>
                                    <td class="tableNumbers">{{localEmployed2022}}</td>
                                    <td class="tableLastCol">
                                        <button class="FirstTableBtn Nutral"
                                            *ngIf="differentlocalId ===0">{{differentlocalId}}%</button>
                                        <button class="FirstTableBtn increased"
                                            *ngIf="differentlocalId >0">{{differentlocalId}}%</button>
                                        <button class="FirstTableBtn decreased"
                                            *ngIf="differentlocalId <0">{{differentlocalId}}%</button>
                                        <button class="FirstTableBtn">{{getDailyCountDifference(localEmployed2020,localEmployed2022)}}%</button>
                                    </td>
                                </tr> -->

                            </tbody>
                        </table>
                    </div>
                    <div class="SourceName">Source: Medill LNI database, September 2024</div>
                </div>
            </div>

            <div class="col-12 col-md-12 col-xl-6 dital4">

                <div class="tableTitle gInnerBottom">Digital Sites </div>
                <div class="newsPaperCard2">
                    <!--                    <div class="digitalSitesCardHeader">
                    <span>REMIPSUM DOLOR SIT AMET</span>

                </div>-->
                    <div class="overFlowTable">
                        <table class="table">
                            <thead>
                                <tr>

                                    <th style="border-bottom: none;" class="tableColorText">Type</th>
                                    <th style="border-bottom: none;" class="tableColorText text-end">For-Profit</th>
                                    <th style="border-bottom: none;" class="tableColorText text-end">Nonprofit</th>
                                    <th style="border-bottom: none;" class="tableColorText text-end">Total</th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Local</td>
                                    <td class="text-end">{{DigitalLocalProfit}}</td>
                                    <td class="text-end">{{DigitalLocalNoneProfit}}</td>
                                    <td class="text-end">{{getLocalTotal()}}</td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td class="text-end">{{digitalStateProfit}}</td>
                                    <td class="text-end">{{digitalStateNonProfit}}</td>
                                    <td class="text-end">{{getStateTotal()}}</td>
                                </tr>
                                <tr class="firstTableFooter gTotalCaption">
                                    <td>Total</td>
                                    <td class="text-end">{{getProfitTotal()}}</td>
                                    <td class="text-end">{{getNonProfitTotal()}}</td>
                                    <td class="text-end">{{getAllTotal()}}</td>

                                </tr>
                        </table>
                    </div>
                    <div class="SourceName">Source: Medill LNI database, September 2024</div>
                </div>

            </div>



        </div>



        <div class="tableSection5">
            <div class="row">

                <div class="col-12 col-md-12 col-xl-6">
                    <div class="tableTitle  gInnerBottom">Ethnic Outlets</div>
                    <div class="newsPaperCard2 col-12 col-md-8">
                        <div class="overFlowTable ">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="border-bottom: none;" class="tableColorText">Community Served</th>
                                        <th style="border-bottom: none;" class="tableColorText text-end">Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Black</td>
                                        <td class="text-end">{{EthnicCountOfBlack}}</td>
                                    </tr>
                                    <tr>
                                        <td>Hispanic</td>
                                        <td class="text-end">{{EthnicCountOfHispanic}}</td>
                                    </tr>
                                    <tr>
                                        <td>Asian</td>
                                        <td class="text-end">{{EthnicCountOfAsian}}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td>American</td>
                                        <td class="text-end">{{EthnicCountOfAmerican}}</td>
                                    </tr> -->
                                    <tr>
                                        <td>Others</td>
                                        <td class="text-end">{{EthnicCountOfOther}}</td>
                                    </tr>
                                    <tr class="firstTableFooter gTotalCaption">
                                        <td>Total</td>
                                        <td class="text-end">{{EthnicTotal}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="SourceName">Source: Medill LNI database, September 2024</div>
                    </div>
                </div>

                <div class="col-12 col-md-12 col-xl-6 dital4">
                    <div class="tableTitle gInnerBottom">Public Broadcasting </div>
                    <div class="newsPaperCard2">
                        <!--                        <div class="digitalSitesCardHeader">
                        <span>REMIPSUM DOLOR SIT AMET</span>

                    </div>-->
                        <div class="overFlowTable">
                            <table class="table">
                                <thead>
                                    <tr>

                                        <th style="border-bottom: none;" class="tableColorText">Coverage</th>
                                        <!-- <th style="border-bottom: none;" class="tableColorText text-end">Local</th>
                                        <th style="border-bottom: none;" class="tableColorText text-end">Non-Local</th>-->
                                        <th style="border-bottom: none;" class="tableColorText text-end">Total</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Local</td>
                                        <!-- <td class="text-end">{{PBLocalCount}}</td>
                                        <td class="text-end">{{PBNonLocalCount}}</td> -->
                                        <td class="text-end">{{PBLocalCountTotal()}}</td>

                                    </tr>
                                    <tr>
                                        <td>State</td>
                                        <!-- <td class="text-end">{{PBStateLocalCount}}</td>
                                        <td class="text-end">{{PBStateNonLocalCount}}</td> -->
                                        <td class="text-end">{{PBStateCountTotal()}}</td>

                                    </tr>
                                    <tr class="firstTableFooter gTotalCaption">
                                        <td>Total</td>
                                        <!-- <td class="text-end">{{getPbLocalTotal()}}</td>
                                        <td class="text-end">{{getPBStateTotal()}}</td> -->
                                        <td class="text-end">{{getAllPBTotal()}}</td>

                                    </tr>
                            </table>
                        </div>
                        <div class="SourceName">Source: Medill LNI database, September 2024</div>
                    </div>
                </div>
                
                


            </div>
        </div>

        <div class="tableSection5">
            <div class="row">

                <div class="col-12 col-md-12 col-xl-6">
                    <div class="tableTitle  gInnerBottom">Network Sites</div>
                    <div class="newsPaperCard2 col-12 col-md-8">
                        <div class="overFlowTable ">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="border-bottom: none;" class="tableColorText">Network</th>
                                        <th style="border-bottom: none;" class="tableColorText text-end">Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Patch</td>
                                        <td class="text-end">{{NetworkCountOfPatch}}</td>
                                    </tr>
                                    <tr>
                                        <td>Others</td>
                                        <td class="text-end">{{NetworkCountOfOther}}</td>
                                    </tr>
                                    <tr class="firstTableFooter gTotalCaption">
                                        <td>Total</td>
                                        <td class="text-end">{{NetworkTotal}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="SourceName">Source: Medill LNI database, September 2024</div>
                    </div>
                </div>

            </div>
        </div>

        <div class="tableSection5 gInnerBottom2">
            <div class="row">
                <div class="col-12 col-md-12 col-xl-6 dital4">
                    <div class="tableTitle  gInnerBottom">National Politics</div>
                    <div class="nationPolitic">
                        <div class="overFlowTable">
                            <table class="table" style="border-bottom: 1px solid #cdcdcd !important;height:133px;">
                                <thead>
                                    <tr>
                                        <th style="border-bottom: none;" class="tableColorText">Presidential Candidates
                                        </th>
                                        <th style="border-bottom: none;" class="tableColorText text-center">Election
                                            Results</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border-bottom: none;">
                                            <div class="d-flex align-items-center gap-2">
                                                <div class="blueCircle"></div> Harris
                                            </div>
                                        </td>
                                        <td style="border-bottom: none;" class="text-center">{{NationalMapBidenValue}}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center gap-2">
                                                <div class="redCircle"></div> Trump
                                            </div>
                                        </td>
                                        <td class="text-center">{{NationalMaptrumpValue}}%</td>
                                    </tr>
                                    <!-- <tr>
                                    <td>Asian</td>
                                    <td class="text-end">{{EthnicCountOfAsian}}</td>
                                </tr>
                                <tr>
                                    <td>Others</td>
                                    <td class="text-end">{{EthnicCountOfOther}}</td>
                                </tr> -->
                                </tbody>
                            </table>
                            <table class="table" style="margin-top: 10px !important;">
                                <thead>
                                    <tr>
                                        <th style="border-bottom: none;" class="tableColorText">Senators/Representatives
                                        </th>
                                        <th style="border-bottom: none;" class="tableColorText text-center">
                                            <div class="d-flex align-items-center gap-2 text-politics">
                                                <div class="blueCircle"></div> Democrats
                                            </div>
                                        </th>
                                        <th style="border-bottom: none;" class="tableColorText text-center">
                                            <div class="d-flex align-items-center gap-2 text-politics">
                                                <div class="redCircle"></div> Republicans
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border-bottom: none;">Senators</td>
                                        <td style="border-bottom: none;" class="text-center">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="blueCicWithValue">
                                                    {{PoliticsSenRepData?.usSenateDemocrates}}
                                                </div>

                                            </div>
                                        </td>
                                        <td style="border-bottom: none;" class="text-center">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="redCicWithValue">
                                                    {{PoliticsSenRepData?.usSenateRepublic}}
                                                </div>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-bottom: none;">Representatives</td>
                                        <td style="border-bottom: none;" class="text-center">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="blueCicWithValue">
                                                    {{PoliticsSenRepData?.usRepresentativesDemocrates}}
                                                </div>
                                            </div>
                                        </td>
                                        <td style="border-bottom: none;" class="text-center">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="redCicWithValue">
                                                    {{PoliticsSenRepData?.usRepresentativesRepublic}}
                                                </div>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="SourceName">Source: Medill LNI database</div> -->
                    </div>
                </div>
                <div class="col-12 col-md-12 col-xl-6 dital4">
                    <div class="tableTitle  gInnerBottom">State Politics</div>
                    <div class="nationPolitic">
                        <div class="overFlowTable">
                            <table class="table" style="border-bottom: 1px solid #cdcdcd !important;height:133px;">
                                <thead>
                                    <tr>
                                        <th style="border-bottom: none;" class="tableColorText">Governor</th>
                                        <th style="border-bottom: none;" class="tableColorText text-start">Party</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border-bottom: none;">{{GovernerName}}</td>
                                        <td style="border-bottom: none;">
                                            <div class="d-flex align-items-center gap-2">
                                                <div class="blueCircle" *ngIf="PartyName ==='Democrats'"></div>
                                                <div class="redCircle" *ngIf="PartyName !=='Democrats'"></div>
                                                {{PartyName}}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="opacity-0">.</div>
                                        </td>
                                        <td>
                                            <div class="opacity-0">.</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table" style="margin-top: 10px !important;">
                                <thead>
                                    <tr>
                                        <th style="border-bottom: none;" class="tableColorText">Senators/Representatives
                                        </th>
                                        <th style="border-bottom: none;" class="tableColorText text-end">
                                            <div class="d-flex align-items-center gap-2 text-politics">
                                                <div class="blueCircle"></div> Democrats
                                            </div>
                                        </th>
                                        <th style="border-bottom: none;" class="tableColorText text-end">
                                            <div class="d-flex align-items-center gap-2 text-politics">
                                                <div class="redCircle"></div> Republicans
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border-bottom: none;">Senators</td>
                                        <td style="border-bottom: none;" class="text-center">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="blueCicWithValue">
                                                    {{PoliticsSenRepData?.stateSenatesDemocrates}}
                                                </div>
                                            </div>
                                        </td>
                                        <td style="border-bottom: none;" class="text-center">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="redCicWithValue">
                                                    {{PoliticsSenRepData?.stateSenatesRepublic}}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-bottom: none;">Representatives</td>
                                        <td style="border-bottom: none;" class="text-center">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="blueCicWithValue">
                                                    {{PoliticsSenRepData?.stateRepresentativesDemocrates}}
                                                </div>
                                            </div>
                                        </td>
                                        <td style="border-bottom: none;" class="text-center">
                                            <div class="d-flex align-items-center justify-content-center">
                                                <div class="redCicWithValue">
                                                    {{PoliticsSenRepData?.stateRepresentativesRepublic}}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="SourceName">Source: Medill LNI database</div> -->
                        <div class="SourceName">Source: Ballotpedia, February 2025</div>

                    </div>
                </div>
            </div>
        </div>
        <!-- Murugabc 
    <div class="tableSection ">
        <div class="tableTitle">National Politics </div>
        <div class="row">
            <div class="col-12 col-md-12 col-xl-6">
                <div class="NationalchartCard1">

                    <div class="tinyTitle">2020 Presidential Election Results</div>

                    <canvas id="MyChart3" width="250" height="250">{{ chart3 }}</canvas>
                    <div class="SourceName">Source: Ballotpedia</div>


                </div>
            </div>
            <div class="col-12 col-md-12 col-xl-6">
                <div class="NationalchartCard2">
                    <div class="tinyTitle">2022 US Senators/Representatives</div>

                    <canvas id="MyStackChart1" width="250" height="200">{{ chart5 }}</canvas>




                    <div class="SourceName">Source: Ballotpedia</div>

                </div>


            </div>


        </div>
    </div>


    <div class="tableSection tableSectionsTATE">
        <div class="tableTitle">State Politics </div>
        <div class="row">
            <div class="col-12 col-md-12 col-xl-6">
                <div class="NationalchartCard1">

                    <div class="tinyTitle">Most Recent Gubernatorial Results </div>
                    <canvas id="MyChart4" width="250" height="250">{{ chart4 }}</canvas>

                    <div class="SourceName">Source: Ballotpedia</div>


                </div>
            </div>
            <div class="col-12 col-md-12 col-xl-6">
                <div class="NationalchartCard2">
                    <div class="tinyTitle">2022 State Senators/Representatives</div>

                    <canvas id="MyStackChart2" width="250" height="200">{{ chart6 }}</canvas>
                    <div class="SourceName">Source: Ballotpedia</div>
                </div>

            </div>


        </div>
    </div> 
     Murua -->



    </div>
</section>


<app-footer from="StatePage"></app-footer>