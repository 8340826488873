
<div class="locationInfo">
  <div class="container ">
      <div class="grid">
          <div class="column-12 column-12-tablet column-12-phone column-12-large grid-item-bottom">


<h1 class="hed stateName">Bright Spots in the Local News Landscape</h1>
<div class="stateDescription">
Highlighting local news startups and some of the business models most successfully navigating 21st-century challenges.
</div>
                                                                                                
<p class="byline">
By
<span class="author name">
          Autumn  Brewington
      </span>
</p>

</div>
      </div>
</div>   
</div>

<section class="mainContent">
<div class="container">
<div class="grid">
<div class="column-10 column-10-tablet column-12-phone column-10-large grid-item-bottom">

  <p class="dek-notice">*Press “play” to see local news startups as they occurred over the last five years. Click on a star to read about outlets with promising business models for navigating 21st century challenges. Use the News deserts map button to activate a layer showing news deserts by county across the country. You can also zoom in for more detail.
  </p>

<iframe
id="inlineFrameExample"
title="Inline Frame Example"
width="100%"
src="https://app.powerbi.com/view?r=eyJrIjoiNTVkNzBhN2UtZmI1NC00YjM1LTg3ZjEtMDVlMjI4MDM3NmIyIiwidCI6Ijk0MjYwZjAzLTA3OTMtNDg0YS05MWNmLWJlYmU1ODQzMTliYyIsImMiOjEwfQ%3D%3D">

</iframe>

</div>   
</div>
</div>
<div class="container">
<div class="grid">
  <div class="column-10">
   
      <article class="content">
<p><a href="https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/2024/brightspots/">To read the full interviews with this year's Brightspots finalists, click here.</a></p>
<p>We'd like to tell you about a dozen reasons to be optimistic about the local media landscape.</p>
<p>Yes, there is grim in this year's State of Local News Report: More than two newspapers a week are closing, and print frequency is shrinking. Some 7,000 newspaper jobs were eliminated in the past year, almost 2,000 of them in newsroom positions.</p>
<p>But not all of the news about the news is negative.</p>
<p>This year the State of Local News Project found a net increase of more than 80 stand-alone digital sites. That includes 30 newspapers moving online after ending their weekly print editions. Our database has expanded to include more than 700 network news sites. Networks such as Patch, Axios Local, States Newsroom and TAPinto have grown rapidly over the past five years and provide local news to millions of readers, mostly in urban and suburban areas.</p>
<p class="space-para">And then there are the Bright Spots.</p>
<p>Last year&rsquo;s State of Local News Report debuted our <a href="https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/explore/#/brightSpots">Bright Spots feature</a>, highlighting 17 outlets that provide original accountability to their communities while creatively working toward sustainable business models. Some of those we spotlighted have continued their positive trends, such as the Minnesota <a href="https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/2023/brightspots/star-tribune-mike-klingensmith-q-and-a/">Star Tribune</a>, which is <a href="https://www.nytimes.com/2024/08/18/business/media/star-tribune-minnesota.html">expanding its statewide coverage</a> and this summer even swapped Minneapolis for Minnesota in its name to reflect its broadened focus. Another featured 2023 publication, the <a href="https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/2023/brightspots/post-and-courier-video/">Post &amp; Courier</a> in South Carolina, was among the six outlets that received inaugural <a href="https://knightfoundation.org/articles/the-knight-growth-challenge-fund-a-new-initiative-to-bolster-local-news/">Knight Growth Challenge Fund</a> awards this summer. The two-year investment, which seeks to expand access to local journalism, is expected to support the Post &amp; Courier&rsquo;s digital growth and community engagement.</p>
<p>This year we profile 12 local outlets around the country that are growing amid industry-wide economic challenges and changing media-consumption habits. These outlets reflect a range of operating structures &ndash; for-profit and nonprofit; legacy print and 21<sup>st</sup>-century digital &ndash; and serve populations in urban, suburban and rural areas. Some have a regional or statewide focus; others concentrate on their immediate and neighboring communities. Some have paywalls, some offer their posted content and newsletters for free, and some take a hybrid approach.</p>
<p>Key features of all are described in pop-up profiles and links to Q&amp;As with executives from these Bright Spot outlets. We explored issues unique to each publication as well as common concerns and lessons learned about audience engagement, business operations, reporting coverage and more. These pieces offer insights that we hope will be helpful to journalists as well as civic leaders hoping to revive or sustain original reporting in their communities.</p>
<p>This year&rsquo;s crop of Bright Spots had a lot of input from outside Medill. We crowdsourced requests for nominations and received more than six dozen suggestions, many of them digital outlets that have launched over the past few years, including the <a href="https://www.thebaltimorebanner.com/">Baltimore Banner</a>, <a href="https://canopyatlanta.org/">Canopy Atlanta</a> and <a href="https://www.mississippifreepress.org/">Mississippi Free Press</a>. From there, Medill narrowed the list to a dozen, taking into account factors such as innovation, years of operation, and geographic and platform diversity. While we focused on publications that are in their fifth year of operation or older, we look forward to including newer startups in future reports. And we hope readers will continue <a href="https://localnewsinitiative.northwestern.edu/posts/2024/05/06/nominate-bright-spots-2024/index.html">suggesting</a> news outlets for consideration as Bright Spots.</p>
<p><strong>The map also highlights 258 news startups that are less than five years old.</strong></p>
<p>Among the common traits and themes we noticed from this year&rsquo;s local Bright Spots:</p>
<ul>
<li>All 12, whether for-profit or nonprofit, are locally owned or controlled. None of the for-profits are publicly traded businesses or owned by large investment funds or hedge funds, so they are not subject to sizeable shareholder pressures or profit expectations.</li>
<li>As with last year&rsquo;s cohort, leaders of both for-profit and nonprofit outlets emphasize the importance of listening to community members and providing excellent customer service for advertisers, donors and news consumers. Before launching their newsrooms or specific coverage initiatives, several publications conducted months of in-person and online surveys to become familiar with regional information gaps and media consumption habits. Many leaders also stress the benefits of partnerships and collaborations with other media outlets and community groups to expand coverage reach and to strengthen engagement.</li>
<li>Philanthropic support for newsroom operations or positions is increasingly sought by not only nonprofits but also for-profit outlets. At Georges Media, the Louisiana parent company of the New Orleans Times-Picayune, the Advocate newspapers and <a href="http://nola.com/">Nola.com</a>, 22 reporting positions are funded by grants and other philanthropy.</li>
<li>While reader support and membership models became more common with the outbreak of COVID-19 &ndash; and the pandemic&rsquo;s walloping of advertising revenue &ndash; the reluctance of tens of millions of Americans to pay for journalism, particularly online, remains a challenge.</li>
</ul>
<p>&ldquo;There are still many people who think news should be free forever and ever, amen,&rdquo; says Ashley Trice, co-founder and co-publisher of <a href="https://www.lagniappemobile.com/">Lagniappe</a>, a 22-year-old for-profit print and digital outlet based in Mobile, Ala. &ldquo;I hope that attitude will continue to change and that people will just start thinking about their news &ndash; especially their local news &ndash; subscription like a utility bill. If you want it, you are going to have to pay for it.&rdquo;</p>
<p>That sentiment is shared at nonprofits. &ldquo;It used to be profitable to do this work. It&rsquo;s no longer profitable in the same ways,&rdquo; says Tasneem Raja, a co-founder of the Cityside nonprofit in California&rsquo;s Bay Area, another <a href="https://knightfoundation.org/articles/the-knight-growth-challenge-fund-a-new-initiative-to-bolster-local-news/">Knight Growth Challenge Fund award recipient</a>. &ldquo;That doesn&rsquo;t mean that this public good, this public service, is no longer needed. It&rsquo;s needed more than ever.&rdquo;</p>
<p>Succession planning was another concern cited at multiple Bright Spots, as was the importance of strategizing to be proactive even as media consumption habits shift.</p>
<p class="challenges">&ldquo;The way that we are structured today, the environment in which we operate today very well might not be the environment we operate in tomorrow, and we need to be prepared for that,&rdquo; says Jennie Liska, co-executive director of the Pittsburgh-based nonprofit <a href="https://www.publicsource.org/">PublicSource</a>. &ldquo;We need to accept that we are in an ever-changing environment and be changing with it, not reacting to changes.&rdquo;</p>
<p>Although many nonprofits provide their content for free &ndash; while encouraging readers and listeners who value their work to support them as members or donors &ndash; the <a href="https://www.sltrib.com/">Salt Lake Tribune</a>, the first legacy U.S. newspaper to transition to a 501(c)(3) nonprofit, continues to have a subscription wall. (The Tribune&rsquo;s first-ever <a href="https://www.sltrib.com/footer/2024/07/25/tribune-2024-annual-report/">annual report</a>, published in the summer, calls the paywall a &ldquo;<a href="https://www.niemanlab.org/2024/09/the-salt-lake-tribune-profitable-and-growing-seeks-to-rid-itself-of-that-necessary-evil-the-paywall/">necessary evil</a>&rdquo; that the outlet hopes to do away with &ldquo;at some point in the years to come.&rdquo;) Another outlet, <a href="https://boisedev.com/">Boise Dev</a> in Idaho, has reimagined the paywall concept as a time wall, with paying subscribers receiving some news via e-mail before the information publishes to its website.</p>
<p>Several Bright Spots were early digital adopters or formed as nonprofits several years ago -- Bridge Michigan is in its 13<sup>th</sup> year, and Charlottesville Tomorrow is about to turn 20 -- while one, Conecta Arizona, launched in 2020 on WhatsApp. They are weathering evolutions in news consumption; over the past year, nationwide print and digital circulation have dropped by more than 2 million readers. All of this year&rsquo;s Bright Spots stress the importance of accountability reporting and original content that is useful to community members. Many also cited the importance of meeting audiences where they are, whether through in-person events or on a range of platforms.</p>
<p>As Cityside&rsquo;s Raja noted, newsrooms today are &ldquo;benefiting from the work of countless people who&rsquo;ve been beating that drum over the last, certainly, 10,15, 20 years in saying, &lsquo;Take community engagement seriously. Hire people who do that work. Empower them. They are journalists; treat them as such.&rsquo; &rdquo;</p>
      </article>
  </div>
</div>
</div>



</section>


<app-footer from="BrightSpot"></app-footer>
