import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LNIService {

  constructor(private http: HttpClient,) { }

  BaseURL = 'https://www.northwesternlni.com:9090/api/';
  ContentURL = 'https://www.northwesternlni.com:8068/lni/';
  NetworkURL = 'https://www.northwesternlni.com:8068/api/';
  
  // BaseURL = 'http://50.19.62.115:9091/api/'
  // ContentURL = 'http://50.19.62.115:8067/lni/';
  // NetworkURL = 'http://50.19.62.115:8067/api/';

  // BaseURL = 'http://localhost:9090/api/';
  // ContentURL = 'http://localhost:8067/lni/';
  // NetworkURL = 'http://localhost:8067/api/';

  getHomePageData(){
    const APIURL = `${this.ContentURL}consolidation?year=2025`;
    // const APIURL = `http://localhost:8067/lni/consolidation?year=2025`;
    return this.http.get<any>(APIURL)
  }


  getEthinicCount(state:any){
    const APIURL = `${this.BaseURL}/lni/count?currentYear=2025&compareYear=2023&level=state&value=${state}`
    return this.http.get<any>(APIURL)
    
  }

  getNationalInformation = (location: any, Test: any): Observable<any> => { //Dots Api call for Public Prodcast in stateMap Section
    const APIURL = `${this.ContentURL}nationalFrontPage?location=${location}&textType=${Test}`
    return this.http.get<any>(APIURL)
  }

  //  Apis for home page  - Start
  getWatchListData = (): Observable<any> => {
    const APIURL = `${this.BaseURL}watchlistcounties?year=2025`
    return this.http.get<any>(APIURL)
  }


  getMetroListData = (): Observable<any> => {
    const APIURL = `${this.BaseURL}metroareas?year=2025`
    return this.http.get<any>(APIURL)
  }


  getEcometerData = (): Observable<any> => {
    const APIURL = `${this.BaseURL}localnewsecosystembaro?year=2025`
    return this.http.get<any>(APIURL)
  }

  //  Apis for State Map  - Start
  getHeadMapDataForStateMAp = (state: any): Observable<any> => {
    const APIURL = `${this.BaseURL}consolidation?year=2025&state=${state}`
    return this.http.get<any>(APIURL)
  }

  consolidation2022Api = (state: any): Observable<any> => {
    const APIURL = `${this.BaseURL}consolidation?year=2023&state=${state}`
    return this.http.get<any>(APIURL)
  }

  getstateMapData = (state: any): Observable<any> => {
    const APIURL = `${this.BaseURL}demographics?state=${state}`
    return this.http.get<any>(APIURL)
  }

  newsPaperApiCallForDots = (state: any, year: any): Observable<any> => { //Dots Api call for News paper in stateMap Section
    const APIURL = `${this.BaseURL}active-papers?year=${year}&state=${state}`
    return this.http.get<any>(APIURL)
  }

  localNewsLandScapeApiCallForTable = (state: any, fips: any ,year: any): Observable<any> => { //Dots Api call for News paper in stateMap Section
    const APIURL = `${this.ContentURL}localnewstable?state=${state}&fips=${fips}&year=${year}`
    return this.http.get<any>(APIURL)
  }

  DigitalSiteApiCallForDots = (state: any, year: any): Observable<any> => {
    const APIURL = `${this.BaseURL}digital-sites?state=${state}&year=${year}`
    return this.http.get<any>(APIURL)
  }

  NetworkSiteApiCallForDOts = (state: any): Observable<any> => {
    const APIURL = `${this.NetworkURL}network-sites?state=${state}`
    return this.http.get<any>(APIURL)
  }

  EthinicVoiceApiCallForDots = (state: any, year: any): Observable<any> => { //Dots Api call for Ethinic Voice in stateMap Section
    const APIURL = `${this.BaseURL}ethnicity?state=${state}&year=${year}`
    return this.http.get<any>(APIURL)
  }

  publicProdcastApiCallForDots = (state: any, year: any): Observable<any> => { //Dots Api call for Public Prodcast in stateMap Section
    const APIURL = `${this.BaseURL}public-broadcasting?state=${state}&year=${year}`
    return this.http.get<any>(APIURL)
  }


  CardsTableApi = (year: any, state: any): Observable<any> => { //Dots Api call for Public Prodcast in stateMap Section
    const APIURL = `${this.BaseURL}politics?year=${year}&state=${state}`
    return this.http.get<any>(APIURL)
  }

  CensusTableApi = (year: any, state: any): Observable<any> => { //Dots Api call for Public Prodcast in stateMap Section
    const APIURL = `${this.ContentURL}census?year=${year}&state=${state}`
    return this.http.get<any>(APIURL)
  }

  LanscapTableApi = (state: any): Observable<any> => { //Dots Api call for Public Prodcast in stateMap Section
    const APIURL = `${this.BaseURL}lni/state/county?currentYear=2024&compareYear=2023&state=${state}`
    return this.http.get<any>(APIURL)
  }

  getStateInformation = (state: any): Observable<any> => { //Dots Api call for Public Prodcast in stateMap Section
    const APIURL = `${this.ContentURL}stateTopBanner?state=${state}`
    return this.http.get<any>(APIURL)
  }

  getMapDescription = (TabName: any, state: any): Observable<any> => { //Dots Api call for Public Prodcast in stateMap Section
    let APIURL: string = '';
    if (TabName == 'Newspapers') {
      APIURL = `${this.ContentURL}stateNewspapers?state=${state}`
    } else if (TabName == 'Digital Sites') {
      APIURL = `${this.ContentURL}stateDigitalSites?state=${state}`
    } else if (TabName == 'Ethnic Voices') {
      APIURL = `${this.ContentURL}stateEthnicMedia?state=${state}`
    } else if (TabName == 'Public Broadcasting') {
      APIURL = `${this.ContentURL}statePublicBroadcasting?state=${state}`
    } else if (TabName == 'Local News Landscape') {
      APIURL = `${this.ContentURL}stateEcoSystem?state=${state}`
    }else if(TabName == 'Network Sites'){
      APIURL = `${this.ContentURL}stateNetwork?state=${state}`
    }
    return this.http.get<any>(APIURL)
  }

  // getlocalNewsLandScapeMapDescription = (TabName: any): Observable<any> => { //Dots Api call for Public Prodcast in stateMap Section
  //   const APIURL = 'https://localhost:8068/lni/stateTopBanner?state=CA'
  //   return this.http.get<any>(APIURL)
  // }
}
