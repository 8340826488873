import { Component, OnInit } from '@angular/core';
import * as d3 from "d3";
import * as topojson from 'topojson-client';
import { HttpClient } from '@angular/common/http';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';
import { LNIService } from 'app/Service/lni.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { Chart, registerables } from 'chart.js';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  providers: [CurrencyPipe, DecimalPipe],
})


export class TestComponent implements OnInit {

  disableSelect = new FormControl('option2');
  faCoffee: any = faCoffee;

  private subs = new SubSink();

  StateDataArr: any = [];


  RadioOptionValue: any = 'Local News Landscape';


  RadioOptions: any[] = [
    {
      Name: 'Local News Landscape',
      value: 'Local News Landscape',
    },
    {
      Name: 'Watchlist Counties',
      value: 'Watchlist Counties',
    },
    {
      Name: 'Metro Areas',
      value: 'Metro Areas',
    },
    {
      Name: 'Local News Ecosystem Barometer',
      value: 'Local News Ecosystem Barometer',
    },
  ];

  TabIndex = 1
  // MapGeoJsonData: any = [];
  width: number = 0;
  height: number = 0;
  Zoom: string = '';
  Top: string = '';
  left: string = '';

  Loading = false
  TabName: any = 'Local News Landscape';

  ParameterName: any = 'Local News Landscape';

  chart1Completed = false
  chart2Completed = false
  chart3Completed = false
  chart4Completed = false
  chart5Completed = false
  geoData: any = [];
  StateBoundariesGeoJsonData: any = [];

  constructor(
    public router: Router,
    private lniService: LNIService,
    private http: HttpClient,
    private decimalPipe: DecimalPipe,
    public currencyPipe: CurrencyPipe,) {
    Chart.register(...registerables);
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 100,
    margin: 16,
    navText: ['', ''],
    items: 4,
    // autoplay:true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      768: {
        items: 2
      },
      992: {
        items: 3
      },
      1200: {
        items: 4
      },
    },
    nav: false
  }

  carousalData = [
    {
      posterName: 'Penny',
      postTime: 'Jun, 2023',
      readTime: '6 min read',
      readCaption: 'Executive Summary',
      cardTitle: '2023 Report',
      cardDesc: "This is a nation increasingly divided journalistically, between those who live and work in communities where there is an abundance of local news and those who don’t. Invariably, the",
      cardImg: '/projects/state-of-local-news/explore/assets/Images/image.png',
      cardType: 'report'
    },
    {
      posterName: 'Medill Staff',
      postTime: 'Jan, 2023',
      readTime: '6 min read',
      readCaption: 'Update',
      cardTitle: 'Previous Reports',
      cardDesc: "The second-ever Medill Media Industry Survey was conducted from Nov. 30 through Dec. 31 by Associate Professor Stephanie Edgerly of Medill, and Danielle K. Brown, the Cowles Professor of Journalism Diversity & Equality at the University of Minnesota.",
      cardImg: '/projects/state-of-local-news/explore/assets/Images/image1.png',

      cardType: 'learnmore'
    },
    {
      posterName: 'Medill Staff',
      postTime: 'Feb, 2023',
      readTime: '5 min read',
      readCaption: 'Trends',
      cardTitle: 'Research Insights',
      cardDesc: "This is a nation increasingly divided journalistically, between those who live and work in communities where there is an abundance of local news and those who don’t. Invariably, the",
      cardImg: '/projects/state-of-local-news/explore/assets/Images/image2.png',

      cardType: 'learnmore'
    },
    {
      posterName: 'Medill Staff',
      postTime: 'Feb, 2023',
      readTime: '5 min read',
      readCaption: 'Update',
      cardTitle: 'Monthly Update',
      cardDesc: "Warren has been a senior member of Gannett’s news executive team for more than a decade. In his two most recent posts, he focused on news strategy and career-development for journalists. He will bring those leadership",
      cardImg: '/projects/state-of-local-news/explore/assets/Images/image3.png',
      cardType: 'learnmore'
    }

  ]



  StateNameList: any = [
    {
      "StateCode": '01',
      "StateName": "Alabama",
      "StateAbbr": "AL"
    },
    {
      "StateCode": '02',
      "StateName": "Alaska",
      "StateAbbr": "AK"
    },
    {
      "StateCode": '04',
      "StateName": "Arizona",
      "StateAbbr": "AZ"
    },
    {
      "StateCode": '05',
      "StateName": "Arkansas",
      "StateAbbr": "AR"
    },
    {
      "StateCode": '06',
      "StateName": "California",
      "StateAbbr": "CA"
    },
    {
      "StateCode": '08',
      "StateName": "Colorado",
      "StateAbbr": "CO"
    },
    {
      "StateCode": '09',
      "StateName": "Connecticut",
      "StateAbbr": "CT"
    },
    {
      "StateCode": '10',
      "StateName": "Delaware",
      "StateAbbr": "DE"
    },
    {
      "StateCode": 11,
      "StateName": "District of Columbia",
      "StateAbbr": "DC"
    },
    {
      "StateCode": 12,
      "StateName": "Florida",
      "StateAbbr": "FL"
    },
    {
      "StateCode": 13,
      "StateName": "Georgia",
      "StateAbbr": "GA"
    },
    {
      "StateCode": 15,
      "StateName": "Hawaii",
      "StateAbbr": "HI"
    },
    {
      "StateCode": 16,
      "StateName": "Idaho",
      "StateAbbr": "ID"
    },
    {
      "StateCode": 17,
      "StateName": "Illinois",
      "StateAbbr": "IL"
    },
    {
      "StateCode": 18,
      "StateName": "Indiana",
      "StateAbbr": "IN"
    },
    {
      "StateCode": 19,
      "StateName": "Iowa",
      "StateAbbr": "IA"
    },
    {
      "StateCode": 20,
      "StateName": "Kansas",
      "StateAbbr": "KS"
    },
    {
      "StateCode": 21,
      "StateName": "Kentucky",
      "StateAbbr": "KY"
    },
    {
      "StateCode": 22,
      "StateName": "Louisiana",
      "StateAbbr": "LA"
    },
    {
      "StateCode": 23,
      "StateName": "Maine",
      "StateAbbr": "ME"
    },
    {
      "StateCode": 24,
      "StateName": "Maryland",
      "StateAbbr": "MD"
    },
    {
      "StateCode": 25,
      "StateName": "Massachusetts",
      "StateAbbr": "MA"
    },
    {
      "StateCode": 26,
      "StateName": "Michigan",
      "StateAbbr": "MI"
    },
    {
      "StateCode": 27,
      "StateName": "Minnesota",
      "StateAbbr": "MN"
    },
    {
      "StateCode": 28,
      "StateName": "Mississippi",
      "StateAbbr": "MS"
    },
    {
      "StateCode": 29,
      "StateName": "Missouri",
      "StateAbbr": "MO"
    },
    {
      "StateCode": 30,
      "StateName": "Montana",
      "StateAbbr": "MT"
    },
    {
      "StateCode": 31,
      "StateName": "Nebraska",
      "StateAbbr": "NE"
    },
    {
      "StateCode": 32,
      "StateName": "Nevada",
      "StateAbbr": "NV"
    },
    {
      "StateCode": 33,
      "StateName": "New Hampshire",
      "StateAbbr": "NH"
    },
    {
      "StateCode": 34,
      "StateName": "New Jersey",
      "StateAbbr": "NJ"
    },
    {
      "StateCode": 35,
      "StateName": "New Mexico",
      "StateAbbr": "NM"
    },
    {
      "StateCode": 36,
      "StateName": "New York",
      "StateAbbr": "NY"
    },
    {
      "StateCode": 37,
      "StateName": "North Carolina",
      "StateAbbr": "NC"
    },
    {
      "StateCode": 38,
      "StateName": "North Dakota",
      "StateAbbr": "ND"
    },
    {
      "StateCode": 39,
      "StateName": "Ohio",
      "StateAbbr": "OH"
    },
    {
      "StateCode": 40,
      "StateName": "Oklahoma",
      "StateAbbr": "OK"
    },
    {
      "StateCode": 41,
      "StateName": "Oregon",
      "StateAbbr": "OR"
    },
    {
      "StateCode": 42,
      "StateName": "Pennsylvania",
      "StateAbbr": "PA"
    },
    {
      "StateCode": 44,
      "StateName": "Rhode Island",
      "StateAbbr": "RI"
    },
    {
      "StateCode": 45,
      "StateName": "South Carolina",
      "StateAbbr": "SC"
    },
    {
      "StateCode": 46,
      "StateName": "South Dakota",
      "StateAbbr": "SD"
    },
    {
      "StateCode": 47,
      "StateName": "Tennessee",
      "StateAbbr": "TN"
    },
    {
      "StateCode": 48,
      "StateName": "Texas",
      "StateAbbr": "TX"
    },
    {
      "StateCode": 49,
      "StateName": "Utah",
      "StateAbbr": "UT"
    },
    {
      "StateCode": 50,
      "StateName": "Vermont",
      "StateAbbr": "VT"
    },
    {
      "StateCode": 51,
      "StateName": "Virginia",
      "StateAbbr": "VA"
    },
    {
      "StateCode": 53,
      "StateName": "Washington",
      "StateAbbr": "WA"
    },
    {
      "StateCode": 54,
      "StateName": "West Virginia",
      "StateAbbr": "WV"
    },
    {
      "StateCode": 55,
      "StateName": "Wisconsin",
      "StateAbbr": "WI"
    },
    {
      "StateCode": 56,
      "StateName": "Wyoming",
      "StateAbbr": "WY"
    }
  ]

  public chart1: any;
  public chart2: any;


  ViewWidth = 0
  ViewHeight = 0;
  ViewLeft = 0

  MapContainerWidth = 0

  ngOnInit() {

    setTimeout(() => {


      document.getElementById("home")?.classList.add("active");
      document.getElementById("newspaper")?.classList.remove("active");
      const div: any = document.getElementsByClassName('MapContainer'); // Get the div element by its ID
      const w = div[0].offsetWidth;

      this.MapContainerWidth = w

      let MapLengends: any = document.getElementById("pills-tabContent")

      if (w > 250 && w < 600) {
        const width = MapLengends.offsetWidth
        const height = 740
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '5%';
        this.width = w
        this.height = 300
        this.ViewWidth = w
        this.ViewHeight = 750
        this.ViewLeft = 250


      } else if (w > 601 && w < 800) {
        const width = MapLengends.offsetWidth
        const height = 740
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '5%';
        this.width = 750
        this.height = 500
        this.ViewWidth = 900
        this.ViewHeight = 750
        this.ViewLeft = 0

      }

      else if (w > 801 && w < 1000) {
        const width = MapLengends.offsetWidth
        const height = 550
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '0';
        this.width = w
        this.height = 550
        this.ViewWidth = w
        this.ViewHeight = 700;
        this.ViewLeft = 0


      }
      else if (w > 1001 && w < 1200) {
        const width = 600;
        const height = 400
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '0';

        this.ViewLeft = 0

        this.width = w + 300
        this.height = 750
        this.ViewWidth = w
        this.ViewHeight = 700

      }
      else if (w > 1201) {
        const width = MapLengends.offsetWidth
        const height = 740
        this.Zoom = 'scale(1)';
        this.Top = '20px';
        this.left = '5%';
        this.width = w + 700
        this.height = 950
        this.ViewWidth = w
        this.ViewHeight = 700;
        this.ViewLeft = 0

      }

      this.Loading = true;
      this.chart1Completed = true
      this.getPaperDetailes();
    }, 100);


  }

  getTabName(Name: any, ParameterName: any) {

    this.ParameterName = ParameterName
    this.zoomLevel = 1
    if (Name === 'AtRisk') {
      this.TabName = 'Local News Landscape'
      if (this.chart2Completed) {
        return
      }
      this.chart2Completed = true
      this.Loading = true
      this.StateDataArr.consolidations.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfNewspapers) + Number(element.countOfDigitalSites) + Number(element.countOfEthnicVoice) + Number(element.countOfPBStation)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.countOfNewspapers })
      });
    }
    else if (Name === 'NewsPaper') {
      this.TabName = 'Newspaper Count'
      if (this.chart2Completed) {
        return
      }
      this.chart2Completed = true
      this.Loading = true
      this.StateDataArr.consolidations.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfNewspapers)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.countOfNewspapers })
      });
      this.MapId = 'NewsPaperMapSVG'
      this.MapFormation(this.StateDataArr.consolidations, 'NewsPaperMapSVG')
    } else if (Name === 'DigitalSite') {
      this.TabName = 'Digital Sites Count'
      if (this.chart3Completed) {
        return
      }
      this.chart3Completed = true
      this.Loading = true
      this.StateDataArr.consolidations.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfDigitalSites)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.countOfDigitalSites })
      });
      this.MapId = 'digitalSitesMapSVG'

      this.MapFormation(this.StateDataArr.consolidations, 'digitalSitesMapSVG')
    }
    else if (Name === 'Ethnic') {
      this.TabName = 'Ethnic Outlets Count'
      if (this.chart4Completed) {
        return
      }
      this.chart4Completed = true
      this.Loading = true
      this.StateDataArr.consolidations.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfEthnicVoice)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.countOfEthnicVoice })
      });
      this.MapId = 'EthnicVoiceMapSVG'

      this.MapFormation(this.StateDataArr.consolidations, 'EthnicVoiceMapSVG')
    }
    else if (Name === 'Pb') {
      this.TabName = 'Public Broadcasting Count'
      if (this.chart5Completed) {
        return
      }
      this.chart5Completed = true
      this.Loading = true
      this.StateDataArr.consolidations.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfPBStation)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.countOfPBStation })
      });
      this.MapId = 'PubliCProdCastMapSVG'

      this.MapFormation(this.StateDataArr.consolidations, 'PubliCProdCastMapSVG')
    } else {
      this.TabName = 'Newspaper Count'
      if (this.chart1Completed) {
        return
      }
      this.chart1Completed = true;
      this.Loading = true
      this.StateDataArr.consolidations.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfNewspapers)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.countOfNewspapers })
      });
      this.MapId = 'NewsPaperMapSVG'

      this.MapFormation(this.StateDataArr.consolidations, 'NewsPaperMapSVG')
      // tab Name for At-Risk Conditiom

    }

  }

  stateChange(event: any, state: any) {
    if (event.source.selected) {
      setTimeout(() => {
        let stateCode = state.StateCode
        let navigationExtras: NavigationExtras = {
          queryParams: { 'state': state.StateAbbr, stateCode },
        };
        this.router.navigate(['/newsNaper'], navigationExtras);
      }, 50);

    }

  }
  public getJSON(): Observable<any> {
    return this.http.get("/projects/state-of-local-news/explore/assets/stateData.json");
  }

  getPaperDetailes() {


    this.getJSON().subscribe((data: any) => {

      this.StateDataArr = data
      data.consolidations.forEach((element: any) => {
        element.fips = Number(element.countOfNewspapers)
        Object.assign(element, { value: element.countOfNewspapers })
      });
      this.MapId = 'RiskCityMapSVG';
      this.MapFormation1(data.consolidations)
    });

  }
  MapId = 'RiskCityMapSVG';

  MapFormation(Data: any, MapId: string) {
    if (this.StateBoundariesGeoJsonData.length > 0) {
      this.MapFormation1(Data)
    } else {
      Promise.all([
        // d3.json('/projects/state-of-local-news/explore/assets/gz_2010_us_050_00_5m.json'),
        d3.json('/projects/state-of-local-news/explore/assets/StateBorde.geojson'),
        d3.json('/projects/state-of-local-news/explore/assets/counties.json')

      ]).then((data) => {
        this.StateBoundariesGeoJsonData = data[0]; // 
        this.geoData = data[1]
        this.MapFormation1(Data);

      });
    }


  }


  async MapFormation1(Data: any) {

    const URL_COUNTY = "/projects/state-of-local-news/explore/assets/counties.json";

    // Replace 'YOUR_JSON_URL' with the URL to your own JSON data.
    const YOUR_JSON_URL = "/projects/state-of-local-news/explore/assets/UsEdu.json";

    let promises: any = [];

    function addJsonReqPromise(url: any) {
      promises.push(d3.json(url));
    }

    addJsonReqPromise(URL_COUNTY);
    addJsonReqPromise(YOUR_JSON_URL);

    Promise.all(promises).then((data) => {
      let dataEducation = Data;
      data[1].forEach((element: any) => {
        if (!element.area_name.includes(' County')) {
          element.area_name = element.area_name + ' County'

        }

        element.area_name = element.area_name.split(' County')[0]
      });
      var fibsCount = data[1];

      let i = 0;

      dataEducation.forEach((element: any) => {

        var matchFound = fibsCount.find((ele: any) => ele.state === element.state && (element.county.includes(ele.area_name) || ele.area_name.includes(element.county)))
        if (matchFound) {


          // Object.assign(element, { value: element.countOfNewspapers })
          Object.assign(element, { fips: matchFound.fips })

        } else {
          i = i + 1

        }


      });
      let geoData = data[0];


      let colors: any = d3.schemeBlues[7];



      function getCountyByFips(fips: any) {


        var county = dataEducation.find((county: any) => county.fips === fips);
        return county;
      }
      // let section = d3.select(`#${this.MapId}`)
      // .append("section");



      let svg = d3.select(`#${this.MapId}`)
        .append("svg")
        .attr("width", this.width)
        .attr("height", this.height)
        .attr("viewBox", `${this.ViewLeft} 0 ${this.ViewWidth} ${this.ViewHeight}`); // Set the viewBox to match the SVG dimensions
      this.SVG = svg
      const g = svg.append("g")
      this.MapG = g
      const newTransform = d3.zoomIdentity.scale(this.zoomLevel);
      this.MapG.attr('transform', newTransform);

      this.restoreTransform = newTransform;

      //DRAWING COUNTIES
      let geojson: any = topojson.feature(geoData, geoData.objects.counties)

      let path: any = d3.geoPath();
      let idx = 0
      const tooltip: any = d3.select(`#${this.MapId}`).append("div")
        .attr("class", "dataTip")
        .attr("style", " position: absolute")
        // .attr("style", " display: none")

        .attr("id", "tooltip")
      g.selectAll("path")
        .data(geojson.features)
        .enter()
        .append("path")
        .attr("d", path)
        .attr("class", "county")
        .attr("name", (d: any) => {
          let country = getCountyByFips(d.id)
          return country?.state
        })
        .style('stroke', '#7d91a6')
        .style("stroke-width", "0.25px")
        .attr("fill", (d: any) => {
          let country = getCountyByFips(d.id)

          if (this.MapId === 'RiskCityMapSVG') {
            if (country == undefined) {
              return '#fff'
            } else {
              if (this.RadioOptionValue === 'Local News Landscape') {

                const total = Number(country?.countOfNewspapers) + Number(country?.countOfDigitalSites) + Number(country?.countOfEthnicVoice) + Number(country?.countOfPBStation);
                return this.colorCountry(total);
              }
              else if (this.RadioOptionValue === 'Watchlist Counties') {
                if (country?.category === 'Desert') {
                  return '#000'
                } else {
                  return '#254B8C'
                }
              }
              else if (this.RadioOptionValue === 'Metro Areas') {
                if (country?.digitalReplacement === 'Light Blue') {
                  return '#4ba8c9'
                } else {
                  return '#254B8C'
                }

              }
              else {
                if (country?.value === 'More At Risk') {
                  return '#254B8C'
                } else {
                  return '#4ba8c9'
                }
              }
            }
          }
          else if (this.MapId === 'NewsPaperMapSVG') {
            if (country == undefined) {
              return '#fff'
            } else {
              return this.colorCountry(country?.countOfNewspapers);
            }

          }


          else if (this.MapId === 'digitalSitesMapSVG') {
            if (country == undefined) {
              return '#fff'
            } else {
              return this.colorCountry(country?.countOfDigitalSites);
            }

          }
          else if (this.MapId === 'EthnicVoiceMapSVG') {
            if (country == undefined) {
              return '#fff'
            } else {
              return this.colorCountry(country?.countOfEthnicVoice);

            }
          } else {
            if (country == undefined) {
              return '#fff'
            } else {
              return this.colorCountry(country?.countOfPBStation);
            }
          }
        })
        .attr("data-fips", (d: any) => d.id)
        .on("mouseover", (d: any) => {
          const highlightClass = 'highlighted-county';
          let country = getCountyByFips(d.target.__data__.id)
          // console.log(country.state);


          if (country.state) {
            const matchingCounties = document.querySelectorAll(`[name="${country?.state}"]`);
            // console.log(matchingCounties);

            matchingCounties.forEach((element: any) => {
              element.style.opacity = "0.5";
              element.transition = "fill 0.1s";

            });
          }


          if (this.TabName === 'Local News Landscape') {


            if (this.RadioOptionValue === 'Local News Landscape') {
              const total = country?.countOfNewspapers + country?.countOfDigitalSites + country?.countOfEthnicVoice + country?.countOfPBStation
              tooltip.html(
                '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'Newspapers' + '</span>' + '<span class="count">' + country?.countOfNewspapers + '</span>' + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'Digital Sites' + '</span>' + '<span class="count">' + country?.countOfDigitalSites + '</span>' + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'Ethnic Outlets' + '</span>' + '<span class="count">' + country?.countOfEthnicVoice + '</span>' + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'Public Broadcasting' + '</span>' + '<span class="count">' + country?.countOfPBStation + '</span>' + '</div>'
                + '<div class="dataTipCount totalOutlets">' + '<span>' + 'Total no. of news outlets' + '</span>' + '<span class="count">' + total + '</span>' + '</div>'
              )
            } else if (this.RadioOptionValue === 'Watchlist Counties') {
              tooltip.html(
                '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'Average poverty rate' + '</span>' + '<span class="count">' + country?.averagePovertyRate + '</span>' + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'Population' + '</span>' + '<span class="count">' + this.decimalPipe.transform(country?.population) + '</span>' + '</div>'

              )
            } else if (this.RadioOptionValue === 'Metro Areas') {
  
              tooltip.html(
                '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'Population' + '</span>' + '<span class="count">' + this.decimalPipe.transform(country?.population) + '</span>' + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'Number of news sites lost' + '</span>' + '<span class="count">' + country?.numberOfNewsSitesLost + '</span>' + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'News sites added' + '</span>' + '<span class="count">' + country?.newsSitesAdded + '</span>' + '</div>'
                + '<div class="dataTipCount">' + '<span>' + 'Total active news outlets' + '</span>' + '<span class="count">' + country?.totalActiveNewsOutlets + '</span>' + '</div>'
              )
            } else {
              tooltip.html(
                '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'

              )
            }
          }

          else if (this.TabName === 'Newspaper Count') {

            tooltip.html(
              '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
              + '<div class="dataTipCount">' + '<span>' + this.TabName + '</span>' + '<span class="count">' + country?.countOfNewspapers + '</span>' + '</div>'
            )
          } else if (this.TabName === 'Digital Sites Count') {
            tooltip.html(
              '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
              + '<div class="dataTipCount">' + '<span>' + this.TabName + '</span>' + '<span class="count">' + country?.countOfDigitalSites + '</span>' + '</div>'
            )

          }
          else if (this.TabName === 'Ethnic Outlets Count') {
            tooltip.html(
              '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
              + '<div class="dataTipCount">' + '<span>' + this.TabName + '</span>' + '<span class="count">' + country?.countOfEthnicVoice + '</span>' + '</div>'
            )

          } else {
            tooltip.html(
              '<div class="dataTipHeader"> County :  ' + country?.county + ', ' + country?.state + '</div>'
              + '<div class="dataTipCount">' + '<span>' + this.TabName + '</span>' + '<span class="count">' + country?.countOfPBStation + '</span>' + '</div>'
            )

          }


          d3.select('tooltip')
            .style("stroke", "#000")
            .style("stroke-width", "2px");
          const tooltipHeight = tooltip.node().offsetHeight;
          let tooltipX = 0

          if (d.screenX + 350 >= this.width) {

            tooltipX = d.screenX - 350;
          }
          else {
            tooltipX = d.screenX + 10;

          }
          let tooltipY = d.screenY - tooltipHeight - 10;

          if (tooltipY < 0) {
            tooltipY = d.screenY + 10;
          }

          if (tooltipY > this.height) {
            tooltipY = tooltipY - 100
          }

          if (tooltipX < 0) {
            tooltipX = 0
          }
          if (tooltipX + 300 > this.MapContainerWidth) {
            tooltipX = this.MapContainerWidth - 300
          }

          tooltip.transition().style('opacity', 1)
            .style('left', tooltipX + 'px')
            .style('top', tooltipY + 'px')
            .style('box-shadow', '0px 0px 9px 2px rgba(0, 0, 0, 0.05)')
            .style('background-color', '#fff')
            .style('border-radius', '8px')
            .style('font-family', 'akkuratregular')
            .style('font-size', '16px');

        })

        .on("mouseout", (d: any) => {
          const allCounties = document.querySelectorAll(".county");
          allCounties.forEach((element: any) => {
            element.style.opacity = "1";
          });
        })
        .on("click", (d: any) => {

          let country1 = getCountyByFips(d.target.__data__.id)



          const country = this.StateNameList.find((element: any) => element.StateAbbr == country1?.state)


          routingMap(country1?.state, country?.StateCode)
        })


      const routingMap = (stateShotName: any, stateCode: any) => {
        let navigationExtras: NavigationExtras = {
          queryParams: { 'state': stateShotName, stateCode },
        };
        this.router.navigate(['/newsNaper'], navigationExtras);
      }


      let borders = g.append("path")
        .classed("stateBorder", true)
        .attr("fill", "none")
        .attr("stroke", "#bec4db")
        .attr("stroke-width", "1.5px")
        .datum(topojson.mesh(geoData, geoData.objects.states))
        .attr('d', path)
        .filter(function (d: any) {
          return d === svg.node();
        });


        let debounceTimeout:any = null;

const zoomed = (event: any) => {
  if(event?.sourceEvent?.type ==='wheel'){
    // event?.sourceEvent?.preventDefault();
    return

  }
  if (event?.sourceEvent?.type === 'dblclick') {
    // If there's a debounce timeout, clear it
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
      debounceTimeout = null;
    }

    // Handle the double-click action here
    const { transform } = event;
    console.log(transform);
    this.TransFormedValue = transform;
    g.attr("transform", transform);
    g.attr("stroke-width", 1);
  } else {
    // If not a double-click, initiate a debounce timeout
    debounceTimeout = setTimeout(() => {
      const { transform } = event;
      this.TransFormedValue = transform;
      g.attr("transform", transform);
      g.attr("stroke-width", 1);
      debounceTimeout = null; // Reset the debounce timeout
    }, 50); // Adjust the debounce time as needed
  }
};

  
        
      const zoomEnd = (event: any) => {
        this.currentTransform = event.transform;
      }

      this.originalTransform = d3.zoomIdentity;

      const zoom: any = d3.zoom()
        .scaleExtent([1, 8])
        .on("zoom", zoomed.bind(this))
        .on("end", zoomEnd.bind(this));
      this.ZOOM = zoom
      svg.call(zoom);





    })

    setTimeout(() => {

      this.Loading = false
    }, 300);


  }

  ZOOM: any = ''
  TransFormedValue = 0;

  currentTransform = 0;

  originalTransform: any

  allowZooming: any = false

  colorCountry(country: any) {

    if (country == null || country == undefined) {
      return '#ffffff';
    }

    if (country == null || country == undefined || country === 0) {
      return '#FFE59C';
    }
    else if (country === 1) {
      return '#4ba8c9';
    }
    else if (country > 1) {
      return '#254B8C';
    } else {
      return '#ffffff';
    }
  }


  chartConfig1() {
    const canvas = document.getElementById('MyChart1') as HTMLCanvasElement;

    this.chart1 = new Chart(canvas, {
      type: 'bar',
      data: {
        labels: ['2017', '2018', '2019', '2020', '2021'],
        datasets: [
          {
            label: 'Total Transactions',
            data: [50, 60, 40, 30, 35],
            borderWidth: 1,
            borderColor: '#fff',
            backgroundColor: '#F6C74B',
            borderRadius: 10,
            barPercentage: 0.8, // Adjust the width of the bars for the first dataset
            // categoryPercentage: 0.4, // Adjust the space between categories for the first dataset
          },
          {
            label: 'Number of Papers',
            data: [100, 70, 150, 100, 60],
            borderWidth: 1,
            borderColor: '#fff',
            backgroundColor: '#DD6049',
            borderRadius: 10,
            barPercentage: 0.8, // Adjust the width of the bars for the first dataset
            // Adjust the width of the bars for the second dataset
            // categoryPercentage: 0.4, // Adjust the space between categories for the second dataset
          },
        ],
      },
      options: {
        // aspectRatio: 2.5,
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false, // Remove y-axis grid lines
            },
            ticks: {
              color: '#22222280',
              font: {
                size: 12,
                family: 'akkuratregular',
                weight: '600',
              },
            },
            border: {
              display: false, // Remove x-axis border
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false, // Remove y-axis grid lines
            },
            ticks: {
              color: '#22222280',
              font: {
                size: 12,
                family: 'akkuratregular',
                weight: '600',
              },
            },
            border: {
              display: false, // Remove x-axis border
            },
          },
        },
        responsive: false,
        animation: {
          duration: 1000,
          easing: 'linear',
        },
        layout: {
          padding: 0,
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            fullSize: true,
            display: true,
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              boxWidth: 12,
              boxHeight: 12,
              padding: 32,
              color: '#222222',
              font: {
                size: 13,
                family: 'akkuratregular',
                weight: '600',
                lineHeight: '15px',
              },
            },
          },
        },
      },
    });

    this.chart1.update();
  }

  chartConfig2() {
    const canvas = document.getElementById('MyChart2') as unknown as HTMLCanvasElement;

    this.chart2 = new Chart(canvas, {
      type: 'line',
      data: {
        labels: ['Other ', 'Accounting', 'Production', 'Sales', 'Newsroom'],
        datasets: [
          {
            label: '2006',
            data: [140000, 30000, 110000, 80000, 90000],
            borderWidth: 3,
            borderColor: '#DD6049',
            backgroundColor: '#DD6049',
            spanGaps: true,

          },
          {
            label: '2021',
            data: [50000, 10000, 30000, 30000, 40000],
            borderWidth: 3,
            borderColor: '#F6C74B',
            backgroundColor: '#F6C74B',
          },
        ],
      },
      options: {
        aspectRatio: 2.5,
        scales: {
          x: {
            ticks: {
              color: '#22222280',
              font: {
                size: 12,
                family: 'Akkurat',
                weight: '400',
              },
            },
            border: {
              display: false, // Remove x-axis border
            },
          },
          y: {
            beginAtZero: true,

            grid: {
              display: false, // Remove y-axis grid lines
            },
            ticks: {
              color: '#22222280',
              font: {
                size: 12,
                family: 'akkuratregular',
                weight: '600',
              },
            },

          },
        },
        responsive: false,
        animation: {
          duration: 1000,
          easing: 'linear',
        },
        layout: {
          padding: 0,

        },
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            fullSize: true,
            // lineWidth: 10,
            display: true,
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              boxWidth: 8,

              // lineWidth: 10,
              boxHeight: 8,
              padding: 32,
              color: '#222222',

              font: {
                size: 1,
                family: 'akkuratregular',
                weight: '600',
                lineHeight: '15px',

                // boxWidth:50,
              }
            }
          }
        }
      },
    });
    this.chart2.update();
  }
  Scroll(postion: any) {

    const element: any = document.getElementById('pills-tab');
    if (postion === 'right') {
      element?.scrollBy({ left: 100, behavior: 'smooth' }); // for right scroll
    } else {
      element?.scrollBy({ left: -100, behavior: 'smooth' });
    }
  }



  radioChange(radioValue: any) {


    d3.select('#tooltip').remove();
    d3.select("#RiskCityMapSVG").remove();
    d3.select('.RiskCityMapSVG').append("div")
      .attr("id", 'RiskCityMapSVG')
    if (radioValue.value === 'Local News Landscape') {
      this.MapId = 'RiskCityMapSVG'
      this.MapFormation1(this.StateDataArr.consolidations)
    } else if (radioValue.value === 'Watchlist Counties') {
      this.getWatchListData();
    }
    else if (radioValue.value === 'Metro Areas') {
      this.getMetroListData();
    } else {
      this.getEcometerData();
    }

  }



  getWatchListData() {
    this.Loading = true
    this.lniService.getWatchListData().subscribe((data: any) => {
      data.watchlistCounties.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfDigitalSites)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.category })
        Object.assign(element, { county: element.countyName })
      });
      this.MapId = 'RiskCityMapSVG'
      this.MapFormation1(data.watchlistCounties)
    });
  }

  getMetroListData() {
    this.Loading = true
    this.lniService.getMetroListData().subscribe((data: any) => {

      data.metroAreas.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfDigitalSites)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.digitalReplacement })
        Object.assign(element, { county: element.countyName })

      });
      this.MapId = 'RiskCityMapSVG'
      this.MapFormation1(data.metroAreas)
    });
  }


  getEcometerData() {
    this.Loading = true
    this.lniService.getEcometerData().subscribe((data: any) => {
      data.localNewsEcosystemBarometers.forEach((element: any) => {
        element.totalCirculation = Number(element.countOfDigitalSites)
        element.fips = Number(element.fips)
        Object.assign(element, { value: element.value })
        Object.assign(element, { county: element.countyName })

      });
      this.MapId = 'RiskCityMapSVG'
      this.MapFormation1(data.localNewsEcosystemBarometers)
    });
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
    d3.select("#tooltip").remove();
    d3.select("#RiskCityMapSVG").remove();
    d3.select("#NewsPaperMapSVG").remove();
    d3.select("#digitalSitesMapSVG").remove();
    d3.select("#EthnicVoiceMapSVG").remove();
    d3.select("#PubliCProdCastMapSVG").remove();
  }
  private zoomLevel: number = 1



  zoomOut() {
    this.allowZooming = true
    this.zoomLevel /= 1.2; // You can adjust the zoom factor as needed
    this.updateZoom();
    // this.MapG.attr("transform", this.zoomLevel);
  }

  MapG: any = '';

  restoreTransform: any = d3.zoomIdentity;

  SVG: any = '';

  updateZoom() {
    const newTransform = d3.zoomIdentity.scale(this.zoomLevel);

    this.MapG.attr('transform', newTransform);

    this.restoreTransform = newTransform;

  }

  zoomIN() {
    this.zoomLevel *= 1.2; // You can adjust the zoom factor as needed
    this.updateZoom();
    this.allowZooming = true
  }

  ReStore() {



    const newTransform1 = d3.zoomIdentity;
    this.SVG.transition().duration(1).call(this.ZOOM.transform, newTransform1);
    this.zoomLevel = 1;
    const newTransform = this.originalTransform;
    this.MapG.attr("transform", newTransform);
    this.TransFormedValue = newTransform;
    this.currentTransform = newTransform;

    this.zoomLevel = 1;
    this.allowZooming = true
    this.updateZoom();
    // this.MapG.attr("transform", `scale(1.5)`);


  }

}
