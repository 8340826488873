import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { NewsPaperComponent } from './news-paper/news-paper.component';
import { NationalOutletsComponent } from './national-outlets/national-outlets.component';
import { BrightspotsComponent } from './brightspots/brightspots.component';

const routes: Routes = [
// {
//     path:'',
//     component:NationalOutletsComponent
//   },
//   {
//     path:'newsNaper',
//     component:NewsPaperComponent
//   },
//   {
//     path:'brightSpots',
//     component:BrightspotsComponent
//   },

{
  path:'',
  pathMatch:'full',
  redirectTo:'localnewslandscape'
},
{
  path:'localnewslandscape',
  component:NationalOutletsComponent
},
{
  path:'newspapers',
  component:NationalOutletsComponent
},
{
  path:'digitalsites',
  component:NationalOutletsComponent
},
{
  path:'ethnicoutlets',
  component:NationalOutletsComponent
},
{
  path: 'networksites',
  component:NationalOutletsComponent
},
{
  path:'publicbroadcasting',
  component:NationalOutletsComponent
},
{
  path:'watchlist',
  component:NationalOutletsComponent
},
{
  path:'metroareanews',
  component:NationalOutletsComponent
},
{
  path:'localnewsecosystembarometer',
  component:NationalOutletsComponent
},

    {
      path:'state-localnewslandscape',
      component:NewsPaperComponent
    },
    {
      path:'state-newspapers',
      component:NewsPaperComponent
    },
    {
      path:'state-standalonedigitalsites',
      component:NewsPaperComponent
    },
    {
      path:'state-networkdigitalsites',
      component:NewsPaperComponent
    },
    {
      path:'state-ethnicoutlets',
      component:NewsPaperComponent
    },
    {
      path:'state-publicbroadcasting',
      component:NewsPaperComponent
    },
     {
      path:'brightSpots',
      component:BrightspotsComponent
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes ,{ useHash: true })],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule {}
