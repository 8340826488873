<div class="loader-div" *ngIf="Loading">
    <div class="loader">
        <div class="one"></div>
        <div class="two"></div>
    </div>
</div>
<section class="mainContent nationalouttab">
    <div class="locationInfo gContainerMain">
        <div class="stateName">State of Local News Outlook</div>

        <div class="stateDescription">
            {{BannerContent}}
        </div>


        <div class="viewReportC" rel="noopener noreferrer"><a
                href="https://localnewsinitiative.northwestern.edu/projects/state-of-local-news/2024/report/#executive-summary"
                target="_blank">View 2024 Report</a></div>
    </div>

    <div class="InnerContent1 gContainerMain">


        <div class="TabSection">
            <div class="d-flex align-items-center my-2">

                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                    <li class="nav-item " role="presentation" (click)="getTabName('AtRisk', 'Local News Landscape')">
                        <button class="nav-link show  " name="RiskCity" id="localnewslandscape" data-bs-toggle="pill"
                            data-bs-target="#RiskCity" type="button" role="tab" aria-controls="RiskCity"
                            aria-selected="true">{{RadioOptionValue}}</button>
                    </li>
                    <li class="nav-item " role="presentation" (click)="getTabName('NewsPaper', 'Newspapers')">
                        <button class="nav-link" id="newspapers" name="NewsPaper" data-bs-toggle="pill"
                            data-bs-target="#NewsPaper" type="button" role="tab" aria-controls="NewsPaper"
                            aria-selected="false">Newspapers</button>
                    </li>
                    <li class="nav-item" role="presentation" (click)="getTabName('DigitalSite', 'Digital Sites')">
                        <button class="nav-link" id="digitalsites" name="DigitalSites" data-bs-toggle="pill"
                            data-bs-target="#digitalSites" type="button" role="tab" aria-controls="digitalSites"
                            aria-selected="false">Standalone Digital
                            Sites</button>
                    </li>
                    <li class="nav-item" role="presentation" (click)="getTabName('NetworkSites', 'Network Sites')">
                        <button class="nav-link" id="networksites" name="NetworkSites" data-bs-toggle="pill"
                            data-bs-target="#networkSites" type="button" role="tab" aria-controls="networkSites"
                            aria-selected="false">Network
                            Sites</button>
                    </li>
                    <li class="nav-item" role="presentation" (click)="getTabName('Ethnic', 'Ethnic Outlets')">
                        <button class="nav-link" id="ethnicoutlets" name="EthinicVoice" data-bs-toggle="pill"
                            data-bs-target="#EthinicVoice" type="button" role="tab" aria-controls="EthinicVoice"
                            aria-selected="false">Ethnic
                            Outlets</button>
                    </li>
                    <li class="nav-item" role="presentation" (click)="getTabName('Pb', 'Public Broadcasting')">
                        <button class="nav-link" id="publicbroadcasting" name="PBStation" data-bs-toggle="pill"
                            data-bs-target="#publicProdCast" type="button" role="tab" aria-controls="publicProdCast"
                            aria-selected="false">Public Broadcasting</button>
                    </li>

                </ul>
                <!-- <button mat-icon-button class="ScrollBtn" (click)="Scroll('right')">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>-->

            </div>

            <div class="tab-content" id="pills-tabContent">
                <div class="RiskParameterSection">
                    <div class="chartInsideCaption">
                        {{ParameterName !=='Local News Landscape' ? ParameterName : RadioOptionValue}}
                    </div>
                    <!-- {{ParameterName}} -->


                    <div class="chartInsideDesc" *ngIf="ParameterName ==='Newspapers'">
                        {{TopContentNews}}
                    </div>
                    <div class="chartInsideDesc" *ngIf="ParameterName ==='Digital Sites'">
                        {{TopContentDigital}}
                    </div>
                    <div class="chartInsideDesc" *ngIf="ParameterName ==='Network Sites'">
                        {{TopContentNetwork}}
                    </div>
                    <div class="chartInsideDesc" *ngIf="ParameterName ==='Ethnic Outlets'">
                        {{TopContentEthinic}}
                    </div>
                    <div class="chartInsideDesc" *ngIf="ParameterName =='Public Broadcasting'">
                        {{TopContentPB}}
                    </div>

                    <div class="row" *ngIf="ParameterName ==='Local News Landscape'">
                        <div class="col-xl-8 col-sm-12 col-md-8">
                            <div class="chartInsideDesc">
                                {{TopContentLocal}}

                                <a href="https://localnewsinitiative.northwestern.edu/assets/forecasting_future_news_deserts_malthouse_et_al.pdf"
                                    target="_blank" rel="noopener noreferrer"
                                    *ngIf="RadioOptionValue ==='Local News Ecosystem Barometer'">Click here</a>
                                <span *ngIf="RadioOptionValue ==='Local News Ecosystem Barometer'"> for additional
                                    modeling details and results. </span>
                            </div>
                        </div>
                        <div class="col-xl-4 col-sm-12 col-md-12">
                            <div class="radioContainer">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                    [(ngModel)]="RadioOptionValue" (change)="radioChangeManual($event)">
                                    <mat-radio-button class="RadioButton" *ngFor="let Option of RadioOptions"
                                        [value]="Option.value">
                                        {{Option.Name}}
                                        <!-- <label for="" class="RadioButtonLable"> {{Option.Name}}</label> -->
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="tab-pane fade show  " id="RiskCity" role="tabpanel" aria-labelledby="localnewslandscape"
                    name="localnewslandscape">
                    <div class="d-flex justify-content-between">
                        <div class="HeatMapRange home-legend" *ngIf="this.RadioOptionValue === 'Local News Landscape'">
                            <span class="noNewsPaper"> <span></span>No news outlets</span>
                            <span class="oneNewsPaper"><span></span>1 news outlet</span>
                            <span class="multipleNewsPaper"> <span></span>2 or more news outlets</span>
                        </div>
                        <div class="HeatMapRange home-legend" *ngIf="this.RadioOptionValue === 'Watch List Counties'">
                            <span class="DesertLegend"> <span></span>Desert</span>
                            <span class="WatchlistLegend"><span></span>Watch List</span>
                        </div>
                        <div class="HeatMapRange home-legend-1" *ngIf="this.RadioOptionValue === 'Metro Areas'">
                            <span class="LowMetro"> <span></span>Low Digital Replacement</span>
                            <span class="HighMetro"><span></span>High Digital Replacement</span>
                        </div>
                        <div class="HeatMapRange home-legend-barometer"
                            *ngIf="this.RadioOptionValue === 'Local News Ecosystem Barometer'">
                            <span class="LowDigital"> <span></span>Less At Risk</span>
                            <span class="HighDegital"><span></span>More At Risk </span>
                        </div>

                    </div>
                    <div class="MapContainer">



                        <main class="choropleth RiskCityMapSVG">
                            <div id="RiskCityMapSVG"></div>
                            <div id="choropleth" class="d-flex justify-content-center"></div>
                            <div id="description"></div>
                        </main>

                        <div class="zoom-func zoomingsection">
                            <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                        </div>
                    </div>
                    <div class="content">
                        {{BottomContentLocal}}
                    </div>
                    <!-- <div class="dgSourceName">Source: CNN (2020)</div> -->
                </div>
                <div class="tab-pane fade" id="NewsPaper" role="tabpanel" aria-labelledby="newspapers"
                    name="newspapers">
                    <div class="d-flex justify-content-between">
                        <div class="HeatMapRange">

                            <!-- <span class="noNewsPaper"><span></span> 0 Newspapers</span> -->
                            <span class="oneNewsPaper"> <span></span> 1 Newspaper</span>
                            <span class="multipleNewsPaper"> <span></span>2 or more Newspapers</span>

                            <!-- <span>0</span>
                        <div class="" id="colorRange" class="ColorRange"></div>
                        <span>1</span> -->

                        </div>


                    </div>
                    <div class="MapContainer">



                        <main class="choropleth NewsPaperMapSVG">
                            <div id="NewsPaperMapSVG" class=""></div>
                            <div id="choropleth" class="d-flex justify-content-center"></div>
                            <div id="description"></div>
                        </main>



                        <div class="zoom-func">
                            <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                        </div>


                    </div>
                    <div class="content">
                        {{BottomContentNews}}
                    </div>
                    <!-- <div class="dgSourceName">Source: CNN (2020)</div> -->
                </div>

                <div class="tab-pane fade" id="digitalSites" role="tabpanel" aria-labelledby="digitalsites"
                    name="digitalsites">
                    <div class="d-flex justify-content-between">
                        <div class="HeatMapRange">

                            <!-- <span class="noNewsPaper"> <span></span> 0 Digital Sites</span> -->
                            <span class="oneNewsPaper"> <span></span> 1 Digital Site</span>
                            <span class="multipleNewsPaper"><span></span> 2 or more Digital Sites</span>


                        </div>

                    </div>
                    <div class="MapContainer">
                        <!-- <div class="mapTitle">Digital Sites</div> -->


                        <main class="choropleth digitalSitesMapSVG">
                            <div id="digitalSitesMapSVG"></div>
                            <div id="choropleth" class="d-flex justify-content-center"></div>
                            <div id="description"></div>
                        </main>

                        <div class="zoom-func">
                            <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                        </div>


                    </div>
                    <div class="content">
                        {{BottomContentDigital}}
                    </div>
                    <!-- <div class="dgSourceName">Source: CNN (2020)</div> -->
                </div>

                <div class="tab-pane fade" id="networkSites" role="tabpanel" aria-labelledby="networksites"
                    name="networksites">
                    <div class="d-flex justify-content-between">
                        <div class="HeatMapRange">
                            
                            <!-- <span class="noNewsPaper"> <span></span> 0 Digital Sites</span> -->
                            <span class="oneNewsPaper"> <span></span> 1 Network Site</span>
                            <span class="multipleNewsPaper"><span></span> 2 or more Network Sites</span>


                        </div>

                    </div>
                    <div class="MapContainer">
                        <!-- <div class="mapTitle">Digital Sites</div> -->


                        <main class="choropleth networkSitesMapSVG">
                            <div id="networkSitesMapSVG"></div>
                            <div id="choropleth" class="d-flex justify-content-center"></div>
                            <div id="description"></div>
                        </main>

                        <div class="zoom-func">
                            <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                        </div>


                    </div>
                    <div class="content">
                        {{BottomContentNetwork}}
                    </div>
                    <!-- <div class="dgSourceName">Source: CNN (2020)</div> -->
                </div>

                <div class="tab-pane fade" id="EthinicVoice" role="tabpanel" aria-labelledby="ethnicoutlets"
                    name="ethnicoutlets">
                    <div class="d-flex justify-content-between">
                        <div class="HeatMapRange">

                            <!-- <span class="noNewsPaper"><span></span> 0 Ethnic Outlets</span> -->
                            <span class="oneNewsPaper"><span></span> 1 Ethnic Outlet</span>
                            <span class="multipleNewsPaper"> <span></span> 2 or more Ethnic Outlets</span>

                        </div>

                    </div>
                    <div class="MapContainer">
                        <!-- <div class="mapTitle">Ethnic Voices</div> -->




                        <main class="choropleth EthnicVoiceMapSVG">
                            <div id="EthnicVoiceMapSVG"></div>
                            <div id="choropleth" class="d-flex justify-content-center"></div>
                            <div id="description"></div>
                        </main>

                        <div class="zoom-func">
                            <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                        </div>


                    </div>

                    <div class="content">
                        {{BottomContentEthinic}}
                    </div>
                    <!-- <div class="dgSourceName">Source: CNN (2020)</div> -->

                </div>
                <div class="tab-pane fade" id="publicProdCast" role="tabpanel" aria-labelledby="publicbroadcasting"
                    name="publicbroadcasting">
                    <div class="d-flex justify-content-between">
                        <div class="HeatMapRange">

                            <!-- <span class="noNewsPaper"><span></span> 0 Public Broadcasting</span> -->
                            <span class="oneNewsPaper"><span></span> 1 Public Broadcasting Station</span>
                            <span class="multipleNewsPaper"><span></span> 2 or more Public Broadcasting Stations</span>

                        </div>

                    </div>
                    <div class="MapContainer">
                        <!-- <div class="mapTitle">Public Broadcasting</div> -->




                        <main class="choropleth PubliCProdCastMapSVG">
                            <div id="PubliCProdCastMapSVG"></div>
                            <div id="choropleth" class="d-flex justify-content-center"></div>
                            <div id="description"></div>
                        </main>

                        <div class="zoom-func">
                            <button id="zoom-out" class="ZoomInClass" (click)="zoomOut()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-out.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="zoomIN()"><img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-in.svg" alt=""></button>
                            <button id="zoom-in" class="ZoomInClass" (click)="ReStore()"> <img
                                    src="/projects/state-of-local-news/explore/assets/Images/zoom-reset.svg" alt=""></button>
                        </div>



                    </div>

                    <div class="content">
                        {{BottomContentPB}}
                    </div>
                    <!-- <div class="dgSourceName">Source: CNN (2020)</div> -->

                </div>
            </div>

        </div>

    </div>

    <div class="stateChoose">
        <span class="StateSelectText">Explore any state:</span>
        <mat-select [formControl]="disableSelect" class="StateSelector">
            <mat-option value="option2" disabled>-- Select State --</mat-option>
            <mat-option [value]="state.StateAbbr" *ngFor="let state of StateNameList"
                (onSelectionChange)="stateChange($event,state)">{{state.StateName}}</mat-option>
        </mat-select>
    </div>

    <div class="InnerContent gContainerMain2">
        <!-- <div class="hrSeparator">
    
        </div>-->
        <div class="ChartSection">
            <div class="chartList d-flex align-items-center flex-wrap g-3">
                <div class="col-12 col-sm-6 col-md-6 col-xl-6 d-flex align-items-center justify-content-start">
                    <span class="chartCaption">Local news <br>by the numbers</span>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-xl-6 d-flex align-items-center justify-content-start">
                    <img src="/projects/state-of-local-news/explore/assets/Images/total-number-newspapers.png" alt="">


                </div>
                <div class="col-12 col-sm-12 col-md-6 col-xl-6 d-flex align-items-center justify-content-start outlet">
                    <img src="/projects/state-of-local-news/explore/assets/Images/newspaper-transactions.png" alt="">

                </div>
                <div class="col-12 col-sm-12 col-md-6 col-xl-6 d-flex align-items-center justify-content-start">
                    <img src="/projects/state-of-local-news/explore/assets/Images/newspaper-employment.png" alt="">

                </div>
            </div>
        </div>

        <div class="leaderShipSection">
            <div class="chartHeader ">
                <span class="chartCaption my-3">Thought Leadership</span>
            </div>
            <div class="row cardSection">

                <div *ngFor="let data of carousalData ; index as i"
                    [ngClass]="{'col-xl-12 ': i ===0 , 'col-xl-4 col-md-6 ': i !==0}" class="col-12 ">
                    <div class="carousalCard" *ngIf="i==0" [ngClass]="{'d-md-flex col-12 d-none': i == 0}">
                        <div class="cardImg" [ngClass]="{'col-12 col-md-6': i == 0}"
                            [ngStyle]="{'height': i == 0 ? '' : ''}">
                            <img src="{{data.cardImg}}" alt="">
                        </div>
                        <div class="cardContent" [ngClass]="{'col-12 col-md-6': i == 0}">

                            <div class="cardTitle mt-4">{{data.cardTitle}}</div>
                            <div class="cardDescription">{{data.cardDesc}}</div>
                            <button class="cardActionBtn" *ngIf="data.cardType === 'report'">
                                <a [href]="data.url" target="_blank" class="urlbtn">
                                    View Report
                                    <img src="/projects/state-of-local-news/explore/assets/Images/downloadIcon.svg" alt="">
                                </a>
                            </button>
                            <button class="cardActionBtn" *ngIf="data.cardType === 'viewmore'">
                                <a [href]="data.url" target="_blank" class="urlbtn">
                                    View
                                    <span>
                                        <img src="/projects/state-of-local-news/explore/assets/Images/arrow-circle-right.svg" alt="">

                                    </span>
                                </a>
                            </button>


                        </div>
                    </div>
                    <div class="carousalCard" [ngClass]="{'d-md-none d-block': i == 0}">
                        <div class="cardImg">
                            <img src="{{data.cardImg}}" alt="">
                        </div>
                        <div class="cardContent">

                            <div class="cardTitle mt-3">{{data.cardTitle}}</div>
                            <div class="cardDescription">{{data.cardDesc}}</div>
                            <button class="cardActionBtn" *ngIf="data.cardType === 'viewmore'">
                                <a [href]="data.url" target="_blank" class="urlbtn">
                                    View
                                    <span>
                                        <img src="/projects/state-of-local-news/explore/assets/Images/arrow-circle-right.svg" alt="">
                                    </span>
                                </a>
                            </button>
                            <button class="cardActionBtn" *ngIf="data.cardType === 'learnmore'">
                                <a [href]="data.url" target="_blank" class="urlbtn"> Subscribe here
                                    <img src="/projects/state-of-local-news/explore/assets/Images/arrow-circle-right.svg" alt="">
                                </a>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</section>



<app-footer from="NationalPage"></app-footer>